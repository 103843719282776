import * as React from "react";
import { Typography } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import { StyleDefaultPages, useErrorPageStyles } from "./styles-error-pages";
import { PageContent } from "../../components/page/page-content";

interface OwnProps extends StyleDefaultPages {}

export const Error404: React.FC<OwnProps> = (props) => {
  const classes = useErrorPageStyles(props);

  return (
    <PageContent variant="centered" classes={{ root: classes.root }}>
      <div>
        <ErrorOutline className={classes.icon} />
        <Typography className={classes.bigText} variant={"h1"} gutterBottom>
          404: Page not found
        </Typography>
      </div>
    </PageContent>
  );
};
