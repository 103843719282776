import { TableClassKey } from "@material-ui/core/Table";
import { TableCellClassKey } from "@material-ui/core/TableCell";
import { TableHeadClassKey } from "@material-ui/core/TableHead";
import { TableRowClassKey } from "@material-ui/core/TableRow";
import { CSSProperties } from "@material-ui/styles";

export const MuiTable: Partial<Record<TableClassKey, CSSProperties>> = {
  root: {
    // margin: spacing(-1),
    // marginTop: spacing(-3)
  },
};

export const MuiTableRow: Partial<Record<TableRowClassKey, CSSProperties>> = {
  root: {
    transition: "background 0.3s",
    borderBottom: "1px solid #eee",
    "&:hover": {
      background: "#f2f2f2",
    },
  },
};

export const MuiTableCell: Partial<Record<TableCellClassKey, CSSProperties>> = {
  root: {
    borderBottom: 0,
  },
  head: {
    fontSize: "0.75rem",
    fontWeight: 600,
    textTransform: "uppercase",
    color: "#000",
  },
};

export const MuiTableHead: Partial<Record<TableHeadClassKey, CSSProperties>> = {
  root: {},
};
