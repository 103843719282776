import * as React from "react";
import { MenuItem, TextField, StandardTextFieldProps } from "@material-ui/core";

import { SelectPresenter } from "./select-presenter";
import { ProfileRecord } from "../../../../application/resources/profile/profile";
import { useObserver } from "mobx-react";
import LoadingIcon from "../../../../v2/common/ui/loading-icon";
import { makePresenter } from "../../../../application/helpers/make-presenter";
import { User } from "../../../../application/resources/user/user";

const usePresenter = makePresenter(
  ({ provider }) =>
    new SelectPresenter<ProfileRecord>("profiles", provider.profile)
);

interface OwnProps extends StandardTextFieldProps {
  currentUser?: User;
}

export const SelectProfile: React.FC<OwnProps> = (props) => {
  const presenter = usePresenter(props);
  return useObserver(() => {
    const { collection } = presenter;
    const { ["currentUser"]: currentUser, ...filteredProps } = props;
    const currentUserProfile = currentUser?.profile?.record.profile;

    return (
      <TextField select {...filteredProps}>
        {collection.loading && !collection.loaded ? (
          <>
            <MenuItem disabled value={"_loading"}>
              <LoadingIcon scale={0.5} />
            </MenuItem>
          </>
        ) : (
          collection.items.map((profile) => {
            return (
              <MenuItem
                disabled={
                  profile.id === "xpert-admins" &&
                  currentUserProfile?.id !== "xpert-admins"
                }
                value={profile.id}
              >
                {profile.record.name}
              </MenuItem>
            );
          })
        )}
      </TextField>
    );
  });
};
