import { useObserver } from "mobx-react";
import * as React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { AuthRoute } from "./auth/auth-route";
import { RoutesPresenter } from "./routes-presenter";
import { usePresenter } from "../hooks/use-presenter";
import { PageContainer } from "./components/page/page-container";
import { Fade } from "@material-ui/core";
import { AppPresenter } from "../app-presenter";
import { DefaultInterface } from "./interface/default-interface";
import { ScrollToTop } from "../v2/common/ui/scroll-to-top";

export const Routes: React.FC = (props) => {
  const presenter = usePresenter(
    ({ provider }) => new RoutesPresenter(provider.routes)
  );

  const appPresenter = usePresenter(
    ({ interactor }) => new AppPresenter(interactor.user)
  );

  const location = useLocation();
  presenter.validateLocation(location.pathname);

  return useObserver(() => {
    const { routes, redirect, redirectLocation } = presenter;

    if (redirect && redirectLocation) {
      return <Redirect to={redirectLocation} />;
    }

    return (
      <>
        <Switch>
          {routes.map(
            (route) =>
              appPresenter.user && (
                <Route
                  key={route.path}
                  exact
                  path={route.path}
                  component={DefaultInterface}
                />
              )
          )}
        </Switch>

        <ScrollToTop>
          <PageContainer>
            <Fade in timeout={700}>
              <Switch>
                {routes.map((route) =>
                  route.public ? (
                    <Route
                      key={route.path}
                      exact
                      path={route.path}
                      component={route.component}
                    />
                  ) : (
                    <AuthRoute
                      key={route.path}
                      exact
                      path={route.path}
                      component={route.component}
                      route={route}
                    />
                  )
                )}
              </Switch>
            </Fade>
          </PageContainer>
        </ScrollToTop>
      </>
    );
  });
};
