import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useObserver } from "mobx-react";
import * as React from "react";
import { ProjectionListBySolution } from "../../../../application/resources/projection/projection";
import { usePresenter } from "../../../../hooks/use-presenter";
import { TableProps } from "../../../components/table/table-helpers";
import { TablePresenter } from "../../../components/table/table-presenter";
import { TableRowProjections } from "./projection-table-rows";

interface OwnProps extends TableProps<ProjectionListBySolution> {}

export const TableProjections: React.FC<OwnProps> = (props) => {
  const presenter = usePresenter(
    () =>
      new TablePresenter(
        props.items,
        props.onEdit,
        props.onDelete,
        props.onClone,
        props.onRowClick,
        props.onSelect
      )
  );

  return useObserver(() => {
    return (
      <section>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell children={"Solution"} />
              <TableCell children={"Design case"} />
              <TableCell children={"Revision"} />
              <TableCell children={"Designed by"} />
              <TableCell children={"Approved by"} />
              <TableCell padding={"checkbox"} children={""} />
              <TableCell padding={"checkbox"} children={""} />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items?.map((item) => (
              <TableRowProjections item={item} presenter={presenter} />
            ))}
          </TableBody>
        </Table>
      </section>
    );
  });
};
