import React from "react";
import WidgetHeader from "./widget-header";
import WidgetContent from "./widget-content";
import { Card, makeStyles } from "@material-ui/core";
import { useObserver } from "mobx-react";
import { PentairTheme } from "../../../config/theme/types";
import LoadingIcon from "../../../v2/common/ui/loading-icon";

interface OwnProps {
  title?: string;
  icon?: React.ReactNode;
  loading?: boolean;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {},
  loading: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const Widget: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const { title, children, loading, icon } = props;

  const withHeader = title ? true : false;

  return useObserver(() => (
    <Card className={classes.root}>
      {withHeader && (
        <WidgetHeader icon={icon} title={title || ""}></WidgetHeader>
      )}
      {loading ? (
        <div className={classes.loading}>
          <LoadingIcon />
        </div>
      ) : (
        <WidgetContent>{children}</WidgetContent>
      )}
    </Card>
  ));
};
