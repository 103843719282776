import {
  GraphQLModel,
  BaseGraphQLModel,
} from "../../base/graphql/graphql-model";
import { CalculationValueOptions } from "./calculation-values";
import { v4 as uuid } from "uuid";
import { computed } from "mobx";

export interface TechnologyRecord {
  id: string;
  image: string;
  oneliner: string;
  techImage: Array<string>;
  techImageMap: Array<any>;
  name: string;
  active: boolean;
  description: string;
  techValues: CalculationValue[];
  comparableTechnologies: string[];
}

export interface Technology extends GraphQLModel<TechnologyRecord> {
  value(id: CalculationValueOptions): any;
  valueMap: any;
}

export interface CalculationValue {
  id: string;
  name: string;
  description?: string;
  values: Array<number | string>;
  recommendedValues: Array<number | string>;
  unit: string;
  unitOptions?: string[];
  valueOptions?: Array<number | string>;
  disabled?: boolean;
  error?: string;
  category?: string;
  decimals?: number;
}

export class TechnologyModel
  extends BaseGraphQLModel<TechnologyRecord>
  implements Technology {
  public typename: string = "Technology";
  public useMock: boolean = false;

  public defaultValues: TechnologyRecord = {
    id: uuid(),
    name: "Some technology",
    active: false,
    image: "",
    oneliner: "Unknown",
    techImage: [],
    description: "Some description",
    techValues: [],
    techImageMap: [],
    comparableTechnologies: [],
  };

  public value = (id: CalculationValueOptions) => {
    return {
      ...this.valueMap[id],
    };
  };

  @computed public get valueMap() {
    return this.record.techValues.reduce(
      (result, valueData: CalculationValue) => {
        if (valueData.id) {
          result[valueData.id] = valueData;
        }
        return result;
      },
      {} as Record<CalculationValueOptions, CalculationValue>
    );
  }
}
