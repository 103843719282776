import { ProjectionRecord } from "../../../../../application/resources/projection/projection";
import { useProjectionPageStyles } from "../../../../../view/pages/projection/styles-projection-pages";
import * as React from "react";
import { Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CalculationValue } from "../../../../../application/resources/technology/technology";
import { FormFieldProjectionCalculationValue } from "../../../../../view/components/form/fields/projection-calculation-value";
import { FormSelectProjectionCalculationValue } from "../../../../../view/components/form/fields/select-projection-calculation-value";

export interface OwnProps {
  onChange: (key: keyof ProjectionRecord) => (value: any) => void;
  data: Partial<FormData>;
  errors?: any;
}

interface FormData {
  capacity: CalculationValue;
  capacity_base: CalculationValue;
  water_source: CalculationValue;
}

export const FormProjectionFlowInputs: React.FC<OwnProps> = (props) => {
  const classes = useProjectionPageStyles(props);
  const { onChange, data } = props;
  const [validationErrors, setValidationErrors] = React.useState<
    Record<string, string>
  >({});

  const onValidationError = React.useCallback(
    (key: string) => (error: string | false) => {
      if (validationErrors[key] || false !== error) {
        if (error) {
          validationErrors[key] = error;
        } else {
          delete validationErrors[key];
        }

        setValidationErrors({ ...validationErrors });
      }
    },
    [validationErrors, setValidationErrors]
  );

  return (
    <React.Fragment>
      <div>
        <div className={classes.titleWrap}>
          <FontAwesomeIcon
            className={classes.icon}
            icon={["fal", "clipboard-list"]}
          />
          <Typography variant="h4">Flow inputs</Typography>
        </div>
        <form>
          <FormFieldProjectionCalculationValue
            value={data.capacity}
            onChange={onChange("projectionValues")}
            onError={onValidationError("projectionValues")}
            textfieldProps={{ margin: "normal" }}
          />
          <FormFieldProjectionCalculationValue
            value={data.capacity_base}
            onChange={onChange("projectionValues")}
            onError={onValidationError("projectionValues")}
            textfieldProps={{ margin: "normal" }}
          />
          <FormSelectProjectionCalculationValue
            value={data.water_source}
            onChange={onChange("projectionValues")}
            onError={onValidationError("projectionValues")}
            textfieldProps={{ margin: "normal" }}
          />
        </form>
      </div>
    </React.Fragment>
  );
};
