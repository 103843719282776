import gql from "graphql-tag";
import { runQuery, RunQueryOptions } from "../../../network/run-query";
import { SalesForceConfigFragment } from "../graphql/fragment";
import { SalesForceConfigRecord } from "../single";
import { InputSalesForceConfig } from "./create";

const buildQuery = (fragment: string = SalesForceConfigFragment) => gql`
  mutation UpdateSalesForceConfig(
    $username: String!
    $password: String!
    $apikey: String!
  ) {
    updateSalesForceConfig(
      username: $username
      password: $password
      apikey: $apikey
    )
  }
`;

export const updateSalesForceConfig = async (
  options: RunQueryOptions<InputSalesForceConfig>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<
    InputSalesForceConfig,
    { updateSalesForceConfig: SalesForceConfigRecord }
  >({
    query,
    variables: options.variables,
  });
};
