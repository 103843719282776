import { graphqlOperation } from "aws-amplify";
import { GraphQLProvider, BaseGraphQLProvider } from "../../base/graphql/graphql-provider";
import * as Mutation from "./graphql/mutations";
import * as Query from "./graphql/queries";
import { Technology, TechnologyRecord, TechnologyModel } from "./technology";

export interface TechnologyProvider extends GraphQLProvider<TechnologyRecord, Technology> { }

export interface TechnologyValidations {
  country?: string;
  company?: string;
}

export interface ValidationError {
  property: string;
  errorKey: string[];
}

export default class DefaultTechnologyProvider extends BaseGraphQLProvider<TechnologyRecord, Technology>
  implements TechnologyProvider {
  public model = TechnologyModel;
  public useMock: boolean = false;

  public createOperation = (technology: Technology) => {
    return graphqlOperation(Mutation.CreateTechnology, { input: technology.serialize() });
  };

  public fetchOperation = (technology: Technology) => {
    return graphqlOperation(Query.QueryTechnology, { id: technology.id });
  };

  public updateOperation = (technology: Technology) => {
    return graphqlOperation(Mutation.UpdateTechnology, { input: technology.serialize() });
  };

  public deleteOperation = (technology: Technology) => {
    return graphqlOperation(Mutation.DeleteTechnology, { id: technology.id });
  };

  public listOperation = () => {
    return graphqlOperation(Query.QueryAllTechnologies, {});
  };
}
