import { makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import moment from "moment";
import { PentairTheme } from "../../../../config/theme/pentair-theme";
import { UserRecord } from "../../../../application/resources/user/user";

interface OwnProps {
  user: Partial<UserRecord>;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  lastLogin: {
    color: theme.palette.helperTextGray.main,
  },
}));

export const UserStatusColumn: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const { user } = props;

  return (
    <Typography>
      {user.status}
      <br />
      <i className={classes.lastLogin}>
        {user.login_history &&
          "Last login: " +
            moment
              .unix(
                parseInt(user.login_history[user.login_history.length - 1]) /
                  1000
              )
              .format("DD-MMM-YYYY, HH:mm")}
      </i>
    </Typography>
  );
};
