import * as React from "react";
import { useObserver } from "mobx-react";
import { makePresenter } from "../../../application/helpers/make-presenter";
import { PageContent } from "../../components/page/page-content";
import { Page } from "../../components/page/page-wrapper";
import { PageLoading } from "../page-loading";
import { ProjectionHeader } from "./partials/projection-header";
import { ProjectionPresenter } from "./projection-presenter";
import {
  StyleProjectionSingle,
  useProjectionSingleStyles,
} from "./styles-projection-single";
import { TabsProjectionSingle } from "./partials/projection-single-tabs";
import { ProjectionInputs } from "./partials/projection-inputs";
import { ProjectionResults } from "./partials/projection-results";
import { ProjectionHistory } from "./partials/projection-history";
import { ProjectionPaperSave } from "./partials/projection-paper-save";
import RouteLeavingGuard from "../../components/guards/route-leaving-guard";
import { useRedirect } from "../../../hooks/use-redirect";
import { useHistory } from "react-router";
import moment from "moment";
import { Beforeunload } from "react-beforeunload";

interface OwnProps extends StyleProjectionSingle {}

const usePresenter = makePresenter(
  ({ provider, interactor }) =>
    new ProjectionPresenter(
      useRedirect(),
      provider.projection,
      interactor.technology
    ),
  (presenter, props) => {
    presenter._props = props.match;
  }
);

export const PageProjectionSingle: React.FC<OwnProps> = (props) => {
  const classes = useProjectionSingleStyles(props);
  const history = useHistory();
  const presenter = usePresenter(props);

  // @Edgar please check lines 45-54 and 111

  // When the component is mounted TIME is being set to the current time.
  const [time, setTime] = React.useState(moment().unix());

  // When the presenter.hasChanges has a change (goes from false to true) the useEffect is called and an interval is set to update the time every 15 seconds (every second seems a bit too much for me)
  React.useEffect(() => {
    const updateInterval = setInterval(() => setTime(moment().unix()), 15000);
    return () => {
      clearInterval(updateInterval);
    };
  }, [presenter.hasChanges]);

  return useObserver(() => {
    const {
      loading,
      projection,
      togglePopup,
      activeTab,
      lastModified,
      setActiveTab,
      hasChanges,
    } = presenter;

    if (!projection || projection.record.createdBy === undefined) {
      return null;
    }

    if (loading) {
      return <PageLoading message="Fetching projection" />;
    }

    if (!projection.record.project?.id) {
      return <PageLoading message="Updating projection structure..." />;
    }

    return (
      <Page variant="fullscreen">
        <Beforeunload
          onBeforeunload={(event) => !!hasChanges && event.preventDefault()}
        />
        <RouteLeavingGuard
          when={!!hasChanges}
          titleText={"Warning"}
          subTitleText={"Confirm your action"}
          contentText={
            <div>
              You have made changes to the projection but haven’t saved yet.{" "}
              <br />
              <ul style={{ margin: "5px 0", padding: "0 17px" }}>
                <li>Click save to SAVE this revision</li>
                <li>
                  If you click DISCARD all the changes made are permanently not
                  recoverable.
                </li>
                <li>Click CANCEL to remain on the same page.</li>
              </ul>
            </div>
          }
          cancelButtonText={"Cancel"}
          confirmButtonText={"Discard"}
          customButtons={[
            {
              buttonDisable:
                !hasChanges ||
                (hasChanges && lastModified.unix()) >=
                  moment.unix(time).subtract("1", "minutes").unix(),
              buttonText: "Save",
              buttonAction: () => {
                togglePopup();
              },
              buttonIcon: "file",
              buttonType: "primary",
            },
          ]}
          shouldBlockNavigation={() => {
            if (hasChanges) {
              return true;
            }
            return false;
          }}
          navigate={(path) => history.push(path)}
        />
        <PageContent variant="fullscreen">
          <div className={classes.header}>
            <ProjectionHeader
              // Blocks save button if there are no changes or if the lastModified is less than one minute ago.
              blockSave={
                !hasChanges ||
                (hasChanges && lastModified.unix()) >=
                  moment.unix(time).subtract("1", "minutes").unix()
              }
              projection={projection}
              onSave={togglePopup}
              version={presenter.revision}
            />
          </div>
          <div className={classes.sectionBar}>
            <TabsProjectionSingle
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
          <div className={classes.sectionWrap}>
            {activeTab === "inputs" && (
              <ProjectionInputs presenter={presenter} />
            )}
            {activeTab === "results" && (
              <ProjectionResults presenter={presenter} />
            )}
            {activeTab === "history" && (
              <ProjectionHistory presenter={presenter} />
            )}
          </div>
          <ProjectionPaperSave
            onChange={presenter.onChange}
            abort={presenter.togglePopup}
            data={{ note: presenter.revisionNote }}
            active={presenter.savingPopup}
            saving={presenter.loading}
            onSubmit={presenter.save}
            validations={{
              note: "required",
            }}
          />
        </PageContent>
      </Page>
    );
  });
};
