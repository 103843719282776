import { useCallback, useRef } from "react";

export const useFormChange = <TValue extends any>(
  valueMapper: (e: any) => TValue,
  onChange?: (value: TValue, e: any) => void
) => {
  const changed = useRef(false);

  const handler = useCallback(
    (e: any) => {
      changed.current = true;

      if (onChange) {
        onChange(valueMapper(e), e);
      }
    },
    [onChange]
  );

  return {
    changed: changed.current,
    onChange: handler,
  };
};
