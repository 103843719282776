import gql from "graphql-tag";
import { SolutionRecord } from "../../../../../v2/feature/solution/types/record";
import { runQuery, RunQueryOptions } from "../../../../network/run-query";
import { ProjectRecord } from "../../../project/project";
import { ProjectionFragment } from "../../graphql/fragments";
import { ProjectionRecord } from "../../projection";

interface UserSummary {
  id: string;
  attributes: {
    given_name: string;
    family_name: string;
  };
}

export interface InputProjection {
  id?: string;
  name?: string;
  project?: Partial<ProjectRecord>;
  projectionValues?: Array<any>;
  version?: string;
  status?: string;
  type?: string;
  code?: string;
  country?: string;
  company?: string;
  pdf?: string;
  account?: string;
  opportunity?: string;
  case?: string;
  units?: string;
  owner?: UserSummary;
  approver?: UserSummary;
  solution?: Partial<SolutionRecord>;
  solutionId?: string;
  note?: string;
}

interface MutationCreateProjectionArgs {
  input: InputProjection;
}

const buildQuery = (fragment: string = ProjectionFragment) => gql`
  mutation CreateProjection($input: inputNewProjection!) {
    createProjection(input: $input) {
      ${fragment}
    }
  }
`;

export const createProjection = async (
  options: RunQueryOptions<MutationCreateProjectionArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<
    MutationCreateProjectionArgs,
    { createProjection: ProjectionRecord }
  >({
    query,
    variables: options.variables,
  });
};
