import gql from "graphql-tag";
import {
  runQuery,
  RunQueryOptions,
} from "../../../../application/network/run-query";
import {
  ProjectRecord,
  UserShareSummary,
} from "../../../../application/resources/project/project";
import { ProjectFragment } from "../graphql/fragments";

export interface InputProject {
  id?: string;
  name?: string;
  opportunity?: {
    name: string;
    id: string;
    opportunityNumber: string;
    account: {
      name: string;
    };
  };
  destination?: any;
  pentairAccountName?: string;
  users?: UserShareSummary[];
}

interface MutationCreateProjectArgs {
  input: InputProject;
}

/**
 * Returns a Query
 *
 * @param fragment - Query fragment containing the data you wish to retrieve
 *
 * @returns DocumentNode containing the query string
 */
const buildQuery = (fragment: string = ProjectFragment) => gql`
  mutation CreateProject($input: inputNewProject!) {
    createProject(input: $input) {
      ${fragment}
    }
  }
`;

/**
 * Attempts to create a new project and expects to return the created project
 *
 * @param options - Options for creating the GraphQL Query.
 *
 * @returns GraphQL response
 */
export const createProject = async (
  options: RunQueryOptions<MutationCreateProjectArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<
    MutationCreateProjectArgs,
    { createProject: ProjectRecord }
  >({
    query,
    variables: options.variables,
  });
};
