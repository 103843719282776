export const SalesForceConfigFragment = `
    username
    password
    apikey
`;

export const SalesForceOpportunityFragment = `
    account {
        id
        name
    }
    id
    name
    opportunityNumber
`;
