import { MenuItem, TextField, TextFieldProps } from "@material-ui/core";
import InfoLabel from "../info-label";
import * as React from "react";
import { useFormChange } from "../hooks/use-form-change";
import { DefaultFormFieldProps } from "../types";
import { useFormValidation } from "../hooks/use-form-validation";
import { UserRecord } from "../../../../application/resources/user/user";
import { UserShareSummary } from "../../../../application/resources/project/project";
import { useUserCollection } from "../../../../v2/feature/user/hooks/use-collection";
// import { Autocomplete } from "@material-ui/lab";

interface OwnProps
  extends DefaultFormFieldProps<{
    id: string;
    given_name: string;
    family_name: string;
  }> {
  textfieldProps?: TextFieldProps;
  owner?: string;
  disabled?: boolean;
  currentUsers?: UserShareSummary[];
}

export const FormSelectShareUser: React.FC<OwnProps> = (props) => {
  const users = useUserCollection();
  const [usersWithoutOwner, setUsersWithoutOwner] = React.useState<
    Partial<UserRecord>[]
  >([]);
  const error = useFormValidation(
    props.value,
    props.validations || "required",
    props.onError
  );

  React.useEffect(() => {
    users.fetch().then(() => {
      const records = users.records.filter((user) => {
        if (user.id !== props.owner) {
          if (
            (props.currentUsers || []).filter((currentUser) => {
              return currentUser.id === user.id;
            }).length > 0
          ) {
            return false;
          }
          return true;
        }

        return false;
      });
      setUsersWithoutOwner(records);
    });
  }, [users, props.currentUsers]);

  const { onChange, changed } = useFormChange((e) => {
    const user: any = users.records.find(
      (listUser) => listUser.id === e.target.value
    );

    return {
      id: e.target.value,
      given_name: user.attributes.given_name,
      family_name: user.attributes.family_name,
      email: user.attributes.email,
    };
  }, props.onChange);

  return (
    <>
      <TextField
        disabled={props.disabled}
        select
        required
        name="user"
        id="user"
        value={props.value || ""}
        onChange={onChange}
        fullWidth
        label={
          <InfoLabel
            title={props.label || "User"}
            description={props.description || "User"}
          />
        }
        error={changed && error ? true : false}
        helperText={changed && error}
        {...props.textfieldProps}
      >
        {usersWithoutOwner.map((user) => (
          <MenuItem value={user.id}>
            {(user.attributes &&
              user.attributes.given_name + " " + user.attributes.family_name) ||
              ""}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};
