import * as React from "react";
import { makeStyles, StyledComponentProps } from "@material-ui/core";
import classnames from "classnames";

type PageContentVariant =
  | "contained"
  | "centered"
  | "padded"
  | "fullscreen"
  | "fullscreenPaper";

interface OwnProps
  extends StyledComponentProps<
    | "root"
    | "containedVariant"
    | "paddedVariant"
    | "centeredVariant"
    | "fullscreenVariant"
    | "fullscreenPaperVariant"
  > {
  variant?: PageContentVariant;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexDirection: "column", flex: 1 },
  centeredVariant: {
    margin: "auto",
    justifyContent: "center",
  },
  fullscreenVariant: {
    paddingTop: theme.spacing(6),
  },
}));

export const PageContent: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const { variant } = props;
  const variantClass =
    classes[((variant || "contained") + "Variant") as "containedVariant"];

  return (
    <article
      className={classnames(props.className, classes.root, variantClass)}
    >
      {props.children}
    </article>
  );
};
