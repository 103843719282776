import * as React from "react";

import { useHistory } from "react-router-dom";
import { IconButton } from "../mui-extended/icon-button";

export const ButtonBack = () => {
  const history = useHistory();

  return <IconButton icon="arrow-left" onClick={history.goBack}></IconButton>;
};
