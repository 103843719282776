import { createRoute } from "../../application/helpers/route";
import { PageProjectIndex } from "../../v2/feature/project/view/pages";
import { PageAssistant } from "../../view/pages/assistant/page-assistant";
import { PageProjectSingle } from "../../view/pages/project/single/single";
import { PageProjectionSingle } from "../../view/pages/projection/page-projection-single";

export const routesProject = {
  "project.index": createRoute({
    path: "/project/index",
    title: "Projects",
    component: PageProjectIndex,
    breadcrumbs: [
      {
        route: "project.index",
        label: "Projects",
      },
      {
        route: "project.index",
        label: "Created by me",
      },
    ],
    public: false,
    requiredPermissions: ["projections.project.list"],
  }),
  "project.single": createRoute({
    path: "/project/single/:projectid/",
    title: "View projection",
    component: PageProjectSingle,
    public: false,
    breadcrumbs: [
      {
        route: "project.index",
        label: "Projects",
      },
      {
        route: "project.single",
        label: "Details",
      },
    ],
    requiredPermissions: ["projections.project.get"],
  }),
  "projection.single": createRoute({
    path: "/project/:projectid/projection/:projectionid/",
    title: "View projection",
    component: PageProjectionSingle,
    public: false,
    requiredPermissions: ["projections.projection.get"],
    breadcrumbs: [
      {
        route: "project.single",
        label: "Project",
      },
      {
        label: "Projection",
        route: "projection.single",
      },
      {
        route: "projection.single",
        label: "Inputs",
      },
    ],
  }),
  "projection.single.tab": createRoute({
    path: "/project/:projectid/projection/:projectionid/:tab/",
    title: "View projection",
    breadcrumbs: [
      {
        route: "project.single",
        label: "Project",
      },
      {
        route: "projection.single",
        label: "Projection",
      },
      {
        route: "projection.single.tab",
        label: ({ match }) => match.params.tab,
      },
    ],
    component: PageProjectionSingle,
    public: false,
    requiredPermissions: ["projections.projection.get"],
  }),
  "projection.single.tab.version": createRoute({
    path: "/project/:projectid/projection/:projectionid/:tab/:version",
    title: "View projection",
    breadcrumbs: [
      {
        route: "project.single",
        label: "Project",
      },
      {
        route: "projection.single",
        label: "Projection",
      },
      {
        route: "projection.single.tab",
        label: ({ match }) => match.params.tab,
      },
      {
        route: "projection.single.tab.version",
        label: ({ match }) => match.params.version,
      },
    ],
    component: PageProjectionSingle,
    public: false,
    requiredPermissions: ["projections.projection.get"],
  }),
  "projection.create": createRoute({
    path: "/project/:projectid/projection/create/:projectionid/",
    title: "View projection",
    component: PageProjectionSingle,
    breadcrumbs: [
      {
        route: "project.single",
        label: "Project",
      },
      {
        route: "projection.create",
        label: "Create projection",
      },
    ],
    public: false,
    requiredPermissions: ["projections.projection.get"],
  }),
  "app.assistant.project": createRoute({
    path: "/project/:projectid/assistant",
    title: "Xpert assistant",
    breadcrumbs: [
      {
        route: "project.single",
        label: "Project",
      },
      {
        route: "app.assistant.project",
        label: "Create Projection",
      },
      {
        route: "app.assistant.project",
        label: "Assistant",
      },
    ],
    component: PageAssistant,
  }),
};
