import { createUser } from "./functions/create";
import { deleteUser } from "./functions/delete";
import { fetchUser } from "./functions/fetch";
import { updateUser } from "./functions/update";
import { v4 as uuid } from "uuid";
import { UserRecord } from "../../../application/resources/user/user";
import { take } from "../../../application/resources/helper/take";

export class UserClass {
  private _record: Partial<UserRecord> = {};
  private _changes: Partial<UserRecord> = {};

  public isFetching: boolean = false;
  public isDeleting: boolean = false;
  public isUpdating: boolean = false;
  public isCreating: boolean = false;

  public get record(): Partial<UserRecord> {
    const record = { ...this._record, ...this._changes };

    return record;
  }

  public get changes(): Partial<UserRecord> {
    return this._changes;
  }

  constructor(
    public id?: string,
    public fragment?: string,
    private _setState?: (state: UserClass) => void
  ) {
    this.record.id = id;
  }

  public setState = <T extends Record<string, any>>(
    obj?: T,
    k?: keyof T,
    value?: any
  ) => {
    if (obj && k) {
      obj[k] = value;
    }

    if (this._setState) {
      this._setState({ ...this });
    }
  };

  public handleResponse = (
    record: Partial<UserRecord> | null | undefined,
    errors: object[] = []
  ) => {
    this._record = { ...this._record, ...(record || {}) };
    // this.errors = errors;
    this.setState();
  };

  public get input(): Partial<UserRecord> {
    return take(this.record, ["username"]);
  }

  public change = (key: keyof UserRecord, value: any) => {
    this._changes = { ...this._changes, [key]: value };
    this.setState();
  };

  public update = async () => {
    if (this.id) {
      const res = await updateUser({
        variables: { input: this.input },
        query: { fragment: this.fragment },
      });
      this.handleResponse(res.data && res.data.updateUser, res.errors);
      return res;
    }

    return;
  };

  public create = async () => {
    const res = await createUser({
      variables: { input: { id: uuid(), ...this.input } },
      query: { fragment: this.fragment },
    });
    this.handleResponse(res.data && res.data.createUser, res.errors);
    return res;
  };

  public delete = async (id?: string) => {
    if (!this.id && !id) {
      throw new Error("ID is not set");
    }
    if (id || this.id) {
      const res = await deleteUser({
        variables: { id: id || this.id || "" },
        query: { fragment: this.fragment },
      });
      this.handleResponse(res.data && res.data.deleteUser, res.errors);
      return res;
    }

    throw new Error("ID is not set");
  };

  public fetch = async () => {
    if (!this.id) {
      throw new Error("ID is not set");
    }

    const res = await fetchUser({
      variables: { id: this.id },
      query: { fragment: this.fragment },
    });
    this.handleResponse(res.data && res.data.User, res.errors);
    return res;
  };
}
