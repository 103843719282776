import * as React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { PentairTheme } from "../../config/theme/types";
import { PageContent } from "../components/page/page-content";
import LoadingIcon from "../../v2/common/ui/loading-icon";

interface OwnProps {
  classes?: Record<"root" | "icon" | "message", string>;
  message?: string;
}

export const useStyles = makeStyles<PentairTheme>((theme) => ({
  root: {
    color: theme.palette.primary.main,
    textAlign: "center",
  },
  icon: {},
  message: {
    marginTop: -48,
  },
}));

export const PageLoading: React.FC<OwnProps> = ({ message, classes }) => {
  classes = useStyles({ classes });

  return (
    <PageContent variant="centered" classes={{ root: classes.root }}>
      <div>
        <div className={classes.icon}>
          <LoadingIcon scale={0.9}></LoadingIcon>
        </div>
        {message && (
          <Typography variant={"body1"} className={classes.message}>
            {message}
          </Typography>
        )}
      </div>
    </PageContent>
  );
};
