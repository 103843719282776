import { Grid, Tooltip, Typography } from "@material-ui/core";
import { StyleRules, makeStyles } from "@material-ui/core/styles";
import { StyledComponentProps } from "@material-ui/styles";
import classnames from "classnames";
import * as React from "react";

import { PentairTheme } from "../../../config/theme/types";
import { typographyVariants } from "../../../config/theme/typography";
import { useSolutionCollection } from "../../../v2/feature/solution/hooks/use-collection";
import { SolutionRecord } from "../../../v2/feature/solution/types/record";
import { useRedirect } from "../../../hooks/use-redirect";
import LoadingIcon from "../../../v2/common/ui/loading-icon";
import { ProjectionCreateDialog } from "../../../v2/feature/projection/view/dialog/projection-create-dialog";

interface OwnProps
  extends StyledComponentProps<
    | "root"
    | "done"
    | "inner"
    | "header"
    | "layout"
    | "media"
    | "backButton"
    | "technology"
    | "compareToggleButton"
    | "checkbox"
    | "imageContainer"
    | "container"
    | "disabled"
    | "technologyContainer"
  > {
  include?: string[] | undefined;
  project?: string | undefined;
}

const useStyles = makeStyles(
  (theme: PentairTheme): StyleRules => ({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "auto",
      transition: "all .45s",
      boxSizing: "border-box",
    },
    done: {
      border: "0 !important",
      top: theme.spacing(4),
      height: "calc(100vh - " + theme.spacing(4) + "px)",
      paddingTop: "0 !important",
      bottom: 0,
    },
    technologyContainer: {
      marginTop: theme.spacing(2),
      justifyContent: "center",
    },
    compareToggleButton: {},
    technology: {
      width: "100%",
      maxWidth: theme.spacing(12),
      display: "inline-block",
      transition: "opacity 0.3s",
      opacity: 1,
      "&:hover": {
        cursor: "pointer",
      },
    },
    imageContainer: {
      position: "relative",
      padding: theme.spacing(1),
    },
    checkbox: {
      position: "absolute",
      right: 0,
      bottom: 0,
    },
    container: {},
    disabled: {
      opacity: 0.3,
    },
    tooltip: {
      background: theme.palette.brightBlue.main,
      padding: theme.spacing(2),
      borderRadius: 0,
      width: 500,
      maxWidth: "50vw",
    },
    tooltipArrow: {
      color: theme.palette.brightBlue.main,
    },
  })
);

export const TechnologySelector: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);

  const [loading, setLoading] = React.useState<boolean>();
  const collection = useSolutionCollection();
  const redirect = useRedirect();
  const [newProjection, setNewProjection] = React.useState<any>({});
  const [createPopup, setCreatePopup] = React.useState(false);
  const [solution, setSolution] = React.useState<Partial<SolutionRecord>>();

  const sortedSolutions = () => {
    if (collection.records) {
      return collection.records.sort(
        (a, b) => parseInt(a.order || "") - parseInt(b.order || "")
      );
    }

    return [];
  };

  // const togglePopup = React.useCallback(() => {
  //   setCreatePopup(createPopup ? false : true);
  // }, []);

  const selectTechnology = React.useCallback(
    (solution?: Partial<SolutionRecord>) => {
      if (!solution) {
        return;
      } else if (solution.id === undefined) {
        return;
      }

      if (["anmbr", "crossflow-uf", "hfnano"].includes(solution.id)) {
        redirect("app.homepage");
        return;
      }

      setNewProjection({
        solution: solution.id,
        ...newProjection,
      });

      setSolution(solution);

      setCreatePopup(true);
    },
    []
  );

  React.useEffect(() => {
    setLoading(true);
    collection.fetch().then(() => {
      setLoading(false);
    });
  }, [collection]);

  return (
    <div className={classes.root}>
      <Grid container>
        {/* {technologies.length > 1 && (
          <Grid item xs={12} style={{ display: "flex" }} justify="center">
            <Button
              variant={compare ? "contained" : "outlined"}
              color={"secondary"}
              size={"small"}
              disabled={false}
              className={classes.compareToggleButton}
              onClick={presenter.toggleComparison}
            >
              {compare ? <FontAwesomeIcon icon="times" /> : "compare solutions"}
            </Button>

            {compare && (
              <Button
                size={"small"}
                onClick={presenter.startComparison}
                disabled={presenter.selection.length < 2}
                style={{ marginLeft: 12 }}
                endIcon={
                  <FontAwesomeIcon icon={["fal", "arrow-circle-right"]} />
                }
              >
                Start comparison
              </Button>
            )}
          </Grid>
        )} */}

        <Grid
          item
          xs={12}
          style={{ display: "flex" }}
          className={classes.technologyContainer}
        >
          {(loading && <LoadingIcon />) || (
            <Grid container justify="center">
              {sortedSolutions().map((solution) => {
                // const comparable = presenter.isComparable(solution);

                return (
                  <Grid item xs={2} md={collection.records.length > 1 ? 1 : 12}>
                    <Tooltip
                      classes={{
                        tooltip: classes.tooltip,
                        arrow: classes.tooltipArrow,
                      }}
                      title={
                        <Typography variant={typographyVariants.body2}>
                          {solution.description}
                        </Typography>
                      }
                      placement="top"
                      arrow
                    >
                      <div
                        className={classnames(
                          classes.technology
                          // !comparable && classes.disabled
                        )}
                        onClick={() => selectTechnology(solution)}
                      >
                        <div className={classes.imageContainer}>
                          <img src={solution.image} width="100%" />
                          {/* {compare && (
                          <Checkbox
                            className={classes.checkbox}
                            checked={presenter.selection.includes(
                              solution.id
                            )}
                          />
                        )} */}
                        </div>
                        <Typography align={"center"} variant={"h5"}>
                          {solution.name}
                        </Typography>
                      </div>
                    </Tooltip>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </Grid>
      </Grid>
      <ProjectionCreateDialog
        onClose={() => setCreatePopup(false)}
        open={createPopup}
        solutionid={(solution && solution.id) || ""}
      />
      {/* <ProjectionCreatePaper
          onChange={createProjectOnChange}
          data={newProjection}
          abort={togglePopup}
          active={createPopup}
          saving={creating}
          onSubmit={saveNewProjection}
          validations={{
            name: "required",
            project: "required",
            solution: "required",
          }}
        /> */}
    </div>
  );
};
