import * as React from "react";
import { StyleDefaultPages } from "../errors/styles-error-pages";
import { usePresenter } from "../../../hooks/use-presenter";
import { PagePermissionIndexPresenter } from "./page-permission-index-presenter";
import { useObserver } from "mobx-react";
import { Grid, Typography } from "@material-ui/core";
import { PageHeader } from "../../components/page/page-header";
import { PageTitles } from "../../components/page/page-titles";
import { Widget } from "../../components/widget/widget";
import { AdministrationMenuAccess } from "../../components/menus/menu-access";
import { TablePermissions } from "./partials/permission-table";
import { PageContent } from "../../components/page/page-content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Page } from "../../components/page/page-wrapper";

interface OwnProps extends StyleDefaultPages {}

export const PagePermissionIndex: React.FC<OwnProps> = (props) => {
  const presenter = usePresenter(
    ({ provider, interactor }) =>
      new PagePermissionIndexPresenter(interactor.user, provider.permission)
  );

  return useObserver(() => {
    const { permissions, collectionLoading, selectPermission } = presenter;

    return (
      <Page>
        <PageContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PageHeader />
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Widget>
                <PageTitles title="Xpert" subtitle={"permissions"} />
                <Typography paragraph variant="body2">
                  The permission profile contains all the information required to authorize a
                  permission to CRU(D) records or access components.
                </Typography>
                <Typography paragraph variant="body2">
                  Here you can create permission profiles that can be assigned to permissions.
                </Typography>
                <br />
                <AdministrationMenuAccess />
              </Widget>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <Widget
                icon={<FontAwesomeIcon icon={["fal", "table"]} />}
                title="Permission overview"
                loading={collectionLoading}
              >
                <TablePermissions onEdit={selectPermission} items={permissions} />
              </Widget>
            </Grid>
          </Grid>
        </PageContent>
      </Page>
    );
  });
};
