import gql from "graphql-tag";
import { runQuery, RunQueryOptions } from "../../../../network/run-query";
import { ProjectionIndexFragment } from "../../graphql/fragments";

import { ProjectionRecord } from "../../projection";

export interface QueryProjectionCollectionArgs {
  projectId: string;
}

const buildQuery = (fragment: string = ProjectionIndexFragment) => gql`
  query ProjectionCollection($projectId: ID!) {
    ProjectionCollection(projectId: $projectId) { 
      projections {
        ${fragment}
      }
    }
  }
`;

export const collectProjections = async (
  options: RunQueryOptions<QueryProjectionCollectionArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;

  return await runQuery<
    QueryProjectionCollectionArgs,
    {
      ProjectionCollection: {
        projections: ProjectionRecord[];
        pagination: {
          start: string;
          end: string;
        };
      };
    }
  >({
    query,
    variables: options.variables,
  });
};
