import gql from "graphql-tag";
import {
  runQuery,
  RunQueryOptions,
} from "../../../../application/network/run-query";
import { ProjectRecord } from "../../../../application/resources/project/project";
import { ProjectFragment } from "../graphql/fragments";
import { InputProject } from "./create";

interface MutationUpdateProjectArgs {
  input: InputProject;
}

/**
 * Returns a Query
 *
 * @param fragment - Query fragment containing the data you wish to retrieve
 *
 * @returns DocumentNode containing the query string
 */
const buildQuery = (fragment: string = ProjectFragment) => gql`
  mutation UpdateProject($input: inputUpdatedProject!) {
    updateProject(input: $input) {
      ${fragment}
    }
  }
`;

/**
 * Attempts to update a project and expects to return the updated project or error
 *
 * @param options - Options for creating the GraphQL Query.
 *
 * @returns GraphQL response
 */
export const updateProject = async (
  options: RunQueryOptions<MutationUpdateProjectArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<
    MutationUpdateProjectArgs,
    { updateProject: ProjectRecord }
  >({
    query,
    variables: options.variables,
  });
};
