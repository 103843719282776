import * as React from "react";
import { CalculationValue } from "../../../application/resources/technology/technology";
import {
  makeStyles,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { PentairTheme } from "../../../config/theme/types";
import { typographyVariants } from "../../../config/theme/typography";
import { useObserver } from "mobx-react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/pro-light-svg-icons";

interface RowConfig extends CalculationValue {
  space?: number;
}

interface OwnProps {
  title?: string;
  icon?: IconName;
  values: Array<RowConfig | undefined>;
  variant?: "list";
  hideRecommendedValue?: boolean;
  classes?: Record<
    | "root"
    | "tableRow"
    | "tableCell"
    | "tableValueCell"
    | "before"
    | "name"
    | "value"
    | "suggestedValue"
    | "unit"
    | "listname"
    | "bold"
    | "icon"
    | "titleWrap",
    string
  >;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {
    margin: theme.spacing(0, 0, 3, 0),
    border: "none",
  },
  tableRow: {
    border: "none",
  },
  tableCell: {
    fontWeight: 600,
    padding: theme.spacing(0.2, 0, 0.2, 0),
    border: 0,
    [theme.breakpoints.down("md")]: {
      minWidth: 150,
    },
  },
  tableValueCell: { fontWeight: 400 },
  before: {
    marginRight: theme.spacing(2),
  },
  titleWrap: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  name: {},
  value: {},
  suggestedValue: { opacity: 0.5 },
  unit: {},
  listname: { width: theme.spacing(12), color: theme.palette.silverGray.main },
  bold: { fontWeight: 700 },
  icon: {
    width: theme.spacing(2) + "px !important",
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.blue.main,
  },
}));

const emptyValue = (): CalculationValue => {
  return {
    id: "empty",
    name: "-",
    values: ["-"],
    unit: "-",
    recommendedValues: ["-"],
  };
};

const createSpacing = (space: number) => {
  return 12 * space;
};

export const ValuesTable: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const { title, values, hideRecommendedValue, variant, icon } = props;

  return useObserver(() => {
    return (
      <div className={classes.root}>
        <div className={classes.titleWrap}>
          {icon && (
            <FontAwesomeIcon className={classes.icon} icon={["fal", icon]} />
          )}
          {title && <Typography variant="h4">{title}</Typography>}
        </div>
        <Table style={{ fontSize: "0.825em" }}>
          <TableBody>
            {(values || [])
              .filter((exist) => exist)
              .map((row, index) => {
                if (!row) {
                  row = emptyValue();
                }
                return row.space ? (
                  <div
                    key={index}
                    style={{ height: createSpacing(row.space) }}
                  />
                ) : variant === "list" ? (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell
                      className={classnames(
                        classes.tableCell,
                        classes.listname
                      )}
                    >
                      <Typography
                        className={classes.bold}
                        variant={typographyVariants.body2}
                        color={"inherit"}
                      >
                        {row.name}
                      </Typography>
                    </TableCell>

                    <TableCell
                      className={classnames(classes.tableCell, classes.value)}
                    >
                      <Typography variant={typographyVariants.body2}>
                        : {(row.values || []).join(" - ")} {row.unit}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell
                      className={classnames(classes.tableCell, classes.name)}
                    >
                      <Typography variant={typographyVariants.body2}>
                        {row.name}
                      </Typography>
                    </TableCell>

                    <TableCell
                      className={classnames(classes.tableCell, classes.value)}
                    >
                      <Typography variant={typographyVariants.body2}>
                        {(row.values || []).join(" - ")} {row.unit}
                      </Typography>
                    </TableCell>

                    {!hideRecommendedValue && (
                      <TableCell
                        className={classnames(
                          classes.tableCell,
                          classes.suggestedValue
                        )}
                      >
                        <Typography variant={typographyVariants.body2}>
                          {(row.recommendedValues || []).join(" - ")}
                        </Typography>
                      </TableCell>
                    )}

                    {/* <TableCell className={classnames(classes.tableCell, classes.unit)}>
                      <Typography variant={typographyVariants.body2}>{row.unit}</Typography>
                    </TableCell> */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    );
  });
};
