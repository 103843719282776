import {
  GraphQLModel,
  BaseGraphQLModel,
} from "../../base/graphql/graphql-model";
import { ProfileRecord, ProfilePermissions } from "../profile/profile";
import { v4 as uuid } from "uuid";

export type UserProfileRecord = {
  id: string;
  role: string;
  groups: string[];
  profile: ProfileRecord;
  permissions: ProfilePermissions;
};

export interface UserProfile extends GraphQLModel<UserProfileRecord> {
  resolved: boolean;
  checkPermissions(permission: string | string[]): boolean;
}

export class DefaultUserProfile
  extends BaseGraphQLModel<UserProfileRecord>
  implements UserProfile {
  public resolved: boolean = false;

  public defaultValues = {
    id: uuid(),
    name: "Default name",
    groups: [],
    role: "default",
    permissions: {
      allow: [],
      deny: [],
    },
    profile: {} as any,
  };

  public checkPermissions = (permission: string | string[]): boolean => {
    let result = { allowed: false, denied: false };

    if (typeof permission === "string") {
      permission = [permission];
    }

    result = permission.reduce((result, p) => {
      return {
        allowed:
          result.allowed ||
          this._checkPermissions(p, this.record.permissions.allow),
        denied:
          result.denied ||
          this._checkPermissions(p, this.record.permissions.deny),
      };
    }, result);

    return result.allowed === true && result.denied === false;
  };

  private _checkPermissions = (
    permission: string,
    permissionList: string[]
  ) => {
    let match = false;

    for (const key in permissionList) {
      const granted = this._grants(permission, permissionList[key]);

      if (
        granted.fullAccess ||
        granted.componentAccess ||
        granted.permissionAccess ||
        granted.recordAccess
      ) {
        match = true;
        break;
      }
    }

    return match === true;
  };

  private _grants = (
    requestedPermission: string,
    profilePermission: string
  ) => {
    const isPermissionGroup: boolean = profilePermission.indexOf(".*") !== -1;
    const groupName = profilePermission.replace(".*", "");
    const splitted = requestedPermission.split("::");

    return {
      // for example: *
      fullAccess: profilePermission === "*",

      // for example: component.* or component.resource.*
      componentAccess: isPermissionGroup && splitted[0].startsWith(groupName),

      // for example component.resource.permission
      permissionAccess: splitted[0] === profilePermission,

      // for example component.resource.permission::id
      recordAccess: requestedPermission === profilePermission,
    };
  };
}
