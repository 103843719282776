import * as React from "react";
import { StyledComponentProps, makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { PageFooter } from "./page-footer";

type PageVariant = "contained" | "centered" | "padded" | "fullscreen";

interface OwnProps
  extends StyledComponentProps<
    | "root"
    | "content"
    | "containedVariant"
    | "paddedVariant"
    | "centeredVariant"
    | "fullscreenVariant"
    | "fullscreenPaperVariant"
    | "footer"
  > {
  variant?: PageVariant;
}

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexDirection: "column", flex: 1 },
  containedVariant: {
    width: "100%",
    maxWidth: theme.spacing(140),
    marginLeft: "auto",
    marginRight: "auto",
    padding: "calc(" + theme.spacing(1) + "px + 2vw)",
    paddingBottom: 0,
  },
  paddedVariant: { padding: "calc(" + theme.spacing(1) + "px + 2vw)" },
  centeredVariant: {
    width: "100%",
    flexGrow: 1,
    margin: "auto",
    background: "#eaeaea",
  },
  footer: {
    paddingTop: theme.spacing(2),
    marginTop: "auto",
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
}));

export const Page: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const { variant } = props;
  const variantClass =
    classes[((variant || "contained") + "Variant") as "containedVariant"];

  return (
    <>
      <div className={classnames(classes.root, variantClass)}>
        {props.children}
        <PageFooter classes={{ root: classes.footer }} />
      </div>
    </>
  );
};
