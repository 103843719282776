import * as React from "react";
import { MenuItem, TextField, TextFieldProps } from "@material-ui/core";
import LoadingIcon from "../../../../v2/common/ui/loading-icon";
import { DefaultFormFieldProps } from "../types";
import { useFormChange } from "../hooks/use-form-change";
import { useFormValidation } from "../hooks/use-form-validation";
import { useSolutionCollection } from "../../../../v2/feature/solution/hooks/use-collection";
import InfoLabel from "../info-label";

interface OwnProps extends DefaultFormFieldProps<string> {
  textfieldProps?: TextFieldProps;
  disabled?: boolean;
}

export const SelectSolution: React.FC<OwnProps> = (props) => {
  const { onChange, changed } = useFormChange(
    (e) => e.target.value,
    props.onChange
  );

  const collection = useSolutionCollection();
  const [loading, setLoading] = React.useState(false);

  const error = useFormValidation(
    props.value,
    props.validations || "required|max:120",
    props.onError
  );

  React.useEffect(() => {
    setLoading(true);
    collection.fetch().then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <TextField
      select
      required
      name="solutionId"
      id="solutionId"
      value={props.value || ""}
      fullWidth
      onChange={onChange}
      label={
        <InfoLabel
          title={props.label || "Solution"}
          description={props.description || "Solution for the project"}
        />
      }
      {...props.textfieldProps}
      disabled={loading || props.disabled}
      error={changed && error ? true : false}
      helperText={changed && error}
      {...props.textfieldProps}
    >
      {loading ? (
        <>
          <MenuItem disabled value={"_loading"}>
            <LoadingIcon scale={0.5} />
          </MenuItem>
        </>
      ) : (
        collection.records.map((solution) => (
          <MenuItem value={solution.id}>{solution.name}</MenuItem>
        ))
      )}
    </TextField>
  );
};
