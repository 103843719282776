import { generatePath, match } from "react-router";
import { observable, computed } from "mobx";

export type RouteMap<RouteNames extends string = string> = Record<
  RouteNames | string,
  RouteOptions
>;

export interface RouteConfig<RouteNames extends string = string> {
  redirections: RedirectionOptions[];
  routes: RouteMap<RouteNames>;
}

export interface BreadcrumbConfig {
  route: string;
  label?: string | ((props: { match: match<any> }) => string);
}

export interface RouteOptions {
  path: string;
  public: boolean;
  title: string;
  component: any;
  mainmenu: boolean;
  requiredPermissions: string[];
  breadcrumbs: BreadcrumbConfig[];
}

export type RouteArguments<
  Arguments = Record<string, string | boolean | number>
> = Arguments;

export interface RedirectionOptions {
  from: string;
  to: string;
}

export enum LocationState {
  Redirect = "redirect",
  Continue = "continue",
}

export interface RouteProvider<RouteNames> {
  routes: RouteOptions[];
  redirections: RedirectionOptions[];
  redirectLocation: string | null;
  locationState: LocationState;
  generatePath(
    routeName: RouteNames,
    args?: Record<string, string | boolean | number>
  ): string;
  getOptions(routeName: RouteNames): RouteOptions;
  validateLocation(pathname?: string): void;
  getRouteFromPath(pathname?: string): RouteOptions | undefined;
}

export class DefaultRouteProvider<RouteNames extends string>
  implements RouteProvider<RouteNames> {
  @observable private _routes: RouteMap = {};
  @observable public redirections: RedirectionOptions[] = [];
  @observable public redirectLocation: string | null = null;

  constructor(config: RouteConfig) {
    this.redirections = config.redirections;
    this._routes = config.routes;
  }

  @computed public get routes(): RouteOptions[] {
    return Object.keys(this._routes).map((key: string) => {
      return this._routes[key];
    });
  }

  @computed public get locationState(): LocationState {
    return this.redirectLocation
      ? LocationState.Redirect
      : LocationState.Continue;
  }

  public generatePath = (name: RouteNames, args?: RouteArguments) => {
    return generatePath(this._routes[name].path, args);
  };

  public getOptions = (name: RouteNames) => {
    return this._routes[name];
  };

  public validateLocation = (pathname?: string): void => {
    const path = pathname || window.location.pathname.replace(/\/$/, "");
    const redirection = this.redirections.filter(
      ({ from }) => from === path
    )[0];
    this.redirectLocation = redirection ? redirection.to : null;
  };

  public getRouteFromPath = (routePath: string) => {
    return this.routes.find((route) => route.path == routePath);
  };
}
