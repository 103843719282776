import * as React from "react";
import { Link } from "react-router-dom";
import { useRoute } from "../../../hooks/use-route";
import { makeStyles } from "@material-ui/core";

interface OwnProps {
  appname?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    display: "inline-flex",
    alignItems: "center",
    marginLeft: 24,
    textDecoration: "none",
    position: "relative",
    paddingRight: 12,
  },
}));

export const TopbarLogo: React.FC<OwnProps> = (props) => {
  const [homepageUrl] = useRoute("app.homepage");
  const classes = useStyles(props);

  return (
    <Link to={homepageUrl} className={`${classes.root}`}>
      <img src="/images/logo-x-flow.svg" height="28" />
    </Link>
  );
};
