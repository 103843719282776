import { makeStyles } from "@material-ui/core"
import { PentairTheme } from "../../../config/theme/types"

export type StyleUserSingle = {
    classes?: Record<"root" | "header" | "sectionWrap", string>;
}

export const useUserSingleStyles = makeStyles<PentairTheme>((theme) => ({
    root: {
        maxWidth: "100vw",
    },
    header: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },
    sectionWrap: {
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
    },
}))