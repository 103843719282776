import * as React from "react";
import { makeStyles } from "@material-ui/core";
import { useObserver } from "mobx-react";
import { InterfacePresenter } from "./default-interface-presenter";
import { ToolBar } from "./partials/toolbar";
import { TopBar } from "./partials/topbar";
import { usePresenter } from "../../hooks/use-presenter";
import { DrawerWrap } from "./partials/wrap";

interface OwnProps {}

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    display: "flex",
    alignItems: "flex-start",
    left: 0,
    right: 0,
    top: 0,
    pointerEvents: "none",
    "& > *": {
      pointerEvents: "auto",
    },
  },
}));

export const DefaultInterface: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const presenter = usePresenter(
    ({ interactor }) =>
      new InterfacePresenter(interactor.user, interactor.interface)
  );

  return useObserver(() => {
    if (!presenter.user || !presenter.legalAgreements) {
      return <></>;
    }

    return (
      <div className={classes.root}>
        <ToolBar
          onToolSelect={presenter.toggleTool}
          active={presenter.activeTool}
        />
        <DrawerWrap {...presenter.config[presenter.activeTool]}>
          {presenter.config[presenter.activeTool].render(
            presenter.data,
            presenter.actions
          )}
        </DrawerWrap>
        <TopBar user={presenter.user.record} onLogout={presenter.logout} />
      </div>
    );
  });
};
