import { Collection } from "../../../application/core/collection";
import { ProfileRecord } from "../../../application/resources/profile/profile";
import {
  collectProfiles,
  QueryProfileCollectionArgs,
} from "./functions/collect";

export class ProfileCollection extends Collection<ProfileRecord> {
  public errors: object[] = [];

  constructor(
    protected _fragment: string | undefined,
    protected _setState: (state: any) => void
  ) {
    super(_setState);
  }

  /**
   * Handles a GraphQL response
   *
   * @param records - Array of partial profile records
   * @param errors - Array of GraphQL errors
   */
  public handleResponse = (
    records: Partial<ProfileRecord>[] | null | undefined,
    errors: object[] = []
  ) => {
    this.records = (records as any[]) || [];
    this.errors = errors;
    this._changed();
  };

  /**
   * Fetches a collection of profiles from AWS
   *
   * @param variables - Optional query variables for example a filter
   *
   */
  public fetch = async (variables?: QueryProfileCollectionArgs) => {
    const res = await collectProfiles({
      variables,
      query: { fragment: this._fragment },
    });
    this.handleResponse(res.data && res.data.ProfileCollection, res.errors);
  };
}
