import { MenuItem, TextField, TextFieldProps } from "@material-ui/core";
import InfoLabel from "../info-label";
import * as React from "react";
import { useFormValidation } from "../hooks/use-form-validation";
import { useFormChange } from "../hooks/use-form-change";
import { DefaultFormFieldProps } from "../types";
import { CalculationValue } from "../../../../application/resources/technology/technology";

interface OwnProps extends DefaultFormFieldProps<Partial<CalculationValue>> {
  textfieldProps?: TextFieldProps;
  disabled?: boolean;
}

export const FormFieldProjectionCalculationValue: React.FC<OwnProps> = (
  props
) => {
  const calculationValue = props.value || {};

  const { onChange, changed } = useFormChange((e) => {
    console.log(e);
    if (e.target.id === calculationValue.id) {
      calculationValue.values = [e.target.value];
    } else {
      calculationValue.unit = e.target.value;
    }
    console.log(calculationValue);
    return calculationValue;
  }, props.onChange);

  const error = useFormValidation(
    props.value,
    props.validations || "required|max:120",
    props.onError
  );

  return (
    <React.Fragment>
      <TextField
        disabled={props.disabled}
        required
        name={calculationValue.id}
        id={calculationValue.id}
        inputProps={{ maxLength: 121 }}
        value={
          (calculationValue.values && calculationValue.values[0]) ||
          (calculationValue.recommendedValues &&
            calculationValue.recommendedValues[0]) ||
          ""
        }
        onChange={onChange}
        fullWidth
        label={
          <InfoLabel
            title={props.label || calculationValue.name || "Calculation value"}
            description={
              props.description ||
              calculationValue.description ||
              "Calculation value"
            }
          />
        }
        error={changed && error ? true : false}
        helperText={changed && error}
        {...props.textfieldProps}
      />
      {calculationValue.unitOptions && calculationValue.unitOptions.length > 1 && (
        <TextField
          select
          disabled={props.disabled}
          required
          name={calculationValue.id + "unit"}
          id={calculationValue.id + "unit"}
          value={calculationValue.unit || calculationValue.unitOptions[0]}
          onChange={onChange}
          label={""}
          error={changed && error ? true : false}
          helperText={changed && error}
        >
          {calculationValue.unitOptions.map((unitOption) => (
            <MenuItem value={unitOption}>{unitOption}</MenuItem>
          ))}
        </TextField>
      )}
    </React.Fragment>
  );
};
