import * as React from "react";
import { useRouteMatch, useHistory } from "react-router";
import { usePresenter } from "../../../hooks/use-presenter";
import { PageProfileSinglePresenter } from "./page-profile-single-presenter";
import { useObserver } from "mobx-react";
import { PageLoading } from "../page-loading";
import { Grid, Button, Typography, Checkbox, useTheme } from "@material-ui/core";
import { PageHeader } from "../../components/page/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageTitles } from "../../components/page/page-titles";
import { AdministrationMenuAccess } from "../../components/menus/menu-access";
import { ProfileFormDetails } from "./partials/profile-form-info";
import { StyleDefaultPages } from "../errors/styles-error-pages";
import { useEventHandler } from "../../../hooks/use-eventmapper";
import { PermissionSet } from "./partials/permission-set-component";
import { PageContent } from "../../components/page/page-content";
import { Widget } from "../../components/widget/widget";
import { PentairTheme } from "../../../config/theme/types";
import { Page } from "../../components/page/page-wrapper";

interface OwnProps extends StyleDefaultPages {}

export const PageProfileSingle: React.FC<OwnProps> = (props) => {
  const match = useRouteMatch<{ profile: string }>();
  const history = useHistory();
  const theme = useTheme<PentairTheme>();

  const presenter = usePresenter(
    ({ provider, interactor }) =>
      new PageProfileSinglePresenter(
        { routeParams: match.params },
        history.goBack,
        provider.profile,
        provider.permission,
        interactor.user
      )
  );

  const [eventHandler] = useEventHandler();

  return useObserver(() => {
    const {
      changeProperty,
      loading,
      permissionSets,
      toggleAll,
      togglePermission,
      updateProfile,
      profile,
    } = presenter;

    if (loading) {
      return <PageLoading message={presenter.loadingMessage} />;
    }

    return (
      <Page>
        <PageContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PageHeader>
                <Button
                  onClick={updateProfile}
                  variant="contained"
                  color="primary"
                  startIcon={<FontAwesomeIcon icon={["fal", "check"]} />}
                >
                  {profile.isNew ? "Create profile" : "Update profile"}
                </Button>
              </PageHeader>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Widget>
                <PageTitles title="Profile" subtitle={profile.record.name} />
                <Typography paragraph variant="body2">
                  The profile defines which components are accessible to a user.
                </Typography>
                <Typography paragraph variant="body2">
                  To define a profile select the components and specify the permissions you want a
                  user to receive after the profile has been assigned to a user.
                </Typography>
                <br />
                <AdministrationMenuAccess />
              </Widget>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <Widget title={"Details"} loading={loading}>
                <ProfileFormDetails
                  data={{
                    name: profile.record.name,
                    description: profile.record.description,
                  }}
                  onChange={changeProperty}
                  validations={{
                    name: "required",
                    description: "required",
                  }}
                />
              </Widget>
              <Widget title="Components" loading={loading}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3} style={{ borderRight: "1px solid #ddd" }}>
                    <Typography variant="body2" gutterBottom>
                      <strong>Components</strong>
                    </Typography>

                    {permissionSets.map((set) => {
                      return (
                        <div key={set.id}>
                          <Checkbox
                            style={{ margin: "-8px 0 -8px -14px" }}
                            checked={set.checked.all || set.checked.partial}
                            onClick={eventHandler(toggleAll, set)}
                            icon={
                              <FontAwesomeIcon
                                icon={["fal", "square"]}
                                size="xs"
                                style={{ fontSize: 20 }}
                                color={theme.palette.softGray.main}
                              />
                            }
                            checkedIcon={
                              set.checked.all ? (
                                <FontAwesomeIcon
                                  icon={["fas", "check-square"]}
                                  size="xs"
                                  style={{ fontSize: 20 }}
                                  color={theme.palette.secondary.main}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={["fas", "minus-square"]}
                                  size="xs"
                                  style={{ fontSize: 20 }}
                                  color={theme.palette.secondary.main}
                                />
                              )
                            }
                          />
                          {set.name}
                        </div>
                      );
                    })}
                  </Grid>
                  <Grid item xs={12} lg={9}>
                    <div style={{ columnCount: 3, columnGap: 24, paddingLeft: 12 }}>
                      {permissionSets.map((set) => {
                        return <PermissionSet set={set} onCheck={togglePermission}></PermissionSet>;
                      })}
                    </div>
                  </Grid>
                </Grid>
              </Widget>
            </Grid>
          </Grid>
        </PageContent>
      </Page>
    );
  });
};
