import { Typography } from "@material-ui/core";
import * as React from "react";
import { UserRecord } from "../../../../application/resources/user/user";

interface OwnProps {
  user: Partial<UserRecord>;
}

export const UserProfileColumn: React.FC<OwnProps> = (props) => {
  const { user } = props;

  return (
    <Typography>
      {user.profile && user.profile.profile && user.profile.profile.name}
    </Typography>
  );
};
