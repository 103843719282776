import { observable } from "mobx";
import { Project } from "../../../../application/resources/project/project";
import { ProjectProvider } from "../../../../application/resources/project/project-provider";
import {
  Projection,
  ProjectionRecord,
} from "../../../../application/resources/projection/projection";
import { ProjectionProvider } from "../../../../application/resources/projection/projection-provider";
import { Presenter } from "../../../../hooks/use-presenter";
import { UserInteractor } from "../../../../application/resources/user/user-interactor";

interface ComponentProps {
  params: {
    projectid: string;
  };
  url: string;
}

export class ProjectPresenter implements Presenter {
  protected _projectId: string;
  protected _project: Project;
  protected _projections: Projection[];
  public _props: ComponentProps;
  @observable public loading = false;
  @observable public cloning = false;
  @observable public clonePopup = false;
  @observable public cloneString = "";
  @observable public clonedProjectionData: Partial<ProjectionRecord> = {};
  @observable public projectionToClone = "";

  constructor(
    protected _redirect: any,
    protected _projectProvider: ProjectProvider,
    protected _projectionProvider: ProjectionProvider,
    protected _userInteractor: UserInteractor
  ) {}

  public mount = async () => {};

  public get currentUser() {
    return this._userInteractor.currentUser;
  }

  public unmount = async () => {};

  public onClone = async () => {
    await this._projectionProvider
      .clone(this.projectionToClone, this.cloneString)
      .then((projection) => {
        this._redirect("projection.single.tab", {
          projectid: projection.record.project.id,
          projectionid: projection.id,
          tab: "inputs",
        });
      });
  };

  public togglePopup = () => {
    this.clonePopup = this.clonePopup ? false : true;
  };

  public cloneProjectOnChange = (key: any, value: string) => {
    this.cloneString = value;
  };

  public openCloneForm = async (projectionId: string) => {
    this.projectionToClone = projectionId;

    this.togglePopup();
  };
}
