import { Collection } from "../../../application/core/collection";
import { ProjectRecord } from "../../../application/resources/project/project";
import { collectProjects } from "./functions/collect";
import { QueryProjectCollectionArgs } from "./functions/collect";

export class ProjectCollection extends Collection<ProjectRecord> {
  public errors: object[] = [];

  constructor(
    protected _fragment: string | undefined,
    protected _setState: (state: any) => void
  ) {
    super(_setState);
  }

  public handleResponse = (
    records: Partial<ProjectRecord>[] | null | undefined,
    errors: object[] = []
  ) => {
    this.records = (records as any) || {};
    this.errors = errors;
    this._changed();
  };

  public fetch = async (variables?: QueryProjectCollectionArgs) => {
    const res = await collectProjects({
      variables,
      query: { fragment: this._fragment },
    });
    this.handleResponse(
      res.data && res.data.ProjectCollection.projects,
      res.errors
    );
  };
}
