import * as React from "react";
import { Grid } from "@material-ui/core";
import { usePermissionCollection } from "../../../../../../application/resources/permission/v2/hooks/use-collection";
import { SectionTitle } from "../../../../../../v2/common/ui/section-title";
import LoadingIcon from "../../../../../../v2/common/ui/loading-icon";
import { CollectionTable } from "../../../../../../v2/common/table/table-collection";
import { columnValue } from "../../../../../../v2/common/table/table-columns";
import { SearchField } from "../../../../../../v2/feature/project/view/pages/partials/search-field";
interface OwnProps {}

export const PermissionIndexTable: React.FC<OwnProps> = (props) => {
  const collection = usePermissionCollection();
  const [searchQuery, setSearchQuery] = React.useState("");
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true);
    collection.fetch().then(() => {
      setLoading(false);
    });
  }, [collection, searchQuery]);

  return (
    <section>
      <Grid
        container
        spacing={2}
        justify={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={6}>
          <SectionTitle title="Permission overview" icon="table" />
        </Grid>
        <Grid item xs={2}>
          <SearchField passedValue={searchQuery} onChange={setSearchQuery} />
        </Grid>
        <Grid item xs={12}>
          {(loading && <LoadingIcon />) || (
            <CollectionTable
              sortKey={"name"}
              sortDirection={"desc"}
              items={collection.records}
              columns={{
                name: columnValue("Name"),
                id: columnValue("Key"),
                description: columnValue("Description"),
              }}
            />
          )}
        </Grid>
      </Grid>
    </section>
  );
};
