import { observable, computed } from "mobx";

import { Presenter } from "../../hooks/use-presenter";
import { UserInteractor } from "../../application/resources/user/user-interactor";
import { UserProvider } from "../../application/resources/user/user-provider";
import { onAuthUIStateChange, AuthState } from "@aws-amplify/ui-components";
import { UserLegalProvider } from "../../application/resources/user-legal/user-legal-provider";
import { UserLegal } from "../../application/resources/user-legal/user-legal";

export class AuthPresenter implements Presenter {
  @observable public loading: boolean = true;
  @observable public loadingMessage: string = "Loading application";
  private _userLegals: UserLegal;

  constructor(
    private _userInteractor: UserInteractor,
    private _userProvider: UserProvider,
    private _userLegalProvider: UserLegalProvider
  ) {
    onAuthUIStateChange((event) => {
      if (event === AuthState.SignedIn) {
        if (!this.currentUser) {
          this.loadUser().then(() => {
            if (this._userLegals) {
              let acceptedTerms: boolean[] | undefined;
              const requiredAgreements = this._userLegals.record.agreements?.filter(
                (legalAgreement) => legalAgreement.required === true
              );

              acceptedTerms = requiredAgreements?.map(
                (agreement) => agreement.accepted
              );

              if (!this._userLegals.record.agreements.length) {
                this._userInteractor.legalAgreements = false;
              } else if (acceptedTerms.length && acceptedTerms.every(Boolean)) {
                this._userInteractor.legalAgreements = true;
              } else {
                this._userInteractor.legalAgreements = false;
              }
            }

            if (this._userInteractor.currentUser) {
              this.loading = false;
            }
          });
        }
      }
    });
  }

  @computed public get hasAcceptedUserAgreements() {
    return this._userInteractor.legalAgreements;
  }

  public mount = async () => {};

  public unmount = () => {};

  @computed public get currentUser() {
    try {
      return this._userInteractor.currentUser;
    } catch (err) {}
    return undefined;
  }

  public loadUser = async () => {
    this.loading = true;
    this.loadingMessage = "Initializing user";

    const user = await this._userProvider.fetchAuthUser();
    if (user) {
      this._userInteractor.currentUser = user;
      this._userLegals = this._userLegalProvider.get(user.id);

      await this._userLegals.fetch();
    }

    this.loading = false;
  };
}
