import { TextField, TextFieldProps } from "@material-ui/core";
import InfoLabel from "../info-label";
import * as React from "react";
import { useFormValidation } from "../hooks/use-form-validation";
import { useFormChange } from "../hooks/use-form-change";
import { DefaultFormFieldProps } from "../types";

interface OwnProps extends DefaultFormFieldProps<string> {
  textfieldProps?: TextFieldProps;
  disabled?: boolean;
}

export const FormFieldProjectName: React.FC<OwnProps> = (props) => {
  const { onChange, changed } = useFormChange(
    (e) => e.target.value,
    props.onChange
  );

  const error = useFormValidation(
    props.value,
    props.validations || "required|max:120",
    props.onError
  );

  return (
    <TextField
      disabled={props.disabled}
      required
      name="project-name"
      id="project-name"
      inputProps={{ maxLength: 121 }}
      value={props.value || ""}
      onChange={onChange}
      fullWidth
      label={
        <InfoLabel
          title={props.label || "Project name"}
          description={props.description || "Name of the project"}
        />
      }
      error={changed && error ? true : false}
      helperText={changed && error}
      {...props.textfieldProps}
    />
  );
};
