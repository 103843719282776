import { PagePermissionIndex } from "../../view/pages/permission/page-permission-index";
import { createRoute } from "../../application/helpers/route";

export const routesPermission = {
  "permission.index": createRoute({
    path: "/manage/permission/index",
    title: "Permission management",
    component: PagePermissionIndex,
    requiredPermissions: ["iam.permission.list"],
    breadcrumbs: [
      {
        route: "app.administration",
        label: "Manage",
      },
      {
        route: "permission.index",
        label: "Permissions",
      },
    ],
  }),
};
