import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";
import { useObserver } from "mobx-react";
import * as React from "react";
import { useRedirect } from "../../../../../hooks/use-redirect";
import { PageContent } from "../../../../common/layout/page-content";
import { PageHeader } from "../../../../common/layout/page-header";
import { Page } from "../../../../common/layout/page-wrapper";
import { ProfileIndexTable } from "../../../profile/view/table/table";
import { UserIndexTable } from "../table/table";
import { useProjectionSingleStyles } from "../../../../../view/pages/projection/styles-projection-single";
import { StyleDefaultPages } from "../../../../../view/pages/errors/styles-error-pages";
import { TabsUserManagement } from "./partials/tabs";
import { theme } from "../../../../../config/theme/pentair";
import { PermissionIndexTable } from "../../../../../view/pages/permission/v2/index/partials/table";

interface OwnProps extends StyleDefaultPages {}

export const PageUserIndex: React.FC<OwnProps> = (props) => {
  const classes = useProjectionSingleStyles(props);
  const redirect = useRedirect();
  const [activeTab, setActiveTab] = React.useState("users");

  return useObserver(() => {
    return (
      <Page variant="fullscreen">
        <PageContent>
          <div
            className={classes.header}
            style={{ paddingTop: theme.spacing(4) }}
          >
            <PageHeader
              title={"User management"}
              subtitle={"Users"}
              actions={
                <Button
                  onClick={() => redirect("user.create")}
                  startIcon={<FontAwesomeIcon icon={["fal", "plus"]} />}
                  children="Create user"
                />
              }
            />
          </div>
          <div className={classes.sectionBar}>
            <TabsUserManagement
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
          <div className={classes.sectionWrap}>
            {activeTab === "users" && <UserIndexTable />}
            {activeTab === "profiles" && <ProfileIndexTable />}
            {activeTab === "permissions" && <PermissionIndexTable />}
          </div>
        </PageContent>
      </Page>
    );
  });
};
