import gql from "graphql-tag";
import { runQuery, RunQueryOptions } from "../../../network/run-query";
import { SalesForceOpportunityFragment } from "../graphql/fragment";
import { SalesForceOpportunityRecord } from "../single";

interface QuerySalesForceOpportunityArgs {
  OpportunityNumber: string;
}

const buildQuery = (fragment: string = SalesForceOpportunityFragment) => gql`
  query SalesForceOpportunity($OpportunityNumber: String!) {
    SalesForceOpportunity(OpportunityNumber: $OpportunityNumber) {
      ${fragment}
    }
  }
`;

export const fetchSalesForceOpportunity = async (
  options: RunQueryOptions<QuerySalesForceOpportunityArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<
    QuerySalesForceOpportunityArgs,
    { SalesForceOpportunity: SalesForceOpportunityRecord }
  >({
    query,
    variables: options.variables,
  });
};
