import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid } from "@material-ui/core";
import { useObserver } from "mobx-react";
import * as React from "react";
import { ProjectClass } from "../../../../../v2/feature/project/single";
import { UserRecord } from "../../../../../application/resources/user/user";
import { theme } from "../../../../../config/theme/pentair";
import { useRedirect } from "../../../../../hooks/use-redirect";
import { CanView } from "../../../../auth/can-view";
import { HeaderPropertyValue } from "../../../../components/page/header-prop-value";
import { PageTitles } from "../../../../components/page/page-titles";
import {
  StyleSingleHeader,
  useSingleHeaderStyles,
} from "../../../styles-single-header";
import { SharingComponent } from "./project-sharing";

interface OwnProps extends StyleSingleHeader {
  project: ProjectClass;
  user: UserRecord;
}

export const ProjectHeader: React.FC<OwnProps> = (props) => {
  const classes = useSingleHeaderStyles(props);
  const { project, user } = props;
  const record = project.record;
  const redirect = useRedirect();

  return useObserver(() => (
    <div
      style={{
        borderBottom: "1px solid " + theme.palette.softGray.main,
        paddingBottom: 20,
      }}
    >
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={8}>
          <PageTitles title={record.name} subtitle="details" />
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }} alignItems="center">
          <Button
            color="primary"
            children={"create projection"}
            startIcon={<FontAwesomeIcon icon={["fal", "plus"]} />}
            onClick={() =>
              redirect("app.assistant.project", { projectid: record.id || "" })
            }
          />
        </Grid>
      </Grid>
      <div className={classes.propertyBar}>
        <HeaderPropertyValue
          property="Project Owner"
          value={
            record.owner?.attributes.given_name +
            " " +
            record.owner?.attributes.family_name
          }
        />

        <HeaderPropertyValue
          property="Opportunity Number"
          value={record.opportunity?.opportunityNumber}
        />

        <HeaderPropertyValue
          property="Pentair Account Name"
          value={record.opportunity?.account.name}
        />
        <div style={{ justifySelf: "flex-end", marginLeft: "auto" }}>
          <CanView permission={"sharing.project.by.email"}>
            <SharingComponent project={project} user={user} />
          </CanView>
        </div>
      </div>
    </div>
  ));
};
