import { Presenter } from "../../../hooks/use-presenter";
import { UserProvider } from "../../../application/resources/user/user-provider";
import { ProfileProvider } from "../../../application/resources/profile/profile-provider";
import { Profile } from "../../../application/resources/profile/profile";
import { Collection } from "../../../application/base/graphql/graphql-collection";
import { UserProfileProvider } from "../../../application/resources/user-profile/user-profile-provider";
import { UserProfile } from "../../../application/resources/user-profile/user-profile";
import { User } from "../../../application/resources/user/user";
import { computed, observable } from "mobx";
import { UserInteractor } from "../../../application/resources/user/user-interactor";

interface ComponentProps {
  routeParams: { userid: string };
}

let profileCollection: Collection<Profile>;

export class PageUserSinglePresenter implements Presenter {
  private _userId: string;
  private _userProfile: UserProfile;
  private _user: User;
  @observable public pageType: string;
  @observable public profileToAssign: string | undefined;
  @observable public loading = false;
  @observable public isSaving = false;

  constructor(
    protected props: ComponentProps,
    protected back: any,
    protected _userProvider: UserProvider,
    protected _userProfileProvider: UserProfileProvider,
    protected _profileProvider: ProfileProvider,
    protected _userInteractor: UserInteractor
  ) {
    this._userId = props.routeParams.userid;
    if (this._userId) {
      this.pageType = "edit";
      this._user = this._userProvider.get(this._userId);
      this._userProfile = this._userProfileProvider.get(this._userId);
    } else {
      this.pageType = "create";
      this._user = this._userProvider.create();
      this._userProfile = this._userProfileProvider.create();
    }

    if (!profileCollection) {
      profileCollection = this._profileProvider.collect();
    }
  }

  @computed public get formData() {
    const user = this.user.record;
    const userProfile = this.userProfile.record;

    return {
      email: user.attributes.email || "",
      given_name: user.attributes.given_name || "",
      family_name: user.attributes.family_name || "",
      profile: this.profileToAssign || userProfile.profile.id,
    };
  }

  public mount = async () => {
    this.loading = true;
    const loaders = [];

    if (!this._user.isNew) {
      loaders.push(this._user.fetch());
      loaders.push(this._userProfile.fetch());
    }

    await Promise.all(loaders);

    this.loading = false;
  };

  public unmount = () => {};

  @computed public get isNew() {
    return this._user.isNew;
  }

  public get currentUser() {
    return this._userInteractor.currentUser;
  }

  public get user() {
    return this._user;
  }

  public get userProfile() {
    return this._userProfile;
  }

  public get profiles() {
    return profileCollection.items;
  }

  public onSave = async () => {
    this.isSaving = true;
    await this._user.save();

    if (this.pageType === "create") {
      this._user = this._userProvider.get(this._user.id);
      await this._user.fetch();
    }

    try {
      if (this.profileToAssign && this._profileChanged) {
        const profile = this._profileProvider.get(this.profileToAssign);
        const userProfile = await this._profileProvider.assignProfile(
          this._user,
          profile
        );

        if (userProfile.id) {
          this.user.applyChange();
        }
      }

      this.back();
    } catch (err) {
      console.error(err);
    }

    this.isSaving = false;
  };

  public setProfile = (e: any) => {
    this.profileToAssign = e.target.value;
  };

  public onChange = (key: any, value: string) => {
    if (key === "profile") {
      this.profileToAssign = value;
    }
    this._user.changeAttribute(key, value);
  };

  private get _profileChanged() {
    return (
      this._userProfile.record.profile.id !== this.profileToAssign ||
      this._user.isNew
    );
  }
}
