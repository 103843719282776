import { Presenter } from "../../../hooks/use-presenter";
import { observable, computed } from "mobx";
import {
  Validator,
  ValidationErrors,
  ValidationError,
} from "../../../application/business/utils/validator";

export interface FormProps<TData> {
  data: TData;
  validations?: Partial<Record<keyof TData, string>>;
  onSubmit?(): void;
  onChange?(key: keyof TData, value: any, error: ValidationError | false): void;
  errors?: ValidationErrors | false;
}

export class FormPresenter<TData> implements Presenter {
  @observable public data: TData;
  @observable private _errors: ValidationErrors<TData> | false;

  constructor(
    protected _component: FormProps<TData>,
    protected _validator: Validator
  ) {
    this.data = _component.data || ({} as any);
  }

  public mount = () => {
    this.data = this._component.data;
  };

  public unmount = () => {};

  @computed get errors(): ValidationErrors {
    return { ...(this._component.errors || {}), ...(this._errors || {}) };
  }

  public validate = (data?: Partial<TData>) => {
    return this._validator.validate(
      data || (this.data as any),
      this._component.validations || ({} as any)
    );
  };

  public change = (key: keyof TData, value: any) => {
    this.data[key] = value;
    this._errors = this.validate();

    if (this._component.onChange) {
      this._component.onChange(key, value, this._errors && this._errors[key]);
    }
  };

  public submit = () => {
    this._errors = this.validate();
    console.log(this._errors);

    if (!this._errors && this._component.onSubmit) {
      this._component.onSubmit();
    }
  };
}
