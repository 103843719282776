import * as React from "react";
import { Typography, Theme, makeStyles, useTheme } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NotificationRecord } from "../../../../application/resources/notification/notification";
import { useObserver } from "mobx-react";

interface OwnProps {
  notification: NotificationRecord;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    paddingBottom: 12,
    marginBottom: 18,
    display: "flex",
  },
  body: {
    margin: "6px 0",
  },
  icons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flexGrow: 0,
    flexShrink: 0,
  },
}));

export const DrawerNotification: React.FC<OwnProps> = ({ notification }) => {
  const classes = useStyles();
  const theme = useTheme();

  return useObserver(() => {
    return (
      <div className={classes.root}>
        <div>
          <Typography variant="h6">{notification.name}</Typography>
          <Typography variant="body1" gutterBottom className={classes.body}>
            {notification.description}
          </Typography>

          <Typography variant="body1">
            <small>{notification.createdAt}</small>
          </Typography>
        </div>
        <div className={classes.icons}>
          <FontAwesomeIcon
            size={"xs"}
            icon={["fas", "chevron-right"]}
            color={theme.palette.secondary.main}
          />
          <FontAwesomeIcon icon={["fal", "exclamation-circle"]} color="#D31E1E" />
        </div>
      </div>
    );
  });
};
