import { Typography } from "@material-ui/core";
import * as React from "react";
import { typographyVariants } from "../../../config/theme/pentair-theme/typography";

interface OwnProps {
  property: string;
  value: any;
}

export const HeaderPropertyValue: React.FC<OwnProps> = (props) => {
  const { property, value } = props;

  return (
    <div>
      <Typography variant={typographyVariants.body2}>
        <strong>{property}</strong>
        <br />
        {value}
      </Typography>
    </div>
  );
};
