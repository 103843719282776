import { makeStyles } from "@material-ui/core";
import { PentairTheme } from "../../../config/theme/types";

export type StyleProjectionSingle = {
  classes?: Record<"root" | "header" | "sectionWrap", string>;
};

export const useProjectionSingleStyles = makeStyles<PentairTheme>((theme) => ({
  root: {
    maxWidth: "100vw",
  },
  header: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  sectionWrap: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  paperpopupWrap: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    left: 0,
    top: 0,
    background: "rgba(0,0,0,.6)",
    backdropFilter: "blur(4px)",
    zIndex: 10,
  },
  clickArea: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
  },
  paperpopup: {
    zIndex: 5,
    width: "60vw",
    maxWidth: "438px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  strong: {
    color: theme.palette.brightBlue.main,
  },
}));
