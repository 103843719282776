import { TableColumnProps } from "./table-column";

export const resolveTableValues = <T extends any>(
  items: any[],
  columns: Partial<Record<keyof T | string, TableColumnProps<T>>>
) => {
  return items.map((item) => {
    const parsed = { ...item };
    Object.keys(columns).forEach((key) => {
      if (key in columns) {
        const config = columns[key];
        if (config) {
          if (parsed && key in parsed) {
            let value: any = parsed[key];

            if (config.value && typeof config.value === "function") {
              value = config.value(value, parsed);
            }

            (parsed as any)[key] = value || config.defaultValue;
          }
        }
      }
    });
    return { item, parsed };
  });
};
