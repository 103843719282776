import { createSalesForceConfig } from "./functions/create";
import { deleteSalesForceConfig } from "./functions/delete";
import { fetchSalesForceConfig } from "./functions/fetch";
import { updateSalesForceConfig } from "./functions/update";
import { fetchSalesForceOpportunity } from "./functions/opportunity";

import { take } from "../helper/take";

export interface SalesForceConfigRecord {
  username: string;
  password: string;
  apikey: string;
}

export interface SalesForceOpportunityRecord {
  account: {
    id: string;
    name: string;
  };
  id: string;
  name: string;
  opportunityNumber: string;
}

export class SalesForceConfigClass {
  private _record: Partial<SalesForceConfigRecord> = {};
  private _changes: Partial<SalesForceConfigRecord> = {};

  public isFetching: boolean = false;
  public isDeleting: boolean = false;
  public isUpdating: boolean = false;
  public isCreating: boolean = false;

  public get record(): Partial<SalesForceConfigRecord> {
    const record = { ...this._record, ...this._changes };

    return record;
  }

  public get changes(): Partial<SalesForceConfigRecord> {
    return this._changes;
  }

  constructor(
    public id?: string,
    public fragment?: string,
    private _setState?: (state: SalesForceConfigClass) => void
  ) {}

  public setState = <T extends Record<string, any>>(
    obj?: T,
    k?: keyof T,
    value?: any
  ) => {
    if (obj && k) {
      obj[k] = value;
    }

    if (this._setState) {
      this._setState({ ...this });
    }
  };

  public handleResponse = (
    record: Partial<SalesForceConfigRecord> | null | undefined,
    errors: object[] = []
  ) => {
    this._record = { ...this._record, ...(record || {}) };
    // this.errors = errors;
    this.setState();
  };

  public get input(): Partial<SalesForceConfigRecord> {
    return take(this.record, ["username", "password", "apikey"]);
  }

  public change = (key: keyof SalesForceConfigRecord, value: any) => {
    this._changes = { ...this._changes, [key]: value };
    this.setState();
  };

  public fetch = async () => {
    const res = await fetchSalesForceConfig({
      query: { fragment: this.fragment },
    });
    this.handleResponse(res.data && res.data.SalesForceConfig, res.errors);
    return res;
  };

  public fetchOpportunity = async (OpportunityNumber: string) => {
    const res = await fetchSalesForceOpportunity({
      variables: { OpportunityNumber },
      query: { fragment: this.fragment },
    });

    if (res === null) {
      throw new Error(
        "No opportunity found. Please enter a valid Salesforce opportunity number"
      );
    }

    return res;
  };

  public create = async () => {
    const res = await createSalesForceConfig({
      variables: { input: this.input },
      query: { fragment: this.fragment },
    });
    this.handleResponse(
      res.data && res.data.createSalesForceConfig,
      res.errors
    );
    return res;
  };

  public update = async () => {
    const res = await updateSalesForceConfig({
      variables: { ...this.input },
      query: { fragment: this.fragment },
    });
    this.handleResponse(
      res.data && res.data.updateSalesForceConfig,
      res.errors
    );
    return res;
  };

  public delete = async () => {
    if (!this.id) {
      throw new Error("ID is not set");
    }
    const res = await deleteSalesForceConfig({
      variables: { id: this.id },
      query: { fragment: this.fragment },
    });
    this.handleResponse(
      res.data && res.data.deleteSalesForceConfig,
      res.errors
    );
    return res;
  };
}
