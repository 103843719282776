import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react";
import { useEventHandler } from "../../../hooks/use-eventmapper";
import { usePresenter } from "../../../hooks/use-presenter";
import { useProjectionSingleStyles } from "../../pages/projection/styles-projection-single";
import { FormPresenter, FormProps } from "../form/form-presenter";
import InfoLabel from "../form/info-label";
import { PageTitles } from "../page/page-titles";
import * as React from "react";
import { typographyVariants } from "../../../config/theme/typography";
import { useProjectionCollection } from "../../../application/resources/projection/v2/hooks/use-collection";

type FormData = {
  case?: string;
};

interface OwnProps extends FormProps<FormData> {
  active: boolean;
  saving: boolean;
  abort: () => void;
  classes?: Record<"root", string>;
  projectId: string;
}

export const ProjectionClonePaper: React.FC<OwnProps> = (props) => {
  const classes = useProjectionSingleStyles(props);
  const [eventHandler] = useEventHandler();
  const presenter = usePresenter(
    ({ utils }) => new FormPresenter<FormData>(props, utils.validator)
  );
  const collection = useProjectionCollection();

  const checkDuplicate = React.useCallback((e: any) => {
    if (
      collection.records.filter(
        (projection) => projection.case === e.target.value
      ).length > 0
    ) {
      return true;
    }

    eventHandler(presenter.change, "case", e.target.value);
    return false;
  }, []);

  React.useEffect(() => {
    collection.fetch({
      projectId: props.projectId,
    });
  }, [collection]);

  return useObserver(() => {
    const { active, abort, saving, data } = props;
    const { errors, submit } = presenter;

    if (!active) {
      return null;
    }

    return (
      <div className={classes.paperpopupWrap}>
        <div className={classes.clickArea} onClick={() => abort()} />
        <Paper className={classes.paperpopup} square>
          <Grid container spacing={1} justify={"flex-end"}>
            <Grid item xs={12}>
              <PageTitles title={"Clone"} subtitle={"Projection"} />
              <Typography variant={typographyVariants.body2}>
                Please enter the following details to clone an existing
                projection
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                margin="normal"
                placeholder="Enter case name"
                onChange={checkDuplicate}
                value={data.case}
                id="case"
                inputProps={{ maxLength: 255 }}
                error={errors.case ? true : false}
                helperText={errors.case}
                label={<InfoLabel title="Case" description="Case name" />}
              />
            </Grid>
            <Grid
              item
              xs={"auto"}
              style={{ textAlign: "right", paddingTop: "0" }}
            >
              <Button
                style={{ width: "100%" }}
                color="primary"
                children="Clone"
                disabled={saving}
                startIcon={
                  saving ? (
                    <FontAwesomeIcon icon={["fal", "spinner"]} spin />
                  ) : (
                    <FontAwesomeIcon icon={["fal", "check"]} />
                  )
                }
                onClick={() => submit()}
              />
            </Grid>
            <Grid
              item
              xs={"auto"}
              style={{ textAlign: "right", paddingTop: "0" }}
            >
              <Button
                style={{ width: "100%" }}
                color="default"
                children="cancel"
                disabled={saving}
                startIcon={<FontAwesomeIcon icon={["fal", "times"]} />}
                onClick={() => abort()}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  });
};
