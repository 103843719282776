import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, StyleRules } from "@material-ui/core";
import * as React from "react";
import { PentairTheme } from "../../../config/theme/types";
import classNames from "classnames";

interface OwnProps {
  steps: string[];
  activeStep: string;
  onStepClick: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles(
  (theme: PentairTheme): StyleRules => ({
    root: {},
    stepContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: theme.spacing(2),
    },
    stepWrap: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    step: {
      cursor: "pointer",
      borderRadius: "50%",
      height: theme.spacing(2),
      width: theme.spacing(2),
      padding: theme.spacing(1),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      color: theme.palette.white.main,
      backgroundColor: theme.palette.softGray.main,
    },
    stepActive: {
      backgroundColor: theme.palette.brightBlue.main,
    },
    stepDone: {
      backgroundColor: theme.palette.success.main,
    },
    line: {
      width: "100%",
      backgroundColor: theme.palette.softGray.main,
      height: "1px",
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  })
);

export const SteppedProgressBar: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const { steps, activeStep, onStepClick } = props;
  const [reachedFinalStep, setReachedFinalStep] = React.useState(false);
  const onClickHandler = (step: string) => {
    if (reachedFinalStep) {
      onStepClick(step);
    }
  };

  React.useEffect(() => {
    if (activeStep === "summary") {
      setReachedFinalStep(true);
    }
  }, [activeStep]);

  return (
    <React.Fragment>
      <div className={classes.stepContainer}>
        {steps.map((step, index) => {
          return (
            <React.Fragment>
              <div className={classes.stepWrap}>
                <a
                  onClick={() => onClickHandler(step)}
                  className={classNames(
                    classes.step,
                    step === activeStep && classes.stepActive,
                    steps.lastIndexOf(activeStep) > index && classes.stepDone
                  )}
                >
                  {index !== steps.length - 1 ? (
                    index + 1
                  ) : (
                    <FontAwesomeIcon icon={["fal", "flag"]} />
                  )}
                </a>
              </div>
              {index !== steps.length - 1 && (
                <div className={classes.line}></div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </React.Fragment>
  );
};
