import { TextField, TextFieldProps } from "@material-ui/core";
import InfoLabel from "../info-label";
import * as React from "react";
import { useFormValidation } from "../hooks/use-form-validation";
import { useFormChange } from "../hooks/use-form-change";
import { DefaultFormFieldProps } from "../types";

interface OwnProps extends DefaultFormFieldProps<string> {
  textfieldProps?: TextFieldProps;
}

export const FormFieldSalesforceApikey: React.FC<OwnProps> = (props) => {
  const error = useFormValidation(
    props.value,
    props.validations || "required",
    props.onError
  );

  const { onChange, changed } = useFormChange(
    (e) => e.target.value,
    props.onChange
  );

  return (
    <TextField
      required
      name="salesforce-api-key"
      id="salesforce-api-key"
      inputProps={{ maxLength: 255 }}
      placeholder="Unchanged"
      onChange={onChange}
      type={"password"}
      fullWidth
      label={
        <InfoLabel
          title={props.label || "Salesforce API key"}
          description={props.description || "Salesforce API key to connect"}
        />
      }
      error={changed && error ? true : false}
      helperText={changed && error}
      {...props.textfieldProps}
    />
  );
};
