import { Presenter } from "../../../hooks/use-presenter";
import { AssistantProvider } from "../../../application/resources/assistant/assistant-provider";
import { computed, observable } from "mobx";
import { Collection } from "../../../application/base/graphql/graphql-collection";
import { AssistantResponse } from "../../../application/resources/assistant/assistant-response";
import {
  Validator,
  ValidationErrors,
} from "../../../application/business/utils/validator";

let collection: Collection<AssistantResponse>;

export class AssistantPresenter implements Presenter {
  @observable private _error: string | undefined;
  @observable public loading: boolean = true;
  @observable public isSubmitting: boolean = false;
  @observable public message: string = "";
  @observable public currentIndex: number = 0;
  @observable public validationErrors: ValidationErrors | false = false;

  constructor(
    protected _assistantProvider: AssistantProvider,
    protected validator: Validator
  ) {
    collection = this._assistantProvider.collect();
  }

  @computed public get messages() {
    return collection.items;
  }

  @computed public get currentMessage() {
    return this.messages.slice(-1, this.messages.length)[0];
  }

  @computed public get messageFormat() {
    return (
      this.currentMessage && this.currentMessage.record.message.inputFormat
    );
  }

  @computed public get isDone() {
    return (
      this.currentMessage &&
      this.currentMessage.record.dialogState === "Fulfilled"
    );
  }

  @computed public get isError() {
    return this._error ||
      (this.currentMessage &&
        this.currentMessage.record.dialogState === "Failed")
      ? true
      : false;
  }

  @computed public get errorMessage() {
    return (
      this._error ||
      (this.currentMessage && this.currentMessage.record.message.messageText)
    );
  }

  public mount = async () => {
    this.loading = true;
    await this._assistantProvider.startSession();

    this.loading = false;
  };

  public unmount = () => {
    this._assistantProvider.reset();
  };

  public validate = (answer: string) => {
    if (answer.toLowerCase() !== "skip") {
      this.validationErrors = this.validator.validate(
        { answer },
        {
          answer:
            "required" + (this.messageFormat ? "|" + this.messageFormat : ""),
        }
      );
    }
  };

  public submitMessage = async (answer: string = "") => {
    if (this.currentMessage) {
      if (this.messageFormat === "decimal") {
        answer = answer.replace(",", ".");
      }
      this.currentMessage.givenAnswer = answer;
    }

    this.validate(answer);
    if (this.validationErrors) {
      return;
    }

    this.isSubmitting = true;

    try {
      const response = await this._assistantProvider.sendMessage(answer);
      this.currentIndex++;
      this.onChange(response.sessionAttributes.technologies);
      if (this.isDone) {
        this.onDone();
      }
    } catch (err) {
      this._error = err.toString();
    }

    this.isSubmitting = false;
  };

  public reset = () => {
    this._error = undefined;
    this._assistantProvider.reset();
    this.onChange([]);
    this.mount();
  };

  public onChange = (technologies: string[]) => {};
  public onDone = () => {};
}
