import { CheckboxClassKey } from "@material-ui/core/Checkbox";
import { FormHelperTextClassKey } from "@material-ui/core/FormHelperText";
import { FormLabelClassKey } from "@material-ui/core/FormLabel";
import { InputClassKey } from "@material-ui/core/Input";
import { InputBaseClassKey } from "@material-ui/core/InputBase";
import { InputLabelClassKey } from "@material-ui/core/InputLabel";
import { RadioClassKey } from "@material-ui/core/Radio";
import { SelectClassKey } from "@material-ui/core/Select";
import { CSSProperties } from "@material-ui/styles";

import { PentairBrightBlue, PentairDarkGray } from "../colors";
import { typography } from "../typography";
import { PentairRed } from "../../colors";

export const MuiInputBase: Partial<Record<InputBaseClassKey, CSSProperties>> = {
  root: {
    ...typography("body2"),
    fontWeight: 400,
    "& $input": {
      paddingTop: 12,
      "&::placeholder": {
        color: PentairDarkGray[300],
      },
    },

    "&$error": {
      color: PentairRed[500],
      "&::placeholder": {
        color: PentairRed[500],
      },
    },
  },
};

export const MuiInput: Partial<Record<InputClassKey, CSSProperties>> = {
  root: {
    "&:hover": {
      "&$error": {
        "&$underline:after": {
          transform: "scale(0)",
          borderBottom: "2px solid  currentColor",
        },
      },
    },
    "&$error": {
      "&$underline:before": {
        borderBottom: "1px solid currentColor",
      },
      "&$underline:hover:not($disabled):before": {
        borderBottom: "1px solid  currentColor",
      },
      "&$underline:after": {
        transform: "scale(0)",
        borderBottom: "2px solid  currentColor",
      },
      "&$underline$focused:not($disabled):after": {
        transform: "scale(1)",
      },
    },
    "&$underline": {
      "&:before": {
        borderBottom: "1px solid #DADADA",
      },
      "&:hover:not($disabled):before": {
        borderBottom: "1px solid " + PentairDarkGray[200],
      },
      "&:after": {
        borderBottom: "2px solid " + PentairDarkGray[200],
      },
      "&:hover:not($disabled):after": {
        borderBottom: "2px solid " + PentairDarkGray[200],
      },
    },
  },
};

export const MuiFormLabel: Partial<Record<FormLabelClassKey, CSSProperties>> = {
  root: {},
};

export const MuiSelect: Partial<Record<SelectClassKey, CSSProperties>> = {
  root: {},
};

export const MuiFormHelperText: Partial<Record<FormHelperTextClassKey, CSSProperties>> = {
  root: {
    textTransform: "uppercase",
    fontWeight: 600,
    color: PentairDarkGray[600],
    fontSize: "12px",
  },
};

export const MuiInputLabel: Partial<Record<InputLabelClassKey, CSSProperties>> = {
  // root: {
  //   "&$error": {
  //     color: PentairDarkGray[600],
  //   },
  // },
  shrink: {
    textTransform: "uppercase",
    fontWeight: 600,
    color: PentairDarkGray[600],
    fontSize: "16px",
  },
};

export const MuiCheckbox: Partial<Record<CheckboxClassKey, CSSProperties>> = {
  root: {
    color: PentairDarkGray[200],

    "&$checked svg": {
      fill: PentairBrightBlue[500],
    },
    "& svg": {
      fontSize: "24px",
    },
  },
};

export const MuiRadio: Partial<Record<RadioClassKey, CSSProperties>> = {
  root: {
    color: PentairDarkGray[200],
    "&$checked": {
      color: PentairBrightBlue[500],
    },
    "& svg": {
      fontSize: "24px",
    },
  },
};
