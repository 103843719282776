export const ProjectionFragment = `
  id
  name
  version
  versions {
    versionId
    lastModified
    designedBy {
      id
      attributes {
        given_name
        family_name
      }
    }
    approvedBy {
      id
      attributes {
        given_name
        family_name
      }
    }
    isLatest 
    note
  }
  project {
    id
    name
  }
  projectionValues {
    id
    name
    values
    recommendedValues
    unit
    unitOptions
    valueOptions
    disabled
    error
    category
    decimals
  }
  createdBy {
    id
    attributes {
      given_name
      family_name
    }
  }
  solution {
    id
    name
    oneliner
    description
    image
    techImage
    techImageMap {
      title
      titleVariant
      location
      rotation
      width
      values
      align
      line
      info
    }
  }
`;

export const ProjectionIndexFragment = `
  id
  name
  solution {
    id
    name
  }
  version
  createdBy {
    id
    attributes {
      given_name
      family_name
    }
  }
  approver {
    id
    attributes {
      given_name
      family_name
    }
  }
  status
  createdAt
  updatedAt
  updatedBy {
    id
    attributes {
      given_name
      family_name
    }
  }
`;
