import * as React from "react";
import {
  Typography,
  useTheme,
  Grid,
  Button,
  makeStyles,
} from "@material-ui/core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePresenter } from "../../hooks/use-presenter";
import { PageDashboardPresenter } from "./page-dashboard-presenter";
import { useObserver } from "mobx-react";
import { CanView } from "../auth/can-view";
import { Link } from "react-router-dom";
import { PentairTheme } from "../../config/theme/types";

interface OwnProps {
  classes?: Record<
    | "root"
    | "layout"
    | "content"
    | "intro"
    | "button"
    | "buttonLabel"
    | "buttonIcon",
    string
  >;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#fff",
    height: "100vh",
  },

  content: {
    textAlign: "center",
    maxWidth: 900,
  },

  intro: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },

  button: {
    height: theme.spacing(8),
    padding: theme.spacing(2),
    fontSize: "1rem",
    "&:before": {
      top: "85%",
    },
  },

  buttonLabel: {
    flexDirection: "column",
  },

  buttonIcon: {
    margin: 0,
    marginBottom: theme.spacing(1),
  },
}));

export const PageDashboard: React.FC<OwnProps> = (props) => {
  const presenter = usePresenter(
    ({ provider, interactor }) =>
      new PageDashboardPresenter(provider.routes, interactor.user.currentUser)
  );

  const theme = useTheme();
  const classes = useStyles(props);

  return useObserver(() => {
    const { user, options } = presenter;

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <FontAwesomeIcon
            icon={["fal", "user-headset"]}
            size={"4x"}
            color={theme.palette.primary.main}
          />

          <div className={classes.intro}>
            <Typography gutterBottom variant={"h1"} color={"primary"}>
              Hi{" "}
              {user.attributes.nickname ||
                user.attributes.name ||
                user.attributes.given_name}
              , Welcome to Pentair X-Flow Xpert
            </Typography>

            <Typography paragraph variant={"body1"} gutterBottom>
              I am Xpert Assistant, the world’s first conversational interface
              in the membrane filtration industry. <br />I am here to assist you
              in any way I can.
            </Typography>
          </div>

          <Typography paragraph variant={"h3"}>
            What would you like to do?{" "}
          </Typography>

          <Grid
            container
            spacing={1}
            direction={"row"}
            justify={"center"}
            alignItems="center"
          >
            {options.map((cfg, index) => (
              <CanView permission={cfg.route.requiredPermissions}>
                <Grid
                  key={index}
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  style={{ maxWidth: 170 }}
                >
                  <Button
                    fullWidth
                    classes={{
                      root: classes.button,
                      label: classes.buttonLabel,
                      startIcon: classes.buttonIcon,
                    }}
                    variant={"outlined"}
                    color={"secondary"}
                    component={Link}
                    to={cfg.href}
                  >
                    {cfg.label}
                  </Button>
                </Grid>
              </CanView>
            ))}
          </Grid>
        </div>
      </div>
    );
  });
};
