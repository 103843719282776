import { observable, computed } from "mobx";
import { Presenter } from "../../hooks/use-presenter";
import { UserInteractor } from "../../application/resources/user/user-interactor";

export class CanViewPresenter implements Presenter {
  @observable public isAdmin: any = false;

  @computed public get allowed() {
    let allowed = false;

    try {
      allowed = allowed || this._userInteractor.currentUser.can(this._permissions);
      allowed = allowed || this._roles.includes("public");
    } catch (err) {
      allowed = false;
    }

    return allowed;
  }

  constructor(
    private _userInteractor: UserInteractor,
    private _permissions: string | string[] = [],
    private _roles: string[] = []
  ) {}
  public mount = () => {};
  public unmount = () => {};
}
