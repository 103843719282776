import { useCallback, useState } from "react";
import { useSalesForce } from "../../../../application/resources/salesforce/hooks/use-single";
import { SalesForceOpportunityRecord } from "../../../../application/resources/salesforce/single";
import { useFormChange } from "../../../components/form/hooks/use-form-change";
import { useFormValidation } from "../../../components/form/hooks/use-form-validation";
import { DefaultFormFieldProps } from "../../../components/form/types";
import * as React from "react";
import { SearchField } from "../../../../v2/feature/project/view/pages/partials/search-field";

interface OwnProps extends DefaultFormFieldProps<string> {
  onFetchComplete: (opportunity?: SalesForceOpportunityRecord) => void;
  searchOnSubmit?: boolean;
  searchOnEnter?: boolean;
}

export const SearchFieldOpportunity: React.FC<OwnProps> = (props) => {
  const salesforce = useSalesForce();
  const [errors, setErrors] = useState<Error[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const { onChange, changed } = useFormChange((value) => value, props.onChange);
  const [opportunity, setOpportunity] = useState<boolean>(false);
  let error = useFormValidation(
    props.value,
    props.validations || "required",
    props.onError
  );

  if (errors && errors.length) {
    error = errors[0].message;
  }

  const fetchOpportunity = useCallback(async (value) => {
    onChange(value);
    setLoading(true);
    if (value === "" || value === " " || value === null) {
      setOpportunity(false);
      setLoading(false);
      props.onFetchComplete();

      return;
    }

    let opportunity;
    try {
      const response = await salesforce.fetchOpportunity(value);
      opportunity = response.data?.SalesForceOpportunity;
    } catch (errors) {
      setErrors(errors);
    } finally {
      if (opportunity === undefined || opportunity === null) {
        setErrors([
          {
            name: "Error while fetching opportunity",
            message:
              "No opportunity found. Please enter a valid Salesforce opportunity number",
          },
        ]);
      } else {
        setErrors([]);
      }

      setOpportunity(true);

      props.onFetchComplete(opportunity);
      setLoading(false);
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <SearchField
        required
        label={props.label}
        description={props.description}
        onChange={fetchOpportunity}
        passedValue={props.value}
        error={changed && error ? true : false}
        helperText={changed && error}
        loading={loading}
        showDelete={opportunity}
        searchOnSubmit={props.searchOnSubmit}
        searchOnEnter={props.searchOnEnter}
      />
    </div>
  );
};
