import { useClassInstance } from "../../../../application/core/use-class-instance";
import { ProfileCollection } from "../collection";

/**
 * React hook to use the ProfileCollection class.
 *
 * @param fragment - override the default fragment set on the collectProfiles function
 *
 * @returns ProfileCollection class
 */
export const useProfileCollection = (fragment?: string) => {
  return useClassInstance<ProfileCollection>(
    (setState) => new ProfileCollection(fragment, setState),
    [fragment]
  );
};
