import * as React from "react";
import { useObserver } from "mobx-react";
import { Typography, Checkbox, useTheme } from "@material-ui/core";
import { PermissionSetProps, EditablePermission } from "../page-profile-single-presenter";
import { useEventHandler } from "../../../../hooks/use-eventmapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PentairTheme } from "../../../../config/theme/types";

interface OwnProps {
  set: PermissionSetProps;
  onCheck(set: EditablePermission): void;
}

export const PermissionSet: React.FC<OwnProps> = (props) => {
  const { set, onCheck } = props;
  const theme = useTheme<PentairTheme>();

  const [eventHandler] = useEventHandler();

  return useObserver(() => {
    return (
      <div
        key={set.id}
        style={{
          marginBottom: 36,
          display: "inline-block",
          width: "100%",
        }}
      >
        <Typography variant="body2" gutterBottom>
          <strong>{set.name}</strong>
        </Typography>
        {set.permissions.map((editable) => {
          return (
            <div>
              <Checkbox
                style={{ margin: "-8px 0 -8px -14px" }}
                checked={editable.checked}
                onClick={eventHandler(onCheck, editable)}
                icon={
                  <FontAwesomeIcon
                    icon={["fal", "square"]}
                    size="xs"
                    style={{ fontSize: 20 }}
                    color={theme.palette.softGray.main}
                  />
                }
                checkedIcon={
                  <FontAwesomeIcon
                    icon={["fas", "check-square"]}
                    size="xs"
                    style={{ fontSize: 20 }}
                    color={theme.palette.secondary.main}
                  />
                }
              />
              {editable.permission.record.name}
            </div>
          );
        })}
      </div>
    );
  });
};
