import { useState, useEffect } from "react";
import { application } from "../application";

export interface Presenter<TState> {
  state?: TState;
  setState?: Dispatcher<TState>;
  mount(): void;
  unmount(): void;
}

function createPresenter<P extends Presenter<any>, TProps>(
  _provider: PresenterProvider<P> | P,
  props: TProps
): P {
  return _provider instanceof Function ? _provider(application, props) : _provider;
}

export function makePresenter<P extends Presenter<any>, TProps = any>(
  _provider: PresenterProvider<P> | P,
  applyProps?: (presenter: P, props: TProps & OptionalPresenter<P>) => void
): (props: any) => P {
  return (props: any) => {
    const [presenter] = useState(() => createPresenter(_provider, props));
    const presenterState = useState(presenter.state);

    if (applyProps) {
      applyProps(presenter, props);
    }

    presenter.state = presenterState[0];

    useEffect(() => {
      presenter.setState = presenterState[1];
      presenter.mount();
      return presenter.unmount;
    }, [presenter]);

    return presenter;
  };
}

export abstract class BasePresenter<TState extends Record<any, any>> {
  public abstract state: TState;
  setState: Dispatcher<TState> = (state: TState) => {
    this.state = { ...state };
  };
}

export type PresenterState<TRecord> = Record<keyof TRecord | string, any>;

export type Dispatcher<TState> = React.Dispatch<TState>;

export interface OptionalPresenter<P extends Presenter<any>> {
  presenter?: P;
}

export type PresenterProvider<P extends Presenter<any>, TProps = any, TApplication = any> = (
  app: TApplication,
  props: TProps
) => P;
