import gql from "graphql-tag";
import {
  runQuery,
  RunQueryOptions,
} from "../../../../application/network/run-query";
import { ProjectRecord } from "../../../../application/resources/project/project";
import { ProjectFragment } from "../graphql/fragments";

interface QueryProjectArgs {
  id: string;
}

/**
 * Returns a Query
 *
 * @param fragment - Query fragment containing the data you wish to retrieve
 *
 * @returns DocumentNode containing the query string
 */
const buildQuery = (fragment: string = ProjectFragment) => gql`
  query Project($id: ID!) {
    Project(id: $id) {
      ${fragment}
    }
  }
`;

/**
 * Attempts to fetch a project and expects to return a project or error
 *
 * @param options - Options for creating the GraphQL Query.
 *
 * @returns GraphQL response
 */
export const fetchProject = async (
  options: RunQueryOptions<QueryProjectArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<QueryProjectArgs, { Project: ProjectRecord }>({
    query,
    variables: options.variables,
  });
};
