import React from "react";
import { Permission } from "../../../../application/resources/permission/permission";
import { TableProps } from "../../../components/table/table-helpers";
import { usePresenter } from "../../../../hooks/use-presenter";
import { TablePresenter } from "../../../components/table/table-presenter";
import { useEventHandler } from "../../../../hooks/use-eventmapper";
import { useObserver } from "mobx-react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { IconButton } from "../../../components/mui-extended/icon-button";

interface OwnProps extends TableProps<Permission> {}

export const TablePermissions: React.FC<OwnProps> = (props) => {
  const presenter = usePresenter(
    () =>
      new TablePresenter(
        props.items,
        props.onEdit,
        props.onDelete,
        props.onRowClick,
        undefined,
        props.onSelect
      )
  );

  const [eventHandler] = useEventHandler();

  return useObserver(() => {
    const { onEdit } = presenter;

    return (
      <section>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell children={"Name"} />
              <TableCell children={"key"} />
              <TableCell children={"Description"} />
              <TableCell padding={"checkbox"} children={""} />
            </TableRow>
          </TableHead>
          <TableBody>
            {(props.items || []).map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell children={item.record.name} />
                  <TableCell children={item.record.id} />
                  <TableCell children={item.record.description} />{" "}
                  <TableCell
                    padding={"checkbox"}
                    children={
                      <IconButton
                        onClick={eventHandler(onEdit, item.id)}
                        icon="pencil-alt"
                      />
                    }
                  />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </section>
    );
  });
};
