import { makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { PentairTheme } from "../../../../../config/theme/types";
import { typographyVariants } from "../../../../../config/theme/typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface OwnProps {
  onClick: () => void;
}

const useStyles = (props: OwnProps) =>
  makeStyles((theme: PentairTheme) => ({
    root: {
      background: theme.palette.green.main,
      borderRadius: "50%",
      height: theme.spacing(2.5),
      width: theme.spacing(2.5),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "-15px",
      border: "1px solid " + theme.palette.white.main,
      cursor: "pointer",
    },
    initials: {
      color: theme.palette.white.main,
      fontSize: "11px",
    },
  }))(props);

export const SharedUserAddButton: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);

  return (
    <div className={classes.root} onClick={() => props.onClick()}>
      <Typography
        variant={typographyVariants.body2}
        className={classes.initials}
      >
        <strong>
          <FontAwesomeIcon icon={["fal", "plus"]} />
        </strong>
      </Typography>
    </div>
  );
};
