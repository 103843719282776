import { useEffect, useRef, useState } from "react";

type InstanceProvider<TClass> = (
  setState: (state: TClass) => void,
  state: TClass
) => TClass;

export const useClassInstance = <TClass>(
  provider: InstanceProvider<TClass>,
  deps: any[]
) => {
  const [state, setState] = useState<any>(undefined);
  const ref = useRef<TClass | undefined>(undefined);
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) ref.current = provider(setState, state);
    else didMount.current = true;
  }, deps);

  if (!ref.current) {
    ref.current = provider(setState, state);
  }

  return ref.current;
};
