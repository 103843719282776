import { Error404 } from "../../view/pages/errors/error-404";
import { createRoute } from "../../application/helpers/route";
import { PageDashboard } from "../../view/pages/page-dashboard";
import { PageHome } from "../../view/pages/page-home";
import { PageAssistant } from "../../view/pages/assistant/page-assistant";
import { PageUserIndex } from "../../v2/feature/user/view/pages";

export const routesDefault = {
  homepage: createRoute({
    path: "/",
    title: "Xpert",
    component: PageHome,
    public: true,
  }),
  "app.homepage": createRoute({
    path: "/home",
    title: "Xpert Home",
    component: PageDashboard,
    breadcrumbs: [
      {
        route: "app.homepage",
        label: "Home",
      },
    ],
  }),
  "app.xpert-sign-in": createRoute({
    path: "/xpert-aws-login",
    title: "Xpert Home",
    component: PageDashboard,
    breadcrumbs: [
      {
        route: "app.xpert-sign-in",
        label: "Xpert Sign In",
      },
    ],
  }),
  "app.administration": createRoute({
    path: "/administration",
    title: "Manage Xpert",
    component: PageUserIndex,
    breadcrumbs: [
      {
        route: "app.administration",
        label: "Manage",
      },
      {
        route: "app.administration",
        label: "Users",
      },
    ],
  }),
  "app.assistant": createRoute({
    path: "/assistant",
    title: "Xpert assistant",
    component: PageAssistant,
    breadcrumbs: [
      {
        route: "app.assistant",
        label: "Assistant",
      },
    ],
  }),
  "error.404": createRoute({
    path: "*",
    title: "404 - Resource not found",
    component: Error404,
    public: true,
  }),
};
