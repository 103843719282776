import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useObserver } from "mobx-react";
import { abort } from "process";
import * as React from "react";
import { PentairTheme } from "../../../config/theme/types";
import { typographyVariants } from "../../../config/theme/typography";
import { ButtonConfig } from "../../components/guards/route-leaving-guard";
import { PageTitles } from "../../components/page/page-titles";

interface OwnProps {
  open: boolean;
  titleText: string;
  subTitleText: string;
  contentText: React.ReactFragment;
  cancelButtonText?: string;
  confirmButtonText?: string;
  customButtons?: ButtonConfig[];
  onCancel?: () => void;
  onConfirm?: () => void;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {},
  paperpopupWrap: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    left: 0,
    top: 0,
    background: "rgba(0,0,0,.6)",
    backdropFilter: "blur(4px)",
    zIndex: 10,
  },
  clickArea: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
  },
  paperpopup: {
    zIndex: 5,
    width: "65vw",
    maxWidth: "475px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  strong: {
    color: theme.palette.brightBlue.main,
  },
  header: {},
  content: {},
}));

export const WarningDialog: React.FC<OwnProps> = (props) => {
  const {
    open,
    titleText,
    contentText,
    cancelButtonText,
    confirmButtonText,
    subTitleText,
    onCancel,
    onConfirm,
    customButtons,
  } = props;
  const classes = useStyles();

  return useObserver(() => {
    if (!open) {
      return null;
    }
    return (
      <div className={classes.paperpopupWrap}>
        <div className={classes.clickArea} onClick={() => abort()} />
        <Paper className={classes.paperpopup} square>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12}>
              <PageTitles title={titleText} subtitle={subTitleText} />
              <Typography variant={typographyVariants.body2}>
                {contentText}
              </Typography>
              <br />
            </Grid>
            {customButtons &&
              customButtons.map((button) => {
                if (button.buttonDisable) {
                  return (
                    <Grid item xs={12}>
                      <Alert severity="error">
                        You can't SAVE a projection more than once in a minute.
                        Please wait a minute if you want to SAVE.
                      </Alert>
                    </Grid>
                  );
                }

                return null;
              })}
            {customButtons &&
              customButtons.map((button) => {
                return (
                  <Grid item xs={4}>
                    <Button
                      disabled={button.buttonDisable}
                      style={{ width: "100%" }}
                      color={button.buttonType}
                      children={button.buttonText}
                      startIcon={
                        <FontAwesomeIcon icon={["fal", button.buttonIcon]} />
                      }
                      onClick={button.buttonAction}
                    />
                  </Grid>
                );
              })}
            {confirmButtonText && (
              <Grid item xs={4}>
                <Button
                  style={{ width: "100%" }}
                  color="secondary"
                  children={confirmButtonText}
                  startIcon={<FontAwesomeIcon icon={["fal", "trash-alt"]} />}
                  onClick={onConfirm}
                />
              </Grid>
            )}
            {cancelButtonText && (
              <Grid item xs={4}>
                <Button
                  style={{ width: "100%" }}
                  color="default"
                  children={cancelButtonText}
                  startIcon={<FontAwesomeIcon icon={["fal", "times"]} />}
                  onClick={onCancel}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </div>
    );
  });
};
