import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { ProjectRecord } from "../../../../../../application/resources/project/project";
import { typographyVariants } from "../../../../../../config/theme/typography";
import { PageTitles } from "../../../../../../view/components/page/page-titles";
import { MessageType } from "../../../../../../application/business/interactor/message-interactor";

import { FormFieldProjectName } from "../../../../../../view/components/form/fields/project-name";
import { FormFieldAccountName } from "../../../../../../view/components/form/fields/account-name";
import { FormSelectCountry } from "../../../../../../view/components/form/fields/country";
import { SalesForceOpportunityRecord } from "../../../../../../application/resources/salesforce/single";
import { SearchFieldOpportunity } from "../../../../../../view/pages/project/partials/search-opportunity";
import { useProject } from "../../../hooks/use-single";
import { Message } from "../../../../../common/ui/message";

interface OwnProps {
  open: boolean;
  onClose: (project?: Partial<ProjectRecord>) => void;
  adminForm?: boolean;
}

export const ProjectCreateDialog: React.FC<OwnProps> = ({
  open,
  onClose,
  adminForm,
}) => {
  const [saving, setSaving] = React.useState(false);
  const [errors, setErrors] = React.useState<Error[]>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [validationErrors, setValidationErrors] = React.useState<
    Record<string, string>
  >({});
  const project = useProject();

  const onCreate = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (Object.keys(validationErrors).length) {
        return;
      }

      setSaving(true);
      try {
        if (!adminForm) {
          project.change("opportunity", {
            account: {
              id: "some-id",
              name: "TO BE DETERMINED BY KARTHI",
            },
            id: "some-id",
            name: "TO BE DETERMINED BY KARTHI",
            opportunityNumber: "NO_OPPORTUNITY",
          });
        }
        await project.create();
        onClose(project.record);
      } catch (err) {
        setErrors(err.errors);
      }
      setSaving(false);
    },
    [project.record]
  );

  const onChange = React.useCallback(
    (key: keyof ProjectRecord) => (value: any) => {
      project.change(key, value);
    },
    []
  );

  const onFetchComplete = React.useCallback(
    (opportunity?: SalesForceOpportunityRecord) => {
      if (opportunity && opportunity.name.length > 1) {
        project.change("opportunity", opportunity);
        project.change("name", opportunity.name);
      } else {
        project.change("opportunity", {});
        project.change("name", "");
      }
    },
    []
  );

  const onValidationError = React.useCallback(
    (key: string) => (error: string | false) => {
      if (validationErrors[key] || false !== error) {
        if (error) {
          validationErrors[key] = error;
        } else {
          delete validationErrors[key];
        }

        setValidationErrors({ ...validationErrors });
      }
    },
    [validationErrors, setValidationErrors]
  );

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogContent>
        <PageTitles title="create" subtitle="project" />
        <Typography variant={typographyVariants.body2} paragraph>
          Please enter the following details to create a new project
        </Typography>
        {adminForm && (
          <form onSubmit={onCreate}>
            <SearchFieldOpportunity
              value={searchQuery}
              onChange={setSearchQuery}
              onError={onValidationError("opportunity")}
              onFetchComplete={onFetchComplete}
              label={"Opportunity number"}
              description={
                "Please enter the opportunity number to fetch extra data"
              }
              searchOnSubmit
              searchOnEnter
            />

            <FormFieldProjectName
              disabled
              value={project.record.name}
              onChange={onChange("name")}
              onError={onValidationError("name")}
              textfieldProps={{ margin: "normal" }}
            />
            <FormFieldAccountName
              disabled
              value={
                (project.record.opportunity &&
                  project.record.opportunity.account &&
                  project.record.opportunity.account.name) ||
                ""
              }
              onChange={onChange("pentairAccountName")}
              onError={onValidationError("pentairAccountName")}
              textfieldProps={{ margin: "normal" }}
            />
            <FormSelectCountry
              label="Project destination"
              description="Destination country for the project"
              value={project.record.destination}
              onChange={onChange("destination")}
              onError={onValidationError("destination")}
              textfieldProps={{ margin: "normal" }}
            />
          </form>
        )}
        {!adminForm && (
          <form onSubmit={onCreate}>
            <FormFieldProjectName
              value={project.record.name}
              onChange={onChange("name")}
              onError={onValidationError("name")}
              textfieldProps={{ margin: "normal" }}
            />
            <FormSelectCountry
              label="Project destination"
              description="Destination country for the project"
              value={project.record.destination}
              onChange={onChange("destination")}
              onError={onValidationError("destination")}
              textfieldProps={{ margin: "normal" }}
            />
          </form>
        )}

        {(errors || []).map((err) => (
          <Message
            show={true}
            type={MessageType.Error}
            children={err.message}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          children="Create project"
          disabled={
            saving || (Object.keys(validationErrors).length ? true : false)
          }
          startIcon={
            (saving && <FontAwesomeIcon icon={["fal", "spinner"]} spin />) || (
              <FontAwesomeIcon icon={["fal", "check"]} />
            )
          }
          onClick={onCreate}
        />
      </DialogActions>
    </Dialog>
  );
};
