import * as React from "react";
import {
  FormProps,
  FormPresenter,
} from "../../../components/form/form-presenter";
import { PentairTheme } from "../../../../config/theme/types";
import {
  makeStyles,
  Typography,
  Grid,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { useEventHandler } from "../../../../hooks/use-eventmapper";
import { usePresenter } from "../../../../hooks/use-presenter";
import { useObserver } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoLabel from "../../../components/form/info-label";

type FormData = {
  water_source?: string;
  capacity_base?: string;
  "capacity.unit"?: string;
  "capacity.values"?: number;
};

interface OwnProps extends FormProps<FormData> {
  classes?: Record<"titleWrap" | "root" | "icon", string>;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {
    margin: theme.spacing(0, 0, 3, 0),
    border: "none",
  },
  titleWrap: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  icon: {
    width: theme.spacing(2) + "px !important",
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.blue.main,
  },
}));

export const ProjectionFormPlantCapacity: React.FC<OwnProps> = (props) => {
  const [eventHandler, valueGetter] = useEventHandler();
  const classes = useStyles(props);
  const presenter = usePresenter(
    ({ utils }) => new FormPresenter<FormData>(props, utils.validator)
  );

  return useObserver(() => {
    const { data, change, errors } = presenter;

    return (
      <div className={classes.root}>
        <div className={classes.titleWrap}>
          <FontAwesomeIcon className={classes.icon} icon={["fal", "cogs"]} />
          <Typography variant="h4">Capacity</Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              select
              required
              fullWidth
              margin="normal"
              placeholder="Water source"
              id="water_source"
              label={
                <InfoLabel
                  title="Water source"
                  description="The source of water"
                />
              }
              onChange={eventHandler(change, "water_source", valueGetter)}
              value={data.water_source}
              error={errors.water_source ? true : false}
              helperText={errors.water_source}
            >
              <MenuItem value="Waste water">Waste water</MenuItem>
              <MenuItem value="Surface water">Surface water</MenuItem>
              <MenuItem value="Sea water">Sea water</MenuItem>
              <MenuItem value="Sewage water">Sewage water</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              select
              required
              fullWidth
              margin="normal"
              placeholder="Capacity base"
              id="capacity_base"
              label={
                <InfoLabel
                  title="Capacity based on"
                  description="the capacity base"
                />
              }
              onChange={eventHandler(change, "capacity_base", valueGetter)}
              value={data.capacity_base}
              error={errors.capacity_base ? true : false}
              helperText={errors.capacity_base}
            >
              <MenuItem value="Permeate production">
                Permeate production
              </MenuItem>
              <MenuItem value="Waste production">Waste production</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={8}>
            <TextField
              required
              fullWidth
              margin="normal"
              placeholder="Capacity"
              id="capacity_values"
              label={
                <InfoLabel
                  title="Capacity"
                  description="the capacity of the machine"
                />
              }
              onChange={eventHandler(change, "capacity.values", valueGetter)}
              value={data["capacity.values"]}
              error={errors.capacity ? true : false}
              helperText={errors["capacity.values"]}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              select
              required
              fullWidth
              margin="normal"
              label=" "
              placeholder="m³/h"
              id="project"
              onChange={eventHandler(change, "capacity.unit", valueGetter)}
              value={data["capacity.unit"]}
              error={errors.capacity ? true : false}
              helperText={errors["capacity.unit"]}
            >
              <MenuItem value="m³/h">m³/h</MenuItem>
              <MenuItem value="cm³/h">cm³/h</MenuItem>
              <MenuItem value="dm³/24h">dm³/24h</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </div>
    );
  });
};
