import * as Color from "./colors";
import * as TableOverrides from "./overrides/table";
import * as ButtonOverrides from "./overrides/button";
import * as FormOverrides from "./overrides/form";
import { typography } from "./typography";
import { PentairThemeOptions } from "../pentair-theme";

export const pentairThemeOptions: PentairThemeOptions = {
  spacing: 12,
  palette: {
    primary: {
      main: Color.PentairBlue["500"],
    },
    secondary: {
      main: Color.PentairBrightBlue["500"],
    },
    cta: {
      main: Color.PentairGreen["500"],
    },
    white: {
      main: Color.PentairWhite["500"],
    },
    background: {
      default: Color.PentairBackground["500"],
    },
    darkGray: {
      main: Color.PentairDarkGray["500"],
    },
    silverGray: {
      main: Color.PentairSilverGray["500"],
    },
    softGray: {
      main: Color.PentairSoftGray["500"],
    },
    helperTextGray: {
      main: Color.PentairSoftGray["700"],
    },
    darkGold: {
      main: Color.PentairDarkGold["500"],
    },
    error: {
      main: Color.PentairRed["500"],
    },
    impactGreen: {
      main: Color.PentairImpactGreen["500"],
    },
    seafoamGreen: {
      main: Color.PentairSeafoamGreen["500"],
    },
    green: {
      main: Color.PentairGreen["500"],
    },
    brightBlue: {
      main: Color.PentairBrightBlue["500"],
    },
    blue: {
      main: Color.PentairBlue["500"],
    },
    khaki: {
      main: Color.PentairKhaki["500"],
    },
    info: {
      main: Color.PentairLightGray["500"],
    },
  },
  typography: {
    fontFamily: "Barlow",
    allVariants: {},
    body1: {
      ...typography("body1"),
    },
    body2: {
      ...typography("body2"),
    },
    h1: {
      ...typography("h1"),
    },
    h2: {
      ...typography("h2"),
    },
    h3: {
      ...typography("h3"),
    },
    h4: {
      ...typography("h4"),
    },
    h5: {
      ...typography("h5"),
    },
    h6: {
      ...typography("h6"),
    },
    h1_condensed: {
      ...typography("h1_condensed"),
    },
    h2_condensed: {
      ...typography("h2_condensed"),
    },
    h3_condensed: {
      ...typography("h3_condensed"),
    },
    h4_condensed: {
      ...typography("h4_condensed"),
    },
    h5_condensed: {
      ...typography("h5_condensed"),
    },
    h6_condensed: {
      ...typography("h6_condensed"),
    },
    button: { ...typography("button") },
  },
  shape: {
    borderRadius: 0,
  },
  props: {
    MuiCard: {
      elevation: 0,
      style: { borderRadius: 0 },
    },
    MuiTypography: {
      variant: "body2",
    },
    MuiButton: {
      variant: "contained",
      color: "primary",
    },
    MuiExpansionPanel: {
      elevation: 0,
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiInputBase: {
      fullWidth: true,
    },
  },
  overrides: {
    ...TableOverrides,
    ...ButtonOverrides,
    ...FormOverrides,
    MuiBackdrop: {
      root: {
        background: "rgba(0,0,0,.6)",
        backdropFilter: "blur(4px)",
      },
    },
    MuiDialogActions: {
      root: {
        padding: "12px 24px 24px 24px",
      },
    },
  },
};
