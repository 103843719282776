import { Grid, makeStyles, TextField } from "@material-ui/core";
import { useObserver } from "mobx-react";
import * as React from "react";
import { PentairTheme } from "../../../../config/theme/types";
import { useEventHandler } from "../../../../hooks/use-eventmapper";
import { usePresenter } from "../../../../hooks/use-presenter";
import { SelectCountry } from "../../../components/form/fields/select-country";
import { SelectProfile } from "../../../components/form/fields/select-profile";
import {
  FormPresenter,
  FormProps,
} from "../../../components/form/form-presenter";
import InfoLabel from "../../../components/form/info-label";

type FormData = {
  email?: string;
  family_name?: string;
  given_name?: string;
  country?: string;
  profile?: any;
  accountOwner?: string;
  accountName?: string;
};

interface OwnProps extends FormProps<FormData> {
  classes?: Record<"titleWrap" | "root" | "icon", string>;
  isPentairUser: boolean;
  canEdit: boolean;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {
    margin: theme.spacing(0, 0, 3, 0),
    border: "none",
    width: "700px",
    maxWidth: "90vw",
  },
  titleWrap: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  icon: {
    width: theme.spacing(2) + "px !important",
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.blue.main,
  },
}));

export const UserFormInputs: React.FC<OwnProps> = (props) => {
  const [eventHandler, valueGetter] = useEventHandler();
  const classes = useStyles(props);
  const presenter = usePresenter(
    ({ utils }) => new FormPresenter<FormData>(props, utils.validator)
  );

  return useObserver(() => {
    const { data, change, errors } = presenter;
    const { isPentairUser, canEdit } = props;

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              disabled={canEdit ? false : true}
              margin="normal"
              placeholder="John"
              id="given_name"
              label={<InfoLabel title="First name" description="first name" />}
              onChange={eventHandler(change, "given_name", valueGetter)}
              value={data.given_name}
              error={errors.given_name ? true : false}
              helperText={errors.given_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              fullWidth
              disabled={canEdit ? false : true}
              margin="normal"
              placeholder="Doe"
              id="family_name"
              label={<InfoLabel title="Last name" description="Last name" />}
              onChange={eventHandler(change, "family_name", valueGetter)}
              value={data.family_name}
              error={errors.family_name ? true : false}
              helperText={errors.family_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              disabled={canEdit ? false : true}
              required
              fullWidth
              margin="normal"
              placeholder="email@xpert.com"
              id="email"
              label={
                <InfoLabel
                  title="E-mail address"
                  description="Registered e-mail address"
                />
              }
              onChange={eventHandler(change, "email", valueGetter)}
              value={data.email}
              error={errors.email ? true : false}
              helperText={errors.email}
            />
          </Grid>
          {!isPentairUser && (
            <Grid item xs={12} md={6}>
              <SelectCountry
                value={data.country}
                required
                fullWidth
                disabled
                margin="normal"
                placeholder=""
                id={"country"}
                label={"Mailing country"}
                error={errors.country ? true : false}
                helperText={errors.country}
                onChange={eventHandler(change, "country", valueGetter)}
              />
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <SelectProfile
              value={data.profile && data.profile.id}
              required
              fullWidth
              disabled
              margin="normal"
              placeholder=""
              id={"profile"}
              label="Xpert User Profile"
              error={errors.profile ? true : false}
              helperText={errors.profile}
              onChange={eventHandler(change, "profile", valueGetter)}
            />
          </Grid>
          {!isPentairUser && (
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                disabled={canEdit ? false : true}
                margin="normal"
                placeholder="Doe"
                id="accountName"
                label={
                  <InfoLabel
                    title="Pentair account name"
                    description="Account name for pentair"
                  />
                }
                onChange={eventHandler(change, "accountName", valueGetter)}
                value={data.accountName}
                error={errors.accountName ? true : false}
                helperText={errors.accountName}
              />
            </Grid>
          )}
          {!isPentairUser && (
            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                disabled={canEdit ? false : true}
                margin="normal"
                placeholder="Your account manager"
                id="accountOwner"
                label={
                  <InfoLabel
                    title="Pentair account manager"
                    description="Pentair account manager"
                  />
                }
                onChange={eventHandler(change, "accountOwner", valueGetter)}
                value={data.accountOwner}
                error={errors.accountOwner ? true : false}
                helperText={errors.accountOwner}
              />
            </Grid>
          )}
        </Grid>
      </div>
    );
  });
};
