import * as React from "react";
import { makeStyles } from "@material-ui/core";
import { InterfaceTools } from "../default-interface-presenter";

import { useObserver } from "mobx-react";
import { PentairBlue } from "../../../config/theme/colors";
import { IconButton } from "../../components/mui-extended/icon-button";
import { useRoute } from "../../../hooks/use-route";
import { useEventHandler } from "../../../hooks/use-eventmapper";
import { useLocation } from "react-router";
import { ToolbarPresenter } from "./toolbar-presenter";
import { usePresenter } from "../../../hooks/use-presenter";
import { Link } from "react-router-dom";
import { CanView } from "../../auth/can-view";

interface OwnProps {
  onToolSelect?(tool: InterfaceTools): void;
  active: InterfaceTools;
  classes?: Record<"root", string>;
}

const useStyles = makeStyles({
  root: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    background: PentairBlue[500],
    "& > nav": {
      display: "flex",
      flexDirection: "column",
    },
  },
  userIconWrap: {
    padding: "8px",
    height: 48,
    width: 48,
    textDecoration: "none",
    display: "flex",
  },
  userIcon: {
    width: "100%",
    background: PentairBlue[700],
    borderRadius: "50%",
    color: "#fff",
    fontWeight: 500,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    fontSize: "11px",
    lineHeight: "10px",
  },
});

export const ToolBar: React.FC<OwnProps> = (props: OwnProps) => {
  const { onToolSelect, active } = props;
  const presenter = usePresenter(
    ({ interactor }) => new ToolbarPresenter(interactor.user)
  );
  const classes = useStyles(props);
  const [eventHandler] = useEventHandler();

  const location = useLocation();
  const [homepageUrl] = useRoute("app.homepage");
  const [accountUrl] = useRoute("user.account");
  const [administrationUrl] = useRoute("user.index");

  return useObserver(() => (
    <aside className={classes.root}>
      <nav>
        {homepageUrl !== location.pathname && (
          <IconButton variant="primary" icon="home" to={homepageUrl} />
        )}

        <IconButton
          variant="primary"
          active={active === InterfaceTools.Notifications}
          icon="bell"
          onClick={eventHandler(onToolSelect, InterfaceTools.Notifications)}
        />
      </nav>
      <nav>
        <Link className={classes.userIconWrap} to={accountUrl}>
          <div className={classes.userIcon}>{presenter.userInitials}</div>
        </Link>
        <CanView permission={"ui.toolbar.settings_icon"}>
          <IconButton variant="primary" icon="cog" to={administrationUrl} />
        </CanView>

        <IconButton
          variant="primary"
          icon="expand-wide"
          onClick={() => {
            presenter.toggleFullscreen();
          }}
        />
        <IconButton
          variant="primary"
          icon="sign-out"
          onClick={eventHandler(presenter.signout)}
        />
      </nav>
    </aside>
  ));
};
