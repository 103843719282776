import * as React from "react";
import { makeStyles, Card } from "@material-ui/core";
import { useHistory } from "react-router";
import { ButtonBack } from "../../../v2/common/ui/button-back";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

interface OwnProps {
  title?: string;
  classes?: Record<"root", string>;
}

export const PageHeader: React.FC<OwnProps> = (props) => {
  const classes = useStyles({ props });
  const history = useHistory();

  return (
    <Card className={classes.root}>
      {history.length > 0 && <ButtonBack />} <div>{props.children}</div>
    </Card>
  );
};
