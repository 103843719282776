import { Presenter } from "../../../hooks/use-presenter";
import {
  Projection,
  ProjectionRevision,
} from "../../../application/resources/projection/projection";
import { observable, computed } from "mobx";
import { ProjectionProvider } from "../../../application/resources/projection/projection-provider";
import { TechnologyInteractor } from "../../../application/resources/technology/technology-interactor";
import moment, { Moment } from "moment";

interface ComponentProps {
  params: {
    projectionid: string;
    tab?: string;
    version?: string;
  };
  url: string;
}

export class ProjectionPresenter implements Presenter {
  protected _projectionId: string;
  protected _projection: Projection;
  @observable public projectionVersion: string | undefined;
  @observable public activeTab: string | undefined;
  @observable public revisionNote: string = "";
  @observable public savingPopup: boolean = false;
  @observable public hasChanges: boolean = false;
  protected _projectionRevisions: ProjectionRevision[];
  public _props: ComponentProps;
  @observable public loading = false;

  constructor(
    protected _redirect: any,
    protected _projectionProvider: ProjectionProvider,
    protected _technologyInteractor: TechnologyInteractor
  ) {}

  public mount = async (version?: string, tab?: string) => {
    this.loading = true;
    this.savingPopup = false;

    await this.initialSetup(version, tab);

    this._projectionRevisions = await this._projectionProvider.getRevisions(
      this._projectionId
    );
    if ((!this._projection.isNew && !this._projection.fetched) || version) {
      if (this.projectionVersion === "latest") {
        await this._projection.fetch();
      } else {
        await this._projection.fetch(this.projectionVersion);
      }
    }

    this.loading = false;

    if (!this._projection.record.project?.id) {
      this._projection.record.project = {
        id: "unassigned-projections",
        name: "Unassigned projections",
      };
    }
  };

  public initialSetup = async (version?: string, tab?: string) => {
    this._projectionId = this._props.params.projectionid;
    if (!tab) {
      this.activeTab = this._props.params.tab;
    } else {
      this.activeTab = tab;
    }

    if (!version) {
      this.projectionVersion = this._props.params.version;
    } else {
      this.projectionVersion = version;
    }

    if (!this.projectionVersion) {
      this.projectionVersion = "latest";
    }

    if (this._projectionId) {
      this._projection = this._projectionProvider.get(
        this._projectionId,
        this.projectionVersion
      );
    } else {
      this._projection = this._projectionProvider.create();
    }

    if (!this.activeTab) {
      this.activeTab = "inputs";
    }
  };

  public togglePopup = () => {
    this.savingPopup = this.savingPopup ? false : true;
  };

  public setActiveTab = (tab: string) => {
    let route = "projection.single.tab";
    let params: { [k: string]: any } = {
      projectionid: this.projection.id,
      projectid: this.projection.record.project.id,
      tab: tab,
    };

    if (this.projectionVersion && this.projectionVersion !== "latest") {
      route = "projection.single.tab.version";

      params.version = this.projectionVersion;
    }

    this._redirect(route, params);

    this.activeTab = tab;
  };

  public setVersion = (versionId: any) => {
    const route = "projection.single.tab.version";

    if (versionId === "Latest") {
      versionId.toLowercase();
    }

    this.mount(versionId, "inputs").then(() => {
      this._redirect(route, {
        projectionid: this.projection.id,
        projectid: this.projection.record.project.id,
        tab: "inputs",
        version: versionId,
      });
    });
  };

  public unmount = () => {};

  public get projection() {
    return this._projection;
  }

  public get projectionRevisions() {
    return this._projectionRevisions.length && this._projectionRevisions;
  }

  public get lastModified() {
    let lastModified: Moment;

    try {
      let version = this._projectionRevisions.find(
        (version) => version.isLatest === true
      );

      if (this.projectionVersion !== "latest") {
        version = this._projectionRevisions.find(
          (version) => version.id === this.projectionVersion
        );
      }

      lastModified = moment(version && version.lastModified);
    } catch {
      lastModified = moment().subtract("2", "minutes");
    }

    return lastModified;
  }

  public get revision() {
    let revision: string;

    try {
      let version = this._projectionRevisions.find(
        (version) => version.isLatest === true
      );

      if (this.projectionVersion !== "latest") {
        version = this._projectionRevisions.find(
          (version) => version.id === this.projectionVersion
        );
      }

      revision = moment(version && version.lastModified).format("YYMMDD.HHmm");
    } catch {
      revision = "Unavailable.";
    }

    return revision;
  }

  public save = () => {
    this.hasChanges = false;
    this.projection.save().then(() => {
      this.mount("latest", "inputs").then(() => {
        this.savingPopup = false;
        this._redirect("projection.single.tab.version", {
          projectionid: this.projection.id,
          tab: "inputs",
          version: "latest",
        });
      });
    });
  };

  @computed public get isNew() {
    return this._projection.isNew;
  }

  @computed public get formDataBase() {
    if (this.projection && this.projection.record) {
      return {
        project: this.projection.record.project.name,
        name: this.projection.record.name,
        country: this.projection.record.country,
        status: this.projection.record.status,
      };
    }

    return {};
  }

  public formDataProjectionValues = (ids: string[]) => {
    let values = {};

    ids.forEach((id) => {
      if (id.includes(".")) {
        const separators = id.split(".");
        const projectionValue = this.projection.valueMap["capacity"];

        values[id] =
          separators && projectionValue && projectionValue[separators[1]];
      } else {
        values[id] =
          this.projection.valueMap &&
          this.projection.valueMap[id] &&
          this.projection.valueMap[id].values[0];
      }
    });

    return values;
  };

  public onChange = (key: any, value: string) => {
    this.hasChanges = true;
    this.projection.record[key] = value;
  };
}
