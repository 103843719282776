import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, makeStyles } from "@material-ui/core";
import { PentairTheme } from "../../../config/theme/types";

interface OwnProps {
  title: React.ReactNode;
  description?: React.ReactNode;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontSize: 20,
  },
}));

export default function InfoLabel({ title, description }: OwnProps) {
  const classes = useStyles();

  if (!description) {
    return <React.Fragment>title</React.Fragment>;
  }

  return (
    <Tooltip title={description}>
      <span>
        <FontAwesomeIcon icon="info-circle" className={classes.icon} />
        {title}
      </span>
    </Tooltip>
  );
}
