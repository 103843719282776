import gql from "graphql-tag";
import {
  runQuery,
  RunQueryOptions,
} from "../../../../application/network/run-query";
import { UserFragment } from "../../../../application/resources/user/graphql/fragments";
import { UserRecord } from "../../../../application/resources/user/user";

interface QueryUserArgs {
  id: string;
}

const buildQuery = (fragment: string = UserFragment) => gql`
  query User($id: ID!) {
    User(id: $id) {
      ${fragment}
    }
  }
`;

export const fetchUser = async (options: RunQueryOptions<QueryUserArgs>) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<QueryUserArgs, { User: UserRecord }>({
    query,
    variables: options.variables,
  });
};
