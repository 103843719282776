import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputAdornment, TextField } from "@material-ui/core";
import * as React from "react";
import { theme } from "../../../../../../config/theme/pentair";
import InfoLabel from "../../../../../../view/components/form/info-label";

interface OwnProps {
  passedValue?: string;
  label?: string;
  description?: string;
  onError?: (error: string | false) => void;
  onChange?: (value: string, e: any) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  loading?: boolean;
  required?: boolean;
  showDelete?: boolean;
  searchOnSubmit?: boolean;
  searchOnEnter?: boolean;
}

export const SearchField: React.FC<OwnProps> = ({
  onChange,
  passedValue,
  label,
  description,
  helperText,
  error,
  loading,
  required,
  showDelete,
  searchOnSubmit,
  searchOnEnter,
}) => {
  const [timer, setTimer] = React.useState<any>();
  const [currentValue, setCurrentValue] = React.useState(passedValue);

  return (
    <TextField
      required={required}
      error={error}
      helperText={helperText}
      fullWidth
      margin="normal"
      placeholder="Search"
      value={currentValue}
      onKeyPress={
        searchOnEnter
          ? (e) => {
              console.log("Search on enter");
              if (e.key === "Enter") {
                if (onChange) {
                  onChange(currentValue || "", e);
                }
              }
            }
          : () => {}
      }
      label={
        label ? <InfoLabel title={label} description={description} /> : false
      }
      InputProps={{
        endAdornment: showDelete ? (
          <a
            onClick={(e) => {
              setCurrentValue("");
              if (onChange) {
                onChange("", e);
              }
            }}
            style={{
              cursor: "pointer",
              padding: "9px",
              paddingRight: 0,
              color: theme.palette.error.main,
              fontSize: "16px",
              zIndex: 20,
            }}
          >
            <FontAwesomeIcon icon={["fal", "times"]} />
          </a>
        ) : (
          <InputAdornment position="end">
            {(loading && (
              <FontAwesomeIcon icon={["fal", "spinner"]} spin />
            )) || (
              <a
                style={{ cursor: "pointer", padding: "9px", paddingRight: 0 }}
                onClick={(e) => {
                  if (onChange) {
                    onChange(currentValue || "", e);
                  }
                }}
              >
                <FontAwesomeIcon icon={["fal", "search"]} />
              </a>
            )}
          </InputAdornment>
        ),
      }}
      onChange={
        searchOnSubmit
          ? (e) => {
              setCurrentValue(e.target.value);
            }
          : (e) => {
              const value = e.target.value;
              setCurrentValue(e.target.value);
              clearTimeout(timer);
              setTimer(
                setTimeout(() => {
                  if (onChange) {
                    onChange(value, e);
                  }
                }, 500)
              );
            }
      }
      id="searchString"
    />
  );
};
