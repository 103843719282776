import React from "react";
import { Typography, makeStyles, Theme } from "@material-ui/core";

interface OwnProps {
  title: string;
  icon?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1.5, 2, 0.5, 2),
    marginBottom: theme.spacing(-2),
    background: "#fff",
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: 15,
    marginRight: theme.spacing(1),
    height: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: 48,
    height: 48,
    marginRight: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 22,
    color: theme.palette.primary.main,
  },
}));

export default function WidgetHeader({ title, icon }: OwnProps) {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      {icon && <div className={classes.icon}>{icon}</div>}
      <Typography variant={"h5"} className={classes.title}>
        {title}
      </Typography>
    </section>
  );
}
