import { Presenter } from "../../../hooks/use-presenter";
import { UserInteractor } from "../../../application/resources/user/user-interactor";
import { observable } from "mobx";

export class ToolbarPresenter implements Presenter {
  constructor(protected _userInteractor: UserInteractor) {}
  @observable public user = this._userInteractor.currentUser;
  @observable public fullscreen = false;
  public mount = async () => {
    await this.user.fetch();
  };
  public unmount = () => {};

  public signout = () => {
    this._userInteractor.logout();
  };

  public toggleFullscreen = () => {
    const el: any = document.documentElement;
    if (!this.fullscreen) {
      this.fullscreen = true;
      el.requestFullscreen();
    } else {
      this.fullscreen = false;
      document.exitFullscreen();
    }
  };

  public get userInitials() {
    const user = this._userInteractor.currentUser;
    let firstInitial: string = "";
    let lastInitial = user.record.attributes.family_name?.charAt(0);
    if (user.record.attributes.given_name) {
      firstInitial = user.record.attributes.given_name.charAt(0);
    } else if (user.record.attributes.name) {
      firstInitial = user.record.attributes.name.charAt(0);
    }

    return firstInitial + lastInitial;
  }
}
