import {
  StyleProjectionSingle,
  useProjectionSingleStyles,
} from "../../projection/styles-projection-single";
import * as React from "react";
import { ProjectPresenter } from "./single-presenter";
import { useRedirect } from "../../../../hooks/use-redirect";
import { makePresenter } from "../../../../application/helpers/make-presenter";
import { useObserver } from "mobx-react";
import { PageLoading } from "../../page-loading";
import { Page } from "../../../components/page/page-wrapper";
import { PageContent } from "../../../components/page/page-content";
import { ProjectHeader } from "./partials/single-header";
import { TableProjections } from "../partials/projection-table";
import { theme } from "../../../../config/theme/pentair";
import { Typography } from "@material-ui/core";
import { typographyVariants } from "../../../../config/theme/typography";
import { ProjectionClonePaper } from "../../../components/projection/projection-clone-paper";
import {
  ProjectionListBySolution,
  ProjectionRecord,
} from "../../../../application/resources/projection/projection";
import moment from "moment";
import { useProject } from "../../../../v2/feature/project/hooks/use-single";
import { useProjectionCollection } from "../../../../application/resources/projection/v2/hooks/use-collection";

interface OwnProps extends StyleProjectionSingle {}

const usePresenter = makePresenter(
  ({ provider, interactor }) =>
    new ProjectPresenter(
      useRedirect(),
      provider.project,
      provider.projection,
      interactor.user
    ),
  (presenter, props) => {
    presenter._props = props.match;
  }
);

export const PageProjectSingle: React.FC<OwnProps> = (props: any) => {
  const classes = useProjectionSingleStyles(props);
  const presenter = usePresenter(props);
  const redirect = useRedirect();
  const project = useProject(props.match.params.projectid);
  const projectionCollection = useProjectionCollection();

  const [loading, setLoading] = React.useState(false);
  const [projectionsBySolution, setProjectionsBySolutions] = React.useState<
    ProjectionListBySolution[]
  >([]);

  const mapProjectionsBySolution = () => {
    let technologies: string[] = [];
    console.log(projectionCollection);

    projectionCollection.records.forEach(
      (projection: Partial<ProjectionRecord>) => {
        if (
          projection.solution &&
          projection.solution.id &&
          !technologies.includes(projection.solution.id)
        ) {
          technologies.push(projection.solution.id);
        }
      }
    );

    let projectionsBySolution: ProjectionListBySolution[] = [];

    technologies.forEach((solution) => {
      const filteredProjections = projectionCollection.records.filter(
        (projection) =>
          projection.solution && projection.solution.id === solution
      );

      filteredProjections.forEach((projection) => {
        let revision: string;

        try {
          const latestRevision = (projection.versions || []).find(
            (version) => version.isLatest === true
          );
          revision = moment(
            latestRevision && latestRevision.lastModified
          ).format("YYMMDD.HHmm");
        } catch {
          revision = "Unavailable.";
        }

        projection.version = revision;
      });

      projectionsBySolution.push({
        id: solution,
        name:
          (filteredProjections[0].solution &&
            (filteredProjections[0].solution.name as string)) ||
          "",
        items: filteredProjections,
      });
    });

    setProjectionsBySolutions(projectionsBySolution);
  };

  React.useEffect(() => {
    setLoading(true);
    console.log(props.match.params.projectid);
    project.fetch().then(() => {
      projectionCollection
        .fetch({
          projectId: props.match.params.projectid,
        })
        .then(() => {
          console.log(projectionCollection);
          mapProjectionsBySolution();
          setLoading(false);
        });
    });
  }, [project, projectionCollection]);

  return useObserver(() => {
    const {
      openCloneForm,
      cloning,
      onClone,
      clonePopup,
      togglePopup,
      cloneProjectOnChange,
      currentUser,
    } = presenter;

    if (!project || project.record.owner === undefined) {
      return null;
    }

    if (loading) {
      return <PageLoading message="Fetching project" />;
    }

    return (
      <Page variant="fullscreen">
        <PageContent variant="fullscreen">
          <div className={classes.header}>
            <ProjectHeader project={project} user={currentUser.record} />
          </div>
          <div
            className={classes.sectionWrap}
            style={{ paddingTop: theme.spacing(2) }}
          >
            {projectionCollection.records.length ? (
              <TableProjections
                onEdit={(projectionid) =>
                  redirect("projection.single.tab", {
                    projectid: project.id || "",
                    projectionid: projectionid,
                    tab: "inputs",
                  })
                }
                onClone={openCloneForm}
                items={projectionsBySolution}
              />
            ) : (
              <Typography
                variant={typographyVariants.h4}
                style={{
                  color: theme.palette.blue.main,
                }}
              >
                This project does not contain any projections yet.
              </Typography>
            )}
          </div>
          <ProjectionClonePaper
            onChange={cloneProjectOnChange}
            data={{
              case: presenter.cloneString,
            }}
            projectId={props.match.params.projectid}
            abort={togglePopup}
            active={clonePopup}
            saving={cloning}
            onSubmit={onClone}
            validations={{
              case: "required",
            }}
          />
        </PageContent>
      </Page>
    );
  });
};
