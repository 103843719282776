export const ProjectFragment = `
    id
    owner {
        id
        attributes {
            given_name
            family_name
        }
    }
    name
    destination {
        id
        name
    }
    updatedAt 
    createdAt
    updatedBy {
        id
        attributes {
            given_name
            family_name
        }
    }
    opportunity {
        opportunityNumber
        id
        name
        account { 
            id
            name
        }
    }
`;

export const ProjectIndexFragment = `
    id
    owner {
        id
        attributes {
            given_name
            family_name
        }
    }
    name
    destination {
        id
        name
    }
    updatedAt 
    createdAt
    updatedBy {
        id
        attributes {
            given_name
            family_name
        }
    }
    opportunity {
        opportunityNumber
        account { 
            name
        }
    }
`;
