import { useObserver } from "mobx-react";
import * as React from "react";
import { makePresenter } from "../../../application/helpers/make-presenter";
import { useRedirect } from "../../../hooks/use-redirect";
import { PageContent } from "../../components/page/page-content";
import { Page } from "../../components/page/page-wrapper";
import { PageLoading } from "../page-loading";
import { UserHeader } from "./partials/user-header";
import { UserFormInputs } from "./partials/user-information-form";
import { UserFormPreferences } from "./partials/user-preferences-form";
import { StyleUserSingle, useUserSingleStyles } from "./styles-user-single";
import { UserPresenter } from "./user-presenter";
import { TabsUserSingle } from "./user-single-tabs";

interface OwnProps extends StyleUserSingle {}

const usePresenter = makePresenter(
  ({ provider, interactor }) =>
    new UserPresenter(
      useRedirect(),
      provider.user,
      interactor.user,
      provider.userProfile,
      provider.profile
    ),
  (presenter, props) => {
    presenter._props = props.match;
  }
);

export const PageUserAccount: React.FC<OwnProps> = (props) => {
  const classes = useUserSingleStyles(props);
  const presenter = usePresenter(props);

  return useObserver(() => {
    const {
      loading,
      user,
      activeTab,
      setActiveTab,
      onChange,
      formData,
      preferencesData,
      isPentairUser,
      canEdit,
    } = presenter;

    if (!user) {
      return null;
    }

    if (loading) {
      return <PageLoading message="Fetching user" />;
    }

    return (
      <Page variant="fullscreen">
        <PageContent variant="fullscreen">
          <div className={classes.header}>
            <UserHeader
              user={user}
              onSave={() => {}}
              activeTab={activeTab || "information"}
              presenter={presenter}
            />
          </div>
          <div className={classes.sectionBar}>
            <TabsUserSingle activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
          <div className={classes.sectionWrap}>
            {activeTab === "information" && (
              <UserFormInputs
                data={formData}
                onChange={onChange}
                isPentairUser={isPentairUser}
                canEdit={canEdit}
              />
            )}
            {activeTab === "preferences" && (
              <UserFormPreferences data={preferencesData} onChange={onChange} />
            )}
          </div>
        </PageContent>
      </Page>
    );
  });
};
