import { TechnologyFragment } from "./fragments";

export const QueryAllTechnologies = `
  query SolutionCollection {
    SolutionCollection {
      solutions {
        ${TechnologyFragment}
      }
  }
  }
`;

export const QueryTechnology = `
  query Technology($id:ID!) {
    Technology(id:$id){
    ${TechnologyFragment}
  }
  }
`;

export const QueryTechnologyOwner = `
  query Technology($id:ID!) {
    Technology(id:$id){
    owner{
      id
      username
      email
    }
  }
  }
`;

export const QueryTechnologyApprover = `
  query Technology($id:ID!) {
    Technology(id:$id){
    approver{
      id
      username
      email
    }
  }
  }
`;

export const QueryTechnologyUsers = `
  query TechnologyUsers($id:ID!) {
    Technology(id:$id){
    users{
      id
      username
      email
    }
  }
  }
`;

export const QueryTechnologyNotifications = `
query TechnologyNotification($id:ID!) {
  Technology(id:$id){
    notifications {
      id
      name
      description
      acknowledged
      annotations {
        id
        description
        user {
          id
          username
        }
      }
    }
  }
}
`;
