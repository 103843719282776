import { IconName } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, Typography } from "@material-ui/core";
import { PentairTheme } from "../../../config/theme/types";
import * as React from "react";

interface OwnProps {
  icon: IconName;
  title: string;
  classes?: Record<"titleWrap" | "icon", string>;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  titleWrap: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  icon: {
    width: theme.spacing(2) + "px !important",
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.blue.main,
  },
}));

export const SectionTitle: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const { title, icon } = props;

  return (
    <div className={classes.titleWrap}>
      <FontAwesomeIcon className={classes.icon} icon={["fal", icon]} />
      <Typography variant="h4">{title}</Typography>
    </div>
  );
};
