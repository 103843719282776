import { Grid } from "@material-ui/core";
import * as React from "react";
import { useRedirect } from "../../../../../hooks/use-redirect";
import { CollectionTable } from "../../../../common/table/table-collection";
import {
  columnProjectCountry,
  columnEdit,
  columnUpdatedBy,
  columnValue,
  columnNestedValue,
} from "../../../../common/table/table-columns";
import LoadingIcon from "../../../../common/ui/loading-icon";
import { SectionTitle } from "../../../../common/ui/section-title";
import { useProjectCollection } from "../../hooks/use-collection";
import { SearchField } from "../pages/partials/search-field";

interface OwnProps {
  activeUser: string;
}

export const ProjectIndexTable: React.FC<OwnProps> = (props) => {
  const collection = useProjectCollection();
  const redirect = useRedirect();
  const onEdit = React.useCallback((val, item) => {
    redirect("project.single", { projectid: item.id });
  }, []);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true);
    collection
      .fetch({
        filters: [
          { operator: "=", property: "owner.id", value: props.activeUser },
        ],
      })
      .then(() => {
        console.log(collection);
        setLoading(false);
      });
  }, [collection, searchQuery]);

  return (
    <section>
      <Grid
        container
        spacing={2}
        justify={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={6}>
          <SectionTitle title="Projects created by me" icon="table" />
        </Grid>
        <Grid item xs={2}>
          <SearchField passedValue={searchQuery} onChange={setSearchQuery} />
        </Grid>
        <Grid item xs={12}>
          {(loading && <LoadingIcon />) || (
            <CollectionTable
              sortKey={"updatedAt"}
              sortDirection={"desc"}
              items={collection.records}
              columns={{
                opportunityy: columnNestedValue(
                  "Opportunity nr.",
                  "opportunity.opportunityNumber"
                ),
                name: columnValue("Project Name"),
                solutions: columnValue("Solutions"),
                destination: columnProjectCountry("Destination"),
                updatedBy: columnUpdatedBy("Last modified"),
                edit: columnEdit(onEdit),
              }}
            />
          )}
        </Grid>
      </Grid>
    </section>
  );
};
