import * as React from "react";
import { Typography, Button, Grid } from "@material-ui/core";
import { StyleDefaultPages } from "../errors/styles-error-pages";
import { PageUserSinglePresenter } from "./page-user-single-presenter";
import { useRouteMatch, useHistory } from "react-router";
import { usePresenter } from "../../../hooks/use-presenter";
import { useObserver } from "mobx-react";
import { PageHeader } from "../../components/page/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageTitles } from "../../components/page/page-titles";
import { PageLoading } from "../page-loading";
import { UserFormDetails } from "./partials/user-form-info";
import { Widget } from "../../components/widget/widget";
import { PageContent } from "../../components/page/page-content";
import { Page } from "../../components/page/page-wrapper";

interface OwnProps extends StyleDefaultPages {}

export const PageUserSingle: React.FC<OwnProps> = (props) => {
  const match = useRouteMatch<{ userid: string }>();
  const history = useHistory();

  const presenter = usePresenter(
    ({ provider, interactor }) =>
      new PageUserSinglePresenter(
        { routeParams: match.params },
        history.goBack,
        provider.user,
        provider.userProfile,
        provider.profile,
        interactor.user
      )
  );

  return useObserver(() => {
    const {
      user,
      isSaving,
      onSave,
      isNew,
      loading,
      onChange,
      formData,
      currentUser,
    } = presenter;

    if (loading) {
      return <PageLoading message="fetching user" />;
    }

    return (
      <Page>
        <PageContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PageHeader>
                <Button
                  onClick={onSave}
                  variant="contained"
                  color="primary"
                  startIcon={
                    isSaving ? (
                      <FontAwesomeIcon icon={["fal", "spinner"]} spin />
                    ) : (
                      <FontAwesomeIcon icon={["fal", "check"]} />
                    )
                  }
                >
                  {isNew ? "Create user" : "Apply changes"}
                </Button>
              </PageHeader>
            </Grid>

            <Grid item xs={12} md style={{ maxWidth: 320 }}>
              <Widget>
                <PageTitles
                  title={
                    isNew
                      ? "Create"
                      : user.record.attributes.given_name ||
                        user.record.attributes.email
                  }
                  subtitle={isNew ? "User" : "Details"}
                />
                <Typography paragraph variant="body2">
                  The user profile contains all the information required to
                  authorize a user to CRU(D) records or access components.
                </Typography>
                <Typography paragraph variant="body2">
                  Here you can create user profiles that can be assigned to
                  users.
                </Typography>
              </Widget>
            </Grid>
            <Grid item xs={12} md>
              <UserFormDetails
                currentUser={currentUser}
                data={formData}
                onChange={onChange}
                validations={{
                  email: "required|email",
                  given_name: "required",
                  family_name: "required",
                  profile: "required",
                }}
              />
            </Grid>
          </Grid>
        </PageContent>
      </Page>
    );
  });
};
