import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import * as React from "react";
import { UserShareSummary } from "../../../../../application/resources/project/project";
import { IconButton } from "../../../../components/mui-extended/icon-button";

interface OwnProps {
  users: UserShareSummary[];
  onDelete: (userId: string) => void;
  disabledDelete: boolean;
}

export const ProjectUsersTable: React.FC<OwnProps> = (props) => {
  const { users, onDelete, disabledDelete } = props;

  return (
    <section>
      <Grid
        container
        spacing={2}
        justify={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell children={"Name"} />
                <TableCell children={"Email"} />
                <TableCell children={"Permissions"} />
                <TableCell padding={"checkbox"} children={""} />
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                let permissions;

                if (user.permissions.includes("write")) {
                  permissions = "Read / Write";
                } else if (user.permissions.includes("read")) {
                  permissions = "Read only";
                } else {
                  permissions = "None";
                }

                return (
                  <TableRow>
                    <TableCell>
                      {user.attributes.given_name.charAt(0) +
                        "." +
                        user.attributes.family_name}
                    </TableCell>
                    <TableCell>{user.attributes.email}</TableCell>
                    <TableCell>{permissions}</TableCell>
                    <TableCell
                      style={{ borderBottom: "1px solid #eee" }}
                      padding={"checkbox"}
                      children={
                        <IconButton
                          disabled={disabledDelete}
                          onClick={() => onDelete(user.id)}
                          icon="trash-alt"
                        />
                      }
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </section>
  );
};
