import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { useObserver } from "mobx-react";
import * as React from "react";
import { ValuesTableForm } from "../../../components/table/table-values-form";
import { Widget } from "../../../components/widget/widget";
import { ProjectionPresenter } from "../projection-presenter";
import {
  StyleProjectionPages,
  useProjectionPageStyles,
} from "../styles-projection-pages";
import { ProjectionFormBaseData } from "./projection-form-basedata";
import { ProjectionFormPlantCapacity } from "./projection-form-capacity";
import { ProjectionFormPlantMembrane } from "./projection-form-membrane";
import { ProjectionFormSalesForce } from "./projection-form-salesforce";

interface OwnProps extends StyleProjectionPages {
  presenter: ProjectionPresenter;
}

export const ProjectionInputs: React.FC<OwnProps> = (props) => {
  const classes = useProjectionPageStyles(props);

  return useObserver(() => {
    const presenter = props.presenter;
    const {
      projection,
      formDataBase,
      formDataProjectionValues,
      onChange,
    } = presenter;

    if (!projection) {
      return null;
    }

    const record = projection.record;

    return (
      <React.Fragment>
        <div>
          <Grid container spacing={4}>
            <Grid item xs={3} className={classes.section}>
              <ProjectionFormBaseData
                data={formDataBase}
                onChange={onChange}
                validations={{
                  name: "required",
                  status: "required",
                  country: "required",
                }}
              />
            </Grid>
            <Grid item xs={3} className={classes.section}>
              <ProjectionFormSalesForce
                data={{
                  account: record.account,
                  opportunity: record.opportunity,
                }}
                onChange={onChange}
                validations={{
                  account: "required",
                  opportunity: "required",
                }}
              />
            </Grid>
            <Grid item xs={3} className={classes.section}>
              <ProjectionFormPlantCapacity
                data={formDataProjectionValues([
                  "water_source",
                  "capacity_base",
                  "capacity.values",
                  "capacity.unit",
                ])}
                onChange={onChange}
                validations={{
                  water_source: "required",
                  capacity_base: "required",
                  "capacity.unit": "required",
                  "capacity.values": "required",
                }}
              />
            </Grid>
            <Grid item xs={3} className={classes.section}>
              <ProjectionFormPlantMembrane
                data={formDataProjectionValues(["membrane_element"])}
                onChange={onChange}
                validations={{
                  membrane_element: "required",
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container spacing={2}>
            {projection.categorizedValues["input.water.main"] &&
              projection.categorizedValues["input.water.main"].length && (
                <Grid item md={4}>
                  <Widget
                    title="Main feed water parameters"
                    icon={
                      <FontAwesomeIcon icon={["fal", "clipboard-list-check"]} />
                    }
                  >
                    <ValuesTableForm
                      values={projection.categorizedValues["input.water.main"]}
                      onValueChange={projection.updateValue}
                    />
                  </Widget>
                </Grid>
              )}
            {projection.categorizedValues["input.feed.detailed"] &&
              projection.categorizedValues["input.feed.detailed"].length && (
                <Grid item md={4}>
                  <Widget
                    title="Detailed feed parameters"
                    icon={
                      <FontAwesomeIcon icon={["fal", "clipboard-list-check"]} />
                    }
                  >
                    <ValuesTableForm
                      values={
                        projection.categorizedValues["input.feed.detailed"]
                      }
                      onValueChange={projection.updateValue}
                    />
                  </Widget>
                </Grid>
              )}
          </Grid>
        </div>
      </React.Fragment>
    );
  });
};
