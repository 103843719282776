import * as React from "react";
import {
  FormProps,
  FormPresenter,
} from "../../../components/form/form-presenter";
import { useEventHandler } from "../../../../hooks/use-eventmapper";
import { usePresenter } from "../../../../hooks/use-presenter";
import { useObserver } from "mobx-react";
import { Grid, TextField, makeStyles, Typography } from "@material-ui/core";
import { SelectCountry } from "../../../components/form/fields/select-country";
import { PentairTheme } from "../../../../config/theme/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectStatus } from "../../../components/form/fields/select-status";
import InfoLabel from "../../../components/form/info-label";

type FormData = {
  name?: string;
  country?: string;
  status?: string;
};

interface OwnProps extends FormProps<FormData> {
  classes?: Record<"titleWrap" | "root" | "icon", string>;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {
    margin: theme.spacing(0, 0, 3, 0),
    border: "none",
  },
  titleWrap: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  icon: {
    width: theme.spacing(2) + "px !important",
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.blue.main,
  },
}));

export const ProjectionFormBaseData: React.FC<OwnProps> = (props) => {
  const [eventHandler, valueGetter] = useEventHandler();
  const classes = useStyles(props);
  const presenter = usePresenter(
    ({ utils }) => new FormPresenter<FormData>(props, utils.validator)
  );

  return useObserver(() => {
    const { data, change, errors } = presenter;

    return (
      <div className={classes.root}>
        <div className={classes.titleWrap}>
          <FontAwesomeIcon
            className={classes.icon}
            icon={["fal", "info-circle"]}
          />
          <Typography variant="h4">Projection</Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              margin="normal"
              placeholder="Case"
              id="name"
              label={<InfoLabel title="Case" description="The project name" />}
              onChange={eventHandler(change, "name", valueGetter)}
              value={data.name}
              error={errors.name ? true : false}
              helperText={errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectCountry
              value={data.country}
              required
              fullWidth
              margin="normal"
              placeholder=""
              id={"country"}
              label={
                <InfoLabel
                  title="Country"
                  description="Select a country from the dropdown"
                />
              }
              error={errors.country ? true : false}
              helperText={errors.country}
              onChange={eventHandler(change, "country", valueGetter)}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectStatus
              value={data.status}
              required
              fullWidth
              margin="normal"
              placeholder=""
              id={"status"}
              label={
                <InfoLabel title="Status" description="The project status" />
              }
              error={errors.status ? true : false}
              helperText={errors.status}
              onChange={eventHandler(change, "status", valueGetter)}
            />
          </Grid>
        </Grid>
      </div>
    );
  });
};
