import { Presenter } from "./hooks/use-presenter";
import { UserInteractor } from "./application/resources/user/user-interactor";
import { computed } from "mobx";
import { User } from "./application/resources/user/user";

export class AppPresenter implements Presenter {
  constructor(private _userInteractor: UserInteractor) {
    //
  }

  @computed public get user(): User | null {
    try {
      return this._userInteractor.currentUser;
    } catch (err) {
      return null;
    }
  }

  public mount = () => {};
  public unmount = () => {};
}
