import * as React from "react";
import {
  FormProps,
  FormPresenter,
} from "../../../components/form/form-presenter";
import { useEventHandler } from "../../../../hooks/use-eventmapper";
import { usePresenter } from "../../../../hooks/use-presenter";
import { useObserver } from "mobx-react";
import { Grid, TextField, makeStyles, Typography } from "@material-ui/core";
import { PentairTheme } from "../../../../config/theme/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoLabel from "../../../components/form/info-label";

type FormData = {
  account?: string;
  opportunity?: string;
};

interface OwnProps extends FormProps<FormData> {
  classes?: Record<"titleWrap", string>;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {
    margin: theme.spacing(0, 0, 3, 0),
    border: "none",
  },
  titleWrap: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  icon: {
    width: theme.spacing(2) + "px !important",
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.blue.main,
  },
}));

export const ProjectionFormSalesForce: React.FC<OwnProps> = (props) => {
  const [eventHandler, valueGetter] = useEventHandler();
  const classes = useStyles(props);
  const presenter = usePresenter(
    ({ utils }) => new FormPresenter<FormData>(props, utils.validator)
  );

  return useObserver(() => {
    const { data, change, errors } = presenter;

    return (
      <div className={classes.root}>
        <div className={classes.titleWrap}>
          <FontAwesomeIcon
            className={classes.icon}
            icon={["fal", "usd-circle"]}
          />
          <Typography variant="h4">SalesForce</Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              margin="normal"
              placeholder="Account name"
              id="account"
              label={
                <InfoLabel title="Account name" description="Account name" />
              }
              onChange={eventHandler(change, "account", valueGetter)}
              value={data.account}
              error={errors.account ? true : false}
              helperText={errors.account}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              margin="normal"
              placeholder="Opportunity number"
              id="opportunity"
              label={
                <InfoLabel
                  title="Opportunity number"
                  description="Opportunity number in SalesForce"
                />
              }
              onChange={eventHandler(change, "opportunity", valueGetter)}
              value={data.opportunity}
              error={errors.opportunity ? true : false}
              helperText={errors.opportunity}
            />
          </Grid>
        </Grid>
      </div>
    );
  });
};
