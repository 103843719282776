export const TechnologyFragment = `
  id
  name
  oneliner
  active
  description
  image
  techImage
  comparableTechnologies
  techValues {
    id
    name
    values
    recommendedValues
    unit
    unitOptions
    valueOptions
    disabled
    error
    category
    decimals
  }
  techImageMap {
    title
    titleVariant
    location
    rotation
    width
    values
    align
    line
    info
  }
`;
