import { RouteComponentProps } from "react-router";
import { Auth } from "aws-amplify";

export const PageLogout = (props: RouteComponentProps) => {
  Auth.signOut().then(() => {
    window.location.replace("/");
  });

  return null;
};
