import gql from "graphql-tag";
import {
  runQuery,
  RunQueryOptions,
} from "../../../../application/network/run-query";
import { UserFragment } from "../../../../application/resources/user/graphql/fragments";
import { UserRecord } from "../../../../application/resources/user/user";

interface MutationDeleteUserArgs {
  id: string;
}

const buildQuery = (fragment: string = UserFragment) => gql`
  mutation DeleteUser($id:ID!) {
    deleteUser(id: $id) {
      ${fragment}
    }
  }
`;

export const deleteUser = async (
  options: RunQueryOptions<MutationDeleteUserArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<MutationDeleteUserArgs, { deleteUser: UserRecord }>({
    query,
    variables: options.variables,
  });
};
