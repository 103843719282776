import { IconName } from "@fortawesome/pro-light-svg-icons";
import { Presenter } from "../../hooks/use-presenter";
import {
  RouteProvider,
  RouteOptions,
} from "../../application/business/provider/routes";
import { RouteNames } from "../../config/application";
import { User, UserRecord } from "../../application/resources/user/user";
import { computed, observable } from "mobx";

interface ButtonOptions {
  label: string;
  icon: IconName;
  route: RouteOptions;
  href: string;
}

export class PageDashboardPresenter implements Presenter {
  @observable public options: ButtonOptions[] = [];

  constructor(
    protected routeProvider: RouteProvider<RouteNames>,
    private _user: User
  ) {
    //
  }

  @computed public get user(): UserRecord {
    return this._user.record;
  }

  public mount = () => {
    this.options = [
      this.generateOption("app.assistant", {
        label: "Find Solution",
        icon: "user-headset",
      }),
      this.generateOption("project.index", {
        label: "Manage projects",
        icon: "pencil-alt",
      }),
      this.generateOption("user.index", {
        label: "Manage users",
        icon: "users-cog",
      }),
      this.generateOption("salesforce.config", {
        label: "Manage integrations",
        icon: "cloud-download",
      }),
      // this.generateOption("app.administration", {
      //   label: "Xpert administration",
      //   icon: "cogs",
      // }),
    ];
  };
  public unmount = () => {};

  private generateOption = (
    routeName: RouteNames,
    options: Partial<ButtonOptions>
  ): ButtonOptions => {
    const route = this.routeProvider.getOptions(routeName);
    const href = this.routeProvider.generatePath(routeName);

    return {
      label: "Label",
      icon: "question",
      ...options,
      route,
      href,
    };
  };
}
