import * as React from "react";
import { FormProps, FormPresenter } from "../../../components/form/form-presenter";
import { useEventHandler } from "../../../../hooks/use-eventmapper";
import { usePresenter } from "../../../../hooks/use-presenter";
import { useObserver } from "mobx-react";
import { Grid, TextField } from "@material-ui/core";
import InfoLabel from "../../../components/form/info-label";

type FormData = {
  name?: string;
  description?: string;
};

interface OwnProps extends FormProps<FormData> {
  //
}

export const ProfileFormDetails: React.FC<OwnProps> = (props) => {
  const [eventHandler, valueGetter] = useEventHandler();
  const presenter = usePresenter(
    ({ utils }) => new FormPresenter<FormData>(props, utils.validator)
  );

  return useObserver(() => {
    const { data, change, errors } = presenter;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            required
            fullWidth
            margin="normal"
            placeholder="Profile name"
            id="profile-name"
            label={<InfoLabel title="Profile name" description="The profile name" />}
            onChange={eventHandler(change, "name", valueGetter)}
            value={data.name}
            error={errors.name ? true : false}
            helperText={errors.name}
          />
        </Grid>
        <Grid item xs={12} md={true}>
          <TextField
            required
            fullWidth
            margin="normal"
            placeholder="Profile description"
            id="profile-description"
            label={<InfoLabel title="Profile description" description="The profile description" />}
            onChange={eventHandler(change, "description", valueGetter)}
            value={data.description}
          />
        </Grid>
      </Grid>
    );
  });
};
