import { Presenter } from "../../../hooks/use-presenter";
import { computed } from "mobx";
import { PermissionProvider } from "../../../application/resources/permission/permission-provider";
import { Permission } from "../../../application/resources/permission/permission";
import { UserInteractor } from "../../../application/resources/user/user-interactor";
import { Collection } from "../../../application/base/graphql/graphql-collection";

let permissions: Collection<Permission>;

export class PagePermissionIndexPresenter implements Presenter {
  constructor(
    protected _userInteractor: UserInteractor,
    protected _permissionProvider: PermissionProvider
  ) {
    permissions = permissions || this._permissionProvider.collect();
  }

  public mount = async () => {
    if (!permissions.loaded) {
      await permissions.fetch();
    }
  };

  public unmount = () => {
    //
  };

  @computed public get permissions() {
    return permissions.items;
  }

  @computed public get collectionLoading() {
    return permissions.loading;
  }

  public updatePermision = (data: Partial<Permission>) => {
    // if (this.selectedPermission) {
    //     this.selectedPermission.save();
    //     this.selectedPermission = null;
    //   }
  };

  public cancelUpdate = () => {
    // this.selectedPermission = null;
  };

  public selectPermission = (id: string) => {
    // this.selectedPermission = this._permissionProvider.get(id);
  };

  public toggleCreate = () => {
    // this.createActive = !this.createActive;
  };
}
