import { routesUser } from "./routes-user";
import { routesDefault } from "./routes-default";
import { routesProfile } from "./routes-profile";
import { routesPermission } from "./routes-permission";
import { routesProject } from "./routes-project";
import { routesSalesForce } from "./routes-salesforce";

export const routeConfig = {
  ...routesSalesForce,
  ...routesProject,
  ...routesUser,
  ...routesProfile,
  ...routesPermission,
  ...routesDefault,
};
