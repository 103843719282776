import * as React from "react";
import {
  FormProps,
  FormPresenter,
} from "../../../components/form/form-presenter";
import { PentairTheme } from "../../../../config/theme/types";
import {
  makeStyles,
  Typography,
  Grid,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { useEventHandler } from "../../../../hooks/use-eventmapper";
import { usePresenter } from "../../../../hooks/use-presenter";
import { useObserver } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoLabel from "../../../components/form/info-label";

type FormData = {
  membrane_element?: string;
};

interface OwnProps extends FormProps<FormData> {
  classes?: Record<"titleWrap" | "root" | "icon", string>;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {
    margin: theme.spacing(0, 0, 3, 0),
    border: "none",
  },
  titleWrap: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  icon: {
    width: theme.spacing(2) + "px !important",
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.blue.main,
  },
}));

export const ProjectionFormPlantMembrane: React.FC<OwnProps> = (props) => {
  const [eventHandler, valueGetter] = useEventHandler();
  const classes = useStyles(props);
  const presenter = usePresenter(
    ({ utils }) => new FormPresenter<FormData>(props, utils.validator)
  );

  return useObserver(() => {
    const { data, change, errors } = presenter;

    return (
      <div className={classes.root}>
        <div className={classes.titleWrap}>
          <FontAwesomeIcon className={classes.icon} icon={["fal", "cogs"]} />
          <Typography variant="h4">Membrane elements</Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              select
              required
              fullWidth
              margin="normal"
              placeholder="Membrane"
              id="membrane_element"
              label={
                <InfoLabel title="Membrane" description="Membrane element" />
              }
              onChange={eventHandler(change, "membrane_element", valueGetter)}
              value={data.membrane_element}
              error={errors.membrane_element ? true : false}
              helperText={errors.membrane_element}
            >
              <MenuItem value="Aquaflex 64">Aquaflex 64</MenuItem>
              <MenuItem value="XF64">XF64</MenuItem>
              <MenuItem value="Crossflow UF 64">Crossflow UF 64</MenuItem>
              <MenuItem value="XLINE 64">XLINE 64</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </div>
    );
  });
};
