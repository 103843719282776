import { Collection } from "../../../core/collection";
import { PermissionRecord } from "../permission";
import {
  collectPermissions,
  QueryPermissionCollectionArgs,
} from "./functions/collect";

export class PermissionCollection extends Collection<PermissionRecord> {
  public errors: object[] = [];

  constructor(
    protected _fragment: string | undefined,
    protected _setState: (state: any) => void
  ) {
    super(_setState);
  }

  public handleResponse = (
    records: Partial<PermissionRecord>[] | null | undefined,
    errors: object[] = []
  ) => {
    this.records = (records as any[]) || [];
    this.errors = errors;
    this._changed();
  };

  public fetch = async (variables?: QueryPermissionCollectionArgs) => {
    const res = await collectPermissions({
      variables,
      query: { fragment: this._fragment },
    });
    this.handleResponse(res.data && res.data.PermissionCollection, res.errors);
  };
}
