import gql from "graphql-tag";
import { runQuery, RunQueryOptions } from "../../../../network/run-query";
import { ProjectionFragment } from "../../graphql/fragments";
import { ProjectionRecord } from "../../projection";
import { InputProjection } from "./create";

interface MutationUpdateProjectionArgs {
  input: InputProjection;
}

const buildQuery = (fragment: string = ProjectionFragment) => gql`
  mutation UpdateProjection($id:ID!, $input: inputProjection!) {
    updateProjection(id:$id, input: $input) {
      ${fragment}
    }
  }
`;

export const updateProjection = async (
  options: RunQueryOptions<MutationUpdateProjectionArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<
    MutationUpdateProjectionArgs,
    { updateProjection: ProjectionRecord }
  >({
    query,
    variables: options.variables,
  });
};
