import { ProjectionPresenter } from "../projection-presenter";
import * as React from "react";
import {
  StyleProjectionPages,
  useProjectionPageStyles,
} from "../styles-projection-pages";
import { useObserver } from "mobx-react";
import { ValuesTable } from "../../../components/table/table-values";
import { Grid, Typography } from "@material-ui/core";
import { InteractiveImage } from "./projection-interactive-image";
import { CalculationValueOptions } from "../../../../application/resources/technology/calculation-values";
import { CalculationValue } from "../../../../application/resources/technology/technology";
import { InfoMarker } from "../../../../v2/common/ui/info-marker";

interface OwnProps extends StyleProjectionPages {
  presenter: ProjectionPresenter;
}

export const ProjectionResults: React.FC<OwnProps> = (props) => {
  const classes = useProjectionPageStyles(props);

  return useObserver(() => {
    const presenter = props.presenter;
    const { projection } = presenter;

    if (!projection) {
      return null;
    }

    const record = projection.record;

    return (
      <React.Fragment>
        <Grid container spacing={1}>
          <Grid item xs={3} className={classes.section}>
            <ValuesTable
              hideRecommendedValue={true}
              variant={"list"}
              title="Projection details"
              values={projection.categorizedValues["design.details"]}
              icon="info-circle"
            />
          </Grid>

          <Grid item xs={3} className={classes.section}>
            <ValuesTable
              hideRecommendedValue={true}
              variant={"list"}
              title="Calculation inputs"
              values={[
                projection.valueMap["water_source"],
                projection.valueMap["capacity"],
                projection.valueMap["capacity_base"],
                projection.valueMap["design_turbidity"],
                projection.valueMap["temperature"],
              ]}
              icon="clipboard-check"
            />
          </Grid>

          {projection.categorizedValues["plant.configuration"] &&
            projection.categorizedValues["plant.configuration"].length && (
              <Grid item xs={3} className={classes.section}>
                <ValuesTable
                  hideRecommendedValue={true}
                  variant={"list"}
                  title="Plant configuration"
                  values={projection.categorizedValues["plant.configuration"]}
                  icon="clipboard-check"
                />
              </Grid>
            )}
          {projection.categorizedValues["results"] &&
            projection.categorizedValues["results"].length && (
              <Grid item xs={3} className={classes.section}>
                <ValuesTable
                  hideRecommendedValue={true}
                  variant={"list"}
                  title="Main settings &amp; calculations"
                  values={projection.categorizedValues["results"]}
                  icon="calculator-alt"
                />
              </Grid>
            )}
        </Grid>
        <Grid item xs={12} className={classes.interactiveImage}>
          <div
            style={{
              marginBottom: -48,
              marginTop: 24,
            }}
          >
            {record.solution.techImage && (
              <InteractiveImage img={record.solution.techImage}>
                {() => (
                  <React.Fragment>
                    {record &&
                      record.solution.techImageMap &&
                      record.solution.techImageMap.map(
                        (markerConfig, index) => {
                          const calculationValues = markerConfig.values.map(
                            (id: CalculationValueOptions) =>
                              projection.value(id)
                          );

                          return (
                            <InfoMarker
                              title={
                                markerConfig.title || calculationValues[0].name
                              }
                              icon="info-circle"
                              location={markerConfig.location}
                              rotation={markerConfig.rotation}
                              align={markerConfig.align}
                              line={markerConfig.line}
                              info={markerConfig.info}
                              width={markerConfig.width}
                              animationDelay={300 * index}
                              titleVariant={markerConfig.titleVariant}
                            >
                              <React.Fragment>
                                {(calculationValues || []).map(
                                  (cval: CalculationValue) => (
                                    <Typography
                                      variant="body2"
                                      style={{
                                        fontSize: 13,
                                        lineHeight: 1,
                                        marginTop: 4,
                                      }}
                                    >
                                      {calculationValues.length === 1
                                        ? [
                                            cval.values.join(" - "),
                                            cval.unit,
                                          ].join(" ")
                                        : [
                                            cval.name + ":",
                                            cval.values.join(" - "),
                                            cval.unit,
                                          ].join(" ")}
                                    </Typography>
                                  )
                                )}
                              </React.Fragment>
                            </InfoMarker>
                          );
                        }
                      )}
                  </React.Fragment>
                )}
              </InteractiveImage>
            )}
          </div>
        </Grid>
      </React.Fragment>
    );
  });
};
