import * as React from "react";
import { PageProfileIndexPresenter } from "./page-profile-index-presenter";
import { useObserver } from "mobx-react";
import { Grid, Button, Typography } from "@material-ui/core";
import { PageHeader } from "../../components/page/page-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageTitles } from "../../components/page/page-titles";
import { AdministrationMenuAccess } from "../../components/menus/menu-access";
import { Widget } from "../../components/widget/widget";
import { TableProfiles } from "./partials/profile-table";
import { useRedirect } from "../../../hooks/use-redirect";
import { PageContent } from "../../components/page/page-content";
import { makePresenter } from "../../../application/helpers/make-presenter";
import { Page } from "../../components/page/page-wrapper";

const usePresenter = makePresenter(
  ({ provider, interactor }) =>
    new PageProfileIndexPresenter(useRedirect(), interactor.user, provider.profile)
);

interface OwnProps {}

export const PageProfileIndex: React.FC<OwnProps> = (props) => {
  const presenter = usePresenter(props);

  return useObserver(() => {
    const { profiles, collectionLoading, editProfile, createProfile } = presenter;

    return (
      <Page>
        <PageContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <PageHeader>
                <Button
                  onClick={createProfile}
                  variant="contained"
                  color="primary"
                  startIcon={<FontAwesomeIcon icon={["fal", "plus"]} />}
                >
                  Create profile
                </Button>
              </PageHeader>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
              <Widget>
                <PageTitles title="Xpert" subtitle={"profiles"} />
                <Typography paragraph variant="body2">
                  The profile profile contains all the information required to authorize a profile
                  to CRU(D) records or access components.
                </Typography>
                <Typography paragraph variant="body2">
                  Here you can create profile profiles that can be assigned to profiles.
                </Typography>
                <br />
                <AdministrationMenuAccess />
              </Widget>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <Widget
                icon={<FontAwesomeIcon icon={["fal", "table"]} />}
                title="Profile overview"
                loading={collectionLoading}
              >
                <TableProfiles onEdit={editProfile} items={profiles} />
              </Widget>
            </Grid>
          </Grid>
        </PageContent>
      </Page>
    );
  });
};
