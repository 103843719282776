import { makeStyles } from "@material-ui/core";
import { PentairTheme } from "../../config/theme/types";

export type StyleTabs = {
  classes?: Record<"root" | "button", string>;
};

export const useTabsStyles = makeStyles<PentairTheme>((theme) => ({
  root: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    background: "#f2f2f2",
    color: theme.palette.grey[500],
    border: "none",
    display: "flex",
    height: theme.spacing(4),
  },
  button: {
    color: theme.palette.grey[500],
  },
}));
