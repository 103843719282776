import gql from "graphql-tag";
import {
  runQuery,
  RunQueryOptions,
} from "../../../../application/network/run-query";
import { UserProfileRecord } from "../../../../application/resources/user-profile/user-profile";
import { UserFragment } from "../../../../application/resources/user/graphql/fragments";
import {
  UserAttributes,
  UserRecord,
} from "../../../../application/resources/user/user";

export interface InputUser {
  id?: string;
  username?: string;
  created_at?: string;
  updated_at?: string;
  status?: string;
  attributes?: UserAttributes;
  login_history?: string;
  profile?: UserProfileRecord;
}

interface MutationCreateUserArgs {
  input: InputUser;
}

const buildQuery = (fragment: string = UserFragment) => gql`
  mutation CreateUser($input: inputUser!) {
    createUser(input: $input) {
      ${fragment}
    }
  }
`;

export const createUser = async (
  options: RunQueryOptions<MutationCreateUserArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<MutationCreateUserArgs, { createUser: UserRecord }>({
    query,
    variables: options.variables,
  });
};
