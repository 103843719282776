import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid } from "@material-ui/core";
import * as React from "react";
import { MessageType } from "../../../application/business/interactor/message-interactor";
import { useSalesForce } from "../../../application/resources/salesforce/hooks/use-single";
import { SalesForceConfigRecord } from "../../../application/resources/salesforce/single";
import { theme } from "../../../config/theme/pentair";
import LoadingIcon from "../../../v2/common/ui/loading-icon";
import { Message } from "../../../v2/common/ui/message";
import { FormFieldSalesforceApikey } from "../../components/form/fields/salesforce-api-key";
import { FormFieldSalesforcePassword } from "../../components/form/fields/salesforce-password";
import { FormFieldSalesforceUsername } from "../../components/form/fields/salesforce-username";
import { PageContent } from "../../components/page/page-content";
import { PageTitles } from "../../components/page/page-titles";
import { Page } from "../../components/page/page-wrapper";
import { StyleDefaultPages } from "../errors/styles-error-pages";
import { useProjectionSingleStyles } from "../projection/styles-projection-single";

interface OwnProps extends StyleDefaultPages {}

export const PageSalesForceConfig: React.FC<OwnProps> = (props) => {
  const classes = useProjectionSingleStyles(props);
  const [saving, setSaving] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<Error[]>([]);
  const [messages, setMessages] = React.useState<string[]>([]);
  const [validationErrors, setValidationErrors] = React.useState<
    Record<string, string>
  >({});
  const salesforceConfig = useSalesForce();

  const onUpdate = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (Object.keys(validationErrors).length) {
        return;
      }

      setSaving(true);
      try {
        await salesforceConfig.update();
        setMessages(["Succesfully saved API credentials", ...messages]);
      } catch (err) {
        setErrors(err.errors);
      }
      setSaving(false);
    },
    [salesforceConfig.record]
  );

  const onChange = React.useCallback(
    (key: keyof SalesForceConfigRecord) => (value: any) => {
      salesforceConfig.change(key, value);
    },
    []
  );

  const onValidationError = React.useCallback(
    (key: string) => (error: string | false) => {
      if (validationErrors[key] || false !== error) {
        if (error) {
          validationErrors[key] = error;
        } else {
          delete validationErrors[key];
        }

        setValidationErrors({ ...validationErrors });
      }
    },
    [validationErrors, setValidationErrors]
  );

  React.useEffect(() => {
    setLoading(true);

    salesforceConfig.fetch().then(() => {
      setLoading(false);
    });
  }, [salesforceConfig]);

  return (
    <Page variant="fullscreen">
      <PageContent>
        <div
          className={classes.header}
          style={{ paddingTop: theme.spacing(4) }}
        >
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <PageTitles
                title={"SalesForce config"}
                subtitle={"Edit the salesforce config"}
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{ textAlign: "right" }}
              alignItems="center"
            >
              <Button
                onClick={onUpdate}
                startIcon={
                  (saving && (
                    <FontAwesomeIcon icon={["fal", "spinner"]} spin />
                  )) || <FontAwesomeIcon icon={["fal", "plus"]} />
                }
                disabled={saving}
              >
                Update salesforce config
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={classes.sectionWrap} style={{ maxWidth: "600px" }}>
          {[...errors, ...messages].map((message) => (
            <Message show={true} type={MessageType.Info} children={message} />
          ))}
          {(loading && <LoadingIcon />) || (
            <form onSubmit={onUpdate}>
              <FormFieldSalesforceUsername
                value={salesforceConfig.record.username}
                onChange={onChange("username")}
                onError={onValidationError("username")}
                textfieldProps={{ margin: "normal" }}
              />
              <FormFieldSalesforcePassword
                value={salesforceConfig.record.password}
                onChange={onChange("password")}
                onError={onValidationError("password")}
                textfieldProps={{ margin: "normal" }}
              />
              <FormFieldSalesforceApikey
                value={salesforceConfig.record.apikey}
                onChange={onChange("apikey")}
                onError={onValidationError("apiKey")}
                textfieldProps={{ margin: "normal" }}
              />
            </form>
          )}
        </div>
      </PageContent>
    </Page>
  );
};
