import { makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { ProjectClass } from "../../../../../v2/feature/project/single";
import { UserRecord } from "../../../../../application/resources/user/user";
import { PentairTheme } from "../../../../../config/theme/types";
import { typographyVariants } from "../../../../../config/theme/typography";
import { ProjectShareDialog } from "./project-share-dialog";
import { SharedUserAddButton } from "./shared-user-add-button";
import { SharedUserIcon } from "./shared-user-icon";

interface OwnProps {
  project: ProjectClass;
  user: UserRecord;
}

const useStyles = (props: OwnProps) =>
  makeStyles((theme: PentairTheme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      textAlign: "right",
    },
    users: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  }))(props);

export const SharingComponent: React.FC<OwnProps> = (props) => {
  const { project, user } = props;
  const users = project.record.users || [];
  const classes = useStyles(props);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  return (
    <>
      <div className={classes.root}>
        <Typography variant={typographyVariants.body2}>
          <strong>Shared with</strong>
        </Typography>
        <div className={classes.users}>
          {users.map((user, index) => {
            return (
              <SharedUserIcon user={user} index={index} />
              //   user.user.id +
              //   (user.permissions.read && "Read") +
              //   (user.permissions.write && "Write")
            );
          })}
          <SharedUserAddButton
            onClick={() => {
              setDialogOpen(true);
            }}
          />
        </div>
      </div>
      <ProjectShareDialog
        currentUser={user}
        open={dialogOpen}
        project={project}
        onClose={(project) => {
          setDialogOpen(false);
        }}
      />
    </>
  );
};
