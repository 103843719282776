import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react";
import * as React from "react";
import { makePresenter } from "../../../../application/helpers/make-presenter";
import { AssistantResponse } from "../../../../application/resources/assistant/assistant-response";
import { theme } from "../../../../config/theme/pentair";
import { PentairTheme } from "../../../../config/theme/types";
import { typographyVariants } from "../../../../config/theme/typography";
import { useRedirect } from "../../../../hooks/use-redirect";
import { ProjectionCreateDialog } from "../../../../v2/feature/projection/view/dialog/projection-create-dialog";
import { PageLoading } from "../../../pages/page-loading";
import { AssistantResultsPresenter } from "./results-presenter";

interface OwnProps {
  onReset?(): void;
  presenterReset(): void;
  technology: string;
  technologies: string[];
  project?: string;
  messages: AssistantResponse[];
}

const usePresenter = makePresenter(
  ({ provider }) =>
    new AssistantResultsPresenter(
      useRedirect(),
      provider.technology,
      provider.projection,
      provider.project
    ),
  (presenter, props) => {
    presenter.solutionId = props.technology;
    if (props.project) {
      presenter.project = props.project;
    }
  }
);

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {},
  resultTechnology: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "flex 0.5s",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(6),
  },
  resultPanelTitle: {
    color: theme.palette.blue.main,
    textAlign: "left",
  },
  technologyRoot: {
    maxWidth: "100%",
  },
  technologySelector: {
    justifyContent: "flex-start",
  },
  message: {
    fontFamily: "Barlow Condensed",
    fontWeight: 300,
    fontSize: "calc(1.75em + 1vw)",
    color: theme.palette.silverGray.main,
    opacity: 0,
  },
  answerSheet: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(6),
  },
  imageContainer: {
    position: "relative",
    padding: theme.spacing(1),
  },
  technology: {
    width: "100%",
    maxWidth: theme.spacing(12),
    display: "inline-block",
    transition: "opacity 0.3s",
    opacity: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const getCompleteMessage = (technologies: any[]) => {
  if (technologies.length == 1) return "I recommend the following solution:";
  return "I recommend one of the following solutions:";
};

export const AssistantResults: React.FC<OwnProps> = (props) => {
  const presenter = usePresenter(props);
  const classes = useStyles(props);
  const [createPopup, setCreatePopup] = React.useState(true);

  const { technologies, messages, presenterReset } = props;

  return useObserver(() => {
    const { solution, loading } = presenter;

    if (!solution) {
      return null;
    }

    if (loading) {
      return <PageLoading message="Fetching solutions" />;
    }

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={6}>
            <Grid container className={classes.resultTechnology}>
              <Grid item xs={12} style={{ maxWidth: "100%" }}>
                <Typography
                  className={classes.resultPanelTitle}
                  variant={typographyVariants.h1}
                  gutterBottom
                  align={"center"}
                  style={{ paddingBottom: theme.spacing(3) }}
                >
                  {getCompleteMessage(technologies)}
                </Typography>

                <div
                  className={classes.technology}
                  onClick={() => setCreatePopup(true)}
                >
                  <div className={classes.imageContainer}>
                    <img src={solution.record.image} width="100%" />
                  </div>
                  <Typography align={"center"} variant={"h5"}>
                    {solution.record.name}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: theme.spacing(2) }}>
                <Typography variant={typographyVariants.body1}>
                  {solution.record.description}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: theme.spacing(2) }}>
                <Button
                  color="secondary"
                  startIcon={
                    <FontAwesomeIcon icon={["fal", "arrow-circle-right"]} />
                  }
                  onClick={() => {
                    props.onReset && props.onReset();
                    presenterReset();
                  }}
                >
                  Reset assistant
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ borderLeft: "1px solid #eaeaea" }}>
            <Grid container className={classes.answerSheet}>
              <Grid item xs={12}>
                <Typography
                  variant={typographyVariants.h2}
                  style={{
                    color: theme.palette.blue.main,
                    paddingBottom: theme.spacing(3),
                  }}
                >
                  Answers given by you
                </Typography>
                <div>
                  {messages.map((msg) => {
                    return (
                      msg.givenAnswer && (
                        <div
                          style={{
                            marginTop: theme.spacing(2),
                            marginBottom: theme.spacing(2),
                          }}
                        >
                          <Typography variant={typographyVariants.body1}>
                            {msg.record.message.messageText}
                          </Typography>

                          <Typography
                            variant={typographyVariants.body1}
                            style={{ color: theme.palette.brightBlue.main }}
                          >
                            <strong>{msg.givenAnswer}</strong>
                          </Typography>
                        </div>
                      )
                    );
                  })}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ProjectionCreateDialog
          onClose={() => setCreatePopup(false)}
          open={createPopup}
          solutionid={solution.id || ""}
        />
      </React.Fragment>
    );
  });
};
