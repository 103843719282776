import gql from "graphql-tag";
import {
  runQuery,
  RunQueryOptions,
} from "../../../../application/network/run-query";
import { ProjectRecord } from "../../../../application/resources/project/project";
import { ProjectFragment } from "../graphql/fragments";

interface MutationDeleteProjectArgs {
  id: string;
}

/**
 * Returns a Query
 *
 * @param fragment - Query fragment containing the data you wish to retrieve
 *
 * @returns DocumentNode containing the query string
 */
const buildQuery = (fragment: string = ProjectFragment) => gql`
  mutation DeleteProject($id:ID!) {
    deleteProject(id: $id) {
      ${fragment}
    }
  }
`;

/**
 * Attempts to delete a project and expects to return the deleted project
 *
 * @param options - Options for creating the GraphQL Query.
 *
 * @returns GraphQL response
 */
export const deleteProject = async (
  options: RunQueryOptions<MutationDeleteProjectArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<
    MutationDeleteProjectArgs,
    { deleteProject: ProjectRecord }
  >({
    query,
    variables: options.variables,
  });
};
