import { computed, observable } from "mobx";
import { ToolConfig } from "../../../config/components/interface";
import { InterfaceTools } from "../../../view/interface/default-interface-presenter";
import { toolConfig } from "../../../config/components/interface";
import { UserInteractor } from "../../resources/user/user-interactor";

export interface InterfaceInteractor {
  activeTool: InterfaceTools;
  config: Record<InterfaceTools, ToolConfig>;
  activated: boolean;
  visible: boolean;
  activeToolConfig: ToolConfig;
}

export class DefaultInterfaceInteractor implements InterfaceInteractor {
  @observable public config = toolConfig;
  @observable public visible: boolean = true;
  @observable public activeTool: InterfaceTools = InterfaceTools.None;

  constructor(protected _userInteractor: UserInteractor) {
    //
  }

  @computed public get activated(): boolean {
    try {
      return (
        this._userInteractor.currentUser &&
        this._userInteractor.legalAgreements &&
        true
      );
    } catch {
      return false;
    }
  }

  @computed
  public get activeToolConfig() {
    return this.config[this.activeTool];
  }

  public show = () => {
    this.visible = true;
  };

  public hide = () => {
    this.visible = false;
  };
}
