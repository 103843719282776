import { GraphQLResult } from "@aws-amplify/api-graphql";

export interface QueryOptions<
  TRecord extends Record<string, any>,
  TData = any,
  TVariables extends Record<string, any> = any
> {
  query: string;
  variables?: TVariables;
  reducer(d: GraphQLResult<TData>): TRecord;
}

export interface Pagination {
  start: String;
  stop: String;
}

export class Collection<TRecord> {
  public records: Partial<TRecord>[] = [];
  public pagination?: Pagination;

  constructor(protected _setState?: (state: any) => void) {
    //
  }

  public updateItem = (record: Partial<TRecord>) => {
    const index = this.getIndex(record);
    if (index >= 0) {
      this.records.splice(index, 1, { ...this.records[index], ...record });
      this._changed();
    }
  };

  public addItem = (record: Partial<TRecord>) => {
    this.records.unshift(record);
    this._changed();
  };

  public removeItem = (record: Partial<TRecord>) => {
    const index = this.getIndex(record);
    if (index >= 0) {
      this.records.splice(index, 1);
      this._changed();
    }
  };

  public getIndex = (record: any): number => {
    if (!record || !record.id) {
      return -1;
    }
    return this.records.findIndex((r: any) => r.id === (record as any).id);
  };

  public getItem = (record: any) => {
    if (!record || !record.id) {
      return null;
    }
    return this.records.find((r: any) => r.id === (record as any).id);
  };

  protected _changed = () => {
    if (this._setState) {
      this._setState({ records: this.records });
    }
  };
}
