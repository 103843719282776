import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useObserver } from "mobx-react";
import { useEventHandler } from "../../../../hooks/use-eventmapper";
import { usePresenter } from "../../../../hooks/use-presenter";
import { TableProps } from "../../../components/table/table-helpers";
import { TablePresenter } from "../../../components/table/table-presenter";
import moment from "moment";
import { IconButton } from "../../../components/mui-extended/icon-button";
import { useProjectionSingleStyles } from "../styles-projection-single";

interface OwnProps extends TableProps<any> {
  currentRevision: string;
}

export const TableRevisions: React.FC<OwnProps> = (props) => {
  const classes = useProjectionSingleStyles(props);
  const presenter = usePresenter(
    () => new TablePresenter(props.items, props.onEdit)
  );

  const [eventHandler] = useEventHandler();

  return useObserver(() => {
    return (
      <section>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell children={"Revision"} />
              <TableCell children={"Designed by"} />
              <TableCell children={"Approved by"} />
              <TableCell children={"Revision notes"} />
              <TableCell padding={"checkbox"} children={""} />
            </TableRow>
          </TableHead>
          <TableBody>
            {(props.items || []).map((item) => {
              return (
                <TableRow key={item.id}>
                  <TableCell>
                    <Typography
                      className={
                        moment(item.lastModified).format("YYMMDD.HHmm") ===
                        props.currentRevision
                          ? classes.strong
                          : classes.normal
                      }
                    >
                      {moment(item.lastModified).format("YYMMDD.HHmm")}
                    </Typography>
                  </TableCell>
                  <TableCell>{item.designedBy}</TableCell>
                  <TableCell>{item.approvedBy}</TableCell>
                  <TableCell>{item.note}</TableCell>
                  <TableCell
                    padding={"checkbox"}
                    children={
                      <IconButton
                        onClick={eventHandler(presenter.onEdit, item.id)}
                        icon="pencil-alt"
                      />
                    }
                  />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </section>
    );
  });
};
