import { useObserver } from "mobx-react";
import * as React from "react";
import { Tab } from "../../interface/partials/single-tab";
import { StyleTabs, useTabsStyles } from "../styles-tabs";

interface OwnProps extends StyleTabs {
  setActiveTab: (tab: string) => void;
  activeTab?: string;
}

export const TabsUserSingle: React.FC<OwnProps> = (props) => {
  const classes = useTabsStyles(props);
  const { activeTab, setActiveTab } = props;

  return useObserver(() => {
    return (
      <div className={classes.root}>
        <Tab
          title="Information"
          icon="address-card"
          active={activeTab === "information" || activeTab === undefined}
          onClick={() => setActiveTab("information")}
        />
        <Tab
          title="Preferences"
          icon="sliders-h"
          active={activeTab === "preferences"}
          onClick={() => setActiveTab("preferences")}
        />
      </div>
    );
  });
};
