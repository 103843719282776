import * as React from "react";
import { PageContent } from "../../components/page/page-content";
import { Assistant } from "../../components/assistant/assitant";
import { makeStyles } from "@material-ui/core";
import { PentairTheme } from "../../../config/theme/types";
import { Page } from "../../components/page/page-wrapper";

interface OwnProps {
  match: {
    params: any;
  };
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {},
  assistant: {
    margin: "auto",
    textAlign: "center",
    opacity: 1,
    transition: "opacity 0.5s",
  },
  technologyRoot: {
    maxWidth: "100%",
  },
  technologySelector: {
    justifyContent: "flex-start",
  },
  result: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "flex 0.5s",
  },
  message: {
    fontFamily: "Barlow Condensed",
    fontWeight: 300,
    fontSize: "calc(1.75em + 1vw)",
    color: theme.palette.silverGray.main,
    opacity: 0,
  },
  done: {
    "& $assistant": {
      opacity: 0,
      margin: 0,
    },
    "& $message": {
      opacity: 1,
    },
    "& $result": {
      flex: 1,
      textAlign: "left",
    },
  },
  answerSheet: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(6),
  },
  resultTechnology: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "flex 0.5s",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(6),
  },
  resultPanelTitle: {
    color: theme.palette.blue.main,
    textAlign: "left",
  },
}));

export const PageAssistant: React.FC<OwnProps> = (props) => {
  const [isDone, setDone] = React.useState<boolean>(false);
  const [technologies, setTechnologies] = React.useState<string[]>([]);
  const classes = useStyles(props);
  const project = props.match.params.project;

  return (
    <Page variant="fullscreen">
      <PageContent className={(isDone && classes.done) || undefined}>
        <Assistant
          project={project}
          technologies={technologies}
          onChange={setTechnologies}
          onDone={() => setDone(true)}
          onReset={() => setDone(false)}
          isDone={isDone}
        ></Assistant>
      </PageContent>
    </Page>
  );
};
