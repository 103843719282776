import * as React from "react";
import { Button, Typography, makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface OwnProps {
  errorMessage: string;
  onReset(): void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    maxWidth: 1050,
    transform: "translateY(calc(50% + 75px)) scale(1)",
  },
  message: {
    fontFamily: "Barlow Condensed",
    fontWeight: 300,
    fontSize: "1em",
    color: theme.palette.error.main,
  },
}));

export const ResponseError: React.FC<OwnProps> = (props) => {
  const { errorMessage, onReset } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Typography
        variant="h2"
        gutterBottom
        color={"primary"}
        className={classes.message}
        style={{
          fontSize: `${Math.max(2, Math.min(3, 3 / (errorMessage.length / 150)))}em`,
        }}
      >
        {errorMessage}
      </Typography>
      <Button variant={"text"} startIcon={<FontAwesomeIcon icon={"redo"} />} onClick={onReset}>
        Try again
      </Button>
    </div>
  );
};
