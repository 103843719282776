import gql from "graphql-tag";
import {
  runQuery,
  RunQueryOptions,
} from "../../../../application/network/run-query";
import { ProfileRecord } from "../../../../application/resources/profile/profile";
import { ProfileFragment } from "../graphql/fragments";

export interface QueryProfileCollectionArgs {
  filter: { key: "search" | keyof ProfileRecord; value: string }[];
}

/**
 * Returns a Query
 *
 * @param fragment - Query fragment containing the data you wish to retrieve
 *
 * @returns DocumentNode containing the query string
 */
const buildQuery = (fragment: string = ProfileFragment) => gql`
  query ProfileCollection {
    ProfileCollection {
      ${fragment}
    } 
  }
`;

/**
 * Returns a GraphQL response expecting the profile collection
 *
 * @param options - Options for creating the GraphQL Query.
 *
 * @returns GraphQL response
 */
export const collectProfiles = async (
  options: RunQueryOptions<QueryProfileCollectionArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<
    QueryProfileCollectionArgs,
    { ProfileCollection: ProfileRecord[] }
  >({
    query,
    variables: options.variables,
  });
};
