import { PageTitles } from "./page-titles";
import * as React from "react";

interface OwnProps {
  title: string;
  subtitle: string;
  actions?: React.ReactNode;
}

export const PageHeader: React.FC<OwnProps> = ({
  title,
  subtitle,
  actions,
}) => {
  return (
    <header
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <PageTitles title={title} subtitle={subtitle} />
      {actions && <div>{actions}</div>}
    </header>
  );
};
