import {
  BaseGraphQLModel,
  GraphQLModel,
} from "../../base/graphql/graphql-model";
import { removeNullValues } from "../../helpers/serialize";
import { v4 as uuid } from "uuid";

interface UserSummary {
  id: string;
  attributes: {
    given_name: string;
    family_name: string;
    email?: string;
  };
}

export interface UserShareSummary {
  id: string;
  attributes: {
    given_name: string;
    family_name: string;
    email?: string;
  };
  permissions: string[];
}

export interface ProjectList {
  projects: ProjectRecord[];
  pagination: {
    start: string;
    end: string;
  };
}

export interface ProjectRecord {
  id: string;
  name: string;
  destination: {
    id: string;
    name: string;
  };
  createdAt: number;
  updatedAt: number;
  updatedBy?: UserSummary;
  owner?: UserSummary;
  opportunity: {
    name: string;
    id: string;
    opportunityNumber: string;
    account: {
      name: string;
    };
  };
  pentairAccountName: string;
  users?: UserShareSummary[];
  solutions?: string[];
}

export interface Project extends GraphQLModel<ProjectRecord> {}

export class DefaultProject
  extends BaseGraphQLModel<ProjectRecord>
  implements Project {
  public typename: string = "Project";

  public defaultValues = {
    id: uuid(),
    name: "Some project",
    destination: { id: "NL", name: "Netherlands" },
    createdAt: Date.now(),
    updatedAt: Date.now(),
    opportunity: {
      name: "",
      id: "",
      opportunityNumber: "",
      account: {
        name: "",
      },
    },
    pentairAccountName: "",
  };

  public serialize = () => {
    const serialized = { ...this.record };

    return removeNullValues(serialized);
  };
}
