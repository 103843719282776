import * as React from "react";
import { Button, Grid } from "@material-ui/core";
import { useObserver } from "mobx-react";
import { Projection } from "../../../../application/resources/projection/projection";
import { PageTitles } from "../../../components/page/page-titles";
import {
  StyleSingleHeader,
  useSingleHeaderStyles,
} from "../../styles-single-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HeaderPropertyValue } from "../../../components/page/header-prop-value";

interface OwnProps extends StyleSingleHeader {
  projection: Projection;
  blockSave: boolean;
  version?: string;
  onSave: () => void;
}

export const ProjectionHeader: React.FC<OwnProps> = (props) => {
  const classes = useSingleHeaderStyles(props);
  const { projection, onSave, version, blockSave } = props;
  const record = projection.record;
  const user =
    record.createdBy?.attributes.given_name.substr(0, 1).toUpperCase() +
    ". " +
    record.createdBy?.attributes.family_name;

  return useObserver(() => (
    <React.Fragment>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={8}>
          <PageTitles title={record.solution.name} subtitle={record.name} />
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }} alignItems="center">
          <Button
            color="primary"
            children={projection.isNew ? "save projection" : "save"}
            disabled={projection.loading || blockSave}
            startIcon={
              projection.loading ? (
                <FontAwesomeIcon icon={["fal", "spinner"]} spin />
              ) : (
                <FontAwesomeIcon icon={["fal", "save"]} />
              )
            }
            onClick={() => onSave()}
          />
        </Grid>
      </Grid>
      <div className={classes.propertyBar}>
        {version && <HeaderPropertyValue property="Revision" value={version} />}

        <HeaderPropertyValue property="Solution" value={record.solution.name} />

        <HeaderPropertyValue property="Designed by" value={user} />

        <HeaderPropertyValue
          property="Approved by"
          value={projection.value("approver").values[0]}
        />

        <HeaderPropertyValue property="Project" value={record.project.name} />
      </div>
    </React.Fragment>
  ));
};
