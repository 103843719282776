import * as React from "react";
import { makeStyles, StyledComponentProps } from "@material-ui/core";
import classnames from "classnames";
import { PentairTheme } from "../../../config/theme/types";

type PageContentVariant =
  | "contained"
  | "centered"
  | "padded"
  | "fullscreen"
  | "fullscreenPaper";

type PageContentColor = "default" | "lightGray" | "transparent";

interface OwnProps
  extends StyledComponentProps<
    | "root"
    | "containedVariant"
    | "paddedVariant"
    | "centeredVariant"
    | "fullscreenVariant"
    | "fullscreenPaperVariant"
  > {
  variant?: PageContentVariant;
  className?: string;
  color?: PageContentColor;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: { display: "flex", flexDirection: "column", flex: 1 },
  centeredVariant: {
    margin: "auto",
    justifyContent: "center",
  },
  fullscreenVariant: {
    paddingTop: theme.spacing(6),
  },
  defaultColor: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.text.primary,
  },
  lightGrayColor: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  transparent: {
    backgroundColor: "none",
    color: theme.palette.text.primary,
  },
}));

export const PageContent: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const { variant, color } = props;
  const variantClass =
    classes[((variant || "contained") + "Variant") as "containedVariant"];
  const colorClass =
    classes[((color || "default") + "Color") as "defaultColor"];

  return (
    <article
      className={classnames(
        props.className,
        classes.root,
        variantClass,
        colorClass
      )}
      style={{ backgroundColor: props.color }}
    >
      {props.children}
    </article>
  );
};
