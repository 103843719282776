import API, { graphqlOperation } from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
import { GraphQLAPI } from "../../base/types";
import { amplify } from "../../../config/amplify";
import { MessageInteractor } from "../../business/interactor/message-interactor";

const generateHeader = async () => {
  try {
    const token = ((await Auth.currentSession()) as any).idToken.jwtToken;
    return { Authorization: token };
  } catch (e) {
    return {};
  }
};

export class DefaultAppsyncApi implements GraphQLAPI {
  constructor(protected _messageInteractor: MessageInteractor) {}

  public fetchv2 = async (
    url: string | undefined,
    querystring: string,
    variables?: any,
    useMock: boolean = false
  ) => {
    let result;

    if (useMock) {
      // result = await FakeAPI.graphql(FakeGraphqlOperation(querystring, variables));
    } else {
      API.configure({
        aws_appsync_graphqlEndpoint: url || amplify.aws_appsync_graphqlEndpoint,
        aws_appsync_region: amplify.aws_appsync_region,
        aws_appsync_authenticationType: amplify.Auth.authenticationFlowType,
        graphql_headers: generateHeader,
      });
      result = await API.graphql(graphqlOperation(querystring, variables));
    }

    return result;
  };

  public fetch = async (
    url: string | undefined,
    querystring: string,
    variables?: any,
    useMock: boolean = false
  ) => {
    let result;

    try {
      if (useMock) {
        // result = await FakeAPI.graphql(FakeGraphqlOperation(querystring, variables));
      } else {
        API.configure({
          aws_appsync_graphqlEndpoint:
            url || amplify.aws_appsync_graphqlEndpoint,
          aws_appsync_region: amplify.aws_appsync_region,
          aws_appsync_authenticationType: amplify.Auth.authenticationFlowType,
          graphql_headers: generateHeader,
        });
        result = await API.graphql(graphqlOperation(querystring, variables));
      }
    } catch (err) {
      this._messageInteractor.createError(
        (err.errors &&
          (err.errors.length === 1
            ? err.errors[0].message
            : err.errors.reduce(
                (msg: string, err: any) => msg + "\n  - " + err.message,
                "Something went wrong while executing a request: "
              ))) ||
          err.toString()
      );
      result = err;
    }
    return result;
  };
}
