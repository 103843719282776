import * as React from "react";
import { Typography, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface OwnProps {
  title?: string;
  subtitle?: string;
  prefix?: string;
  classes?: Partial<Record<"root" | "prefix" | "title" | "subtitle", any>>;
}

const useStyles = (props: OwnProps) =>
  makeStyles((theme: Theme) => ({
    root: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
    },
    prefix: {},
    title: {},
    subtitle: { color: theme.palette.secondary.main, fontWeight: 300 },
  }))(props);

export const PageTitles = (props: OwnProps) => {
  const { title, subtitle } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {title && (
        <Typography variant={"h1"} className={classes.title} color={"primary"}>
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography align="left" variant={"h1"} className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
    </div>
  );
};
