import { Presenter } from "../../../../hooks/use-presenter";
import { GraphQLProvider } from "../../../../application/base/graphql/graphql-provider";
import { Collection } from "../../../../application/base/graphql/graphql-collection";
import { RecordData } from "../../../../application/base/general/store";
import { GraphQLModel } from "../../../../application/base/graphql/graphql-model";

const collections: Record<string, Collection<any>> = {};

export class SelectPresenter<
  TRecord extends RecordData = RecordData,
  TModel extends GraphQLModel<TRecord> = GraphQLModel<TRecord>
> implements Presenter {
  constructor(
    protected _key: string,
    protected _provider: GraphQLProvider<TRecord, TModel>
  ) {}

  public get collection(): Collection<TModel> {
    if (!collections[this._key]) {
      collections[this._key] = this._provider.collect();
    }
    return collections[this._key];
  }

  public loadCollection = () => {
    this.collection.fetch();
  };

  public mount = () => {
    this.loadCollection();
  };

  public unmount = () => {};
}
