import * as React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import {
  PentairBlue,
  PentairBrightBlue,
  PentairLightGray,
} from "../../../config/theme/colors";
import { useObserver } from "mobx-react";
import { UserRecord } from "../../../application/resources/user/user";
import { PentairTheme } from "../../../config/theme/types";
// import { useRoute } from "../../../hooks/use-route";
import { TopbarLogo } from "./topbar-logo";
import { Breadcrumbs } from "./breadcrumbs";

interface OwnProps {
  user: UserRecord | undefined;
  onLogout(e: any): void;
  classes?: Record<"root", string>;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {
    // borderBottom: "2px solid " + PentairLightGray[500],
    flex: 1,
  },
  username: {
    padding: "0 " + theme.spacing(2) + "px",
  },
  shadowAppbar: {
    height: 120,
  },
  quicknav: {
    display: "flex",
    justifyContent: "flex-start",
    ...theme.typography.button,
    fontSize: "1rem",
    fontWeight: "normal",
    fontFamily: theme.typography.h1.fontFamily,
    color: PentairBlue[500],
    padding: 0,
  },
  quicknavLink: {},
  mainnav: {
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  itemArrow: {
    "&::before": {
      zIndex: 3,
      content: "''",
      position: "absolute",
      top: -10,
      right: 0,
      width: 0,
      height: 0,
      borderTop: "24px solid transparent",
      borderLeft: "24px solid #fff",
      borderBottom: "24px solid transparent",
    },
    "&::after": {
      zIndex: 2,
      content: "''",
      position: "absolute",
      top: -10,
      right: -2, // extra space on top to create border
      width: 0,
      height: 0,
      borderTop: "24px solid transparent",
      borderLeft: "24px solid " + PentairLightGray[500],
      borderBottom: "24px solid transparent",
    },
  },
  mainnavLink: {
    ...theme.typography.button,
    fontFamily: theme.typography.h1.fontFamily,
    color: theme.palette.text.primary,
    padding: "0.8rem 0.75rem",
    "&.active": {
      color: PentairBrightBlue[500],
    },
  },
  menu: { marginRight: -theme.spacing(1) },
}));

export const TopBar: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  // const [accountUrl] = useRoute("user.account");

  return useObserver(() => {
    // const { user, onLogout } = props;

    return (
      <AppBar
        className={classes.root}
        elevation={0}
        position="static"
        color="inherit"
      >
        <Toolbar className={classes.quicknav} variant="dense">
          <TopbarLogo appname="X-Flow" />
          <Breadcrumbs />

          <span style={{ alignSelf: "flex-end", marginLeft: "auto" }}>
            <Button variant="text">
              <Typography>
                <strong style={{ fontWeight: 600 }}>
                  <span
                    style={{
                      color: PentairBrightBlue[500],
                      textTransform: "none",
                    }}
                  >
                    Smart, Sustainable Water Solutions. FOR LIFE.
                  </span>
                </strong>
              </Typography>
            </Button>
          </span>
        </Toolbar>
      </AppBar>
    );
  });
};
