import { createMuiTheme } from "@material-ui/core";

import { typography } from "./typography";
import {
  PentairBrightBlue,
  PentairBlue,
  PentairSilverGray,
  PentairGreen,
  PentairWhite,
  PentairBackground,
  PentairDarkGray,
  PentairSoftGray,
  PentairDarkGold,
  PentairRed,
  PentairImpactGreen,
  PentairSeafoamGreen,
  PentairKhaki,
} from "./colors";
import { PentairTheme, PentairThemeOptions } from "./types";
import {
  MuiButton,
  MuiFab,
  MuiIconButton,
  MuiLink,
  MuiSwitch,
} from "./overrides/button";
import {
  MuiExpansionPanel,
  MuiExpansionPanelDetails,
  MuiExpansionPanelSummary,
} from "./overrides/expantion-panel";
import {
  MuiCheckbox,
  MuiFormLabel,
  MuiInput,
  MuiInputBase,
  MuiInputLabel,
  MuiRadio,
  MuiSelect,
} from "./overrides/form";
import { MuiList, MuiListItem, MuiMenuItem } from "./overrides/list";
import {
  MuiTable,
  MuiTableCell,
  MuiTableHead,
  MuiTableRow,
} from "./overrides/table";
import { MuiTooltip } from "./overrides/tooltip";
import { MuiInputAdornment } from "./overrides/adornment";
import { MuiFormHelperText } from "./overrides/form-helper-text";

// import  from "@material-ui/core/styles/createSpacing";

export const theme: PentairTheme = createMuiTheme({
  spacing: 12,
  palette: {
    primary: {
      main: PentairBlue["500"],
    },
    secondary: {
      main: PentairBrightBlue["500"],
    },
    cta: {
      main: PentairGreen["500"],
    },
    white: {
      main: PentairWhite["500"],
    },
    background: {
      default: PentairBackground["500"],
    },
    darkGray: {
      main: PentairDarkGray["500"],
    },
    silverGray: {
      main: PentairSilverGray["500"],
    },
    softGray: {
      main: PentairSoftGray["500"],
    },
    darkGold: {
      main: PentairDarkGold["500"],
    },
    error: {
      main: PentairRed["500"],
    },
    impactGreen: {
      main: PentairImpactGreen["500"],
    },
    seafoamGreen: {
      main: PentairSeafoamGreen["500"],
    },
    green: {
      main: PentairGreen["500"],
    },
    brightBlue: {
      main: PentairBrightBlue["500"],
    },
    blue: {
      main: PentairBlue["500"],
    },
    khaki: {
      main: PentairKhaki["500"],
    },
  },
  typography: {
    fontFamily: "Barlow",
    allVariants: {},
    body1: {
      ...typography("body1"),
    },
    body2: {
      ...typography("body2"),
    },
    h1: {
      ...typography("h1"),
    },
    h2: {
      ...typography("h2"),
    },
    h3: {
      ...typography("h3"),
    },
    h4: {
      ...typography("h4"),
    },
    h5: {
      ...typography("h5"),
    },
    h6: {
      ...typography("h6"),
    },
    h1_condensed: {
      ...typography("h1_condensed"),
    },
    h2_condensed: {
      ...typography("h2_condensed"),
    },
    h3_condensed: {
      ...typography("h3_condensed"),
    },
    h4_condensed: {
      ...typography("h4_condensed"),
    },
    h5_condensed: {
      ...typography("h5_condensed"),
    },
    h6_condensed: {
      ...typography("h6_condensed"),
    },
    button: { ...typography("button") },
  },
  props: {
    MuiCard: {
      elevation: 0,
    },
    MuiButton: {
      variant: "contained",
      color: "primary",
    },
    MuiExpansionPanel: {
      elevation: 0,
    },
    MuiInputLabel: {
      shrink: true,
    },
  },
  overrides: {
    MuiButton,
    MuiIconButton,
    MuiExpansionPanel,
    MuiExpansionPanelSummary,
    MuiExpansionPanelDetails,
    MuiFormLabel,
    MuiInput,
    MuiInputBase,
    MuiFormHelperText,
    MuiInputLabel,
    MuiSelect,
    MuiList,
    MuiListItem,
    MuiMenuItem,
    MuiRadio,
    MuiCheckbox,
    MuiLink,
    MuiTable,
    MuiTableHead,
    MuiTableCell,
    MuiTableRow,
    MuiFab,
    MuiTooltip,
    MuiInputAdornment,
    MuiSwitch,
  },
} as PentairThemeOptions) as PentairTheme;
