import { MenuItem, TextField, TextFieldProps } from "@material-ui/core";
import InfoLabel from "../info-label";
import * as React from "react";
import { useFormValidation } from "../hooks/use-form-validation";
import { useFormChange } from "../hooks/use-form-change";
import { DefaultFormFieldProps } from "../types";
import { CalculationValue } from "../../../../application/resources/technology/technology";

interface OwnProps extends DefaultFormFieldProps<Partial<CalculationValue>> {
  textfieldProps?: TextFieldProps;
  disabled?: boolean;
  items?: (string | number)[];
}

export const FormSelectProjectionCalculationValue: React.FC<OwnProps> = (
  props
) => {
  const calculationValue = props.value || {};
  const items = props.items || calculationValue.recommendedValues || [];

  const { onChange, changed } = useFormChange((e) => {
    calculationValue.values = [e.target.value];
    return calculationValue;
  }, props.onChange);

  const error = useFormValidation(
    props.value,
    props.validations || "required|max:120",
    props.onError
  );

  return (
    <TextField
      select
      disabled={props.disabled}
      required
      name="projection-flow"
      id="projection-flow"
      inputProps={{ maxLength: 121 }}
      value={
        (calculationValue.values && calculationValue.values[0]) ||
        (calculationValue.recommendedValues &&
          calculationValue.recommendedValues[0]) ||
        ""
      }
      onChange={onChange}
      fullWidth
      label={
        <InfoLabel
          title={props.label || calculationValue.name || "Calculation value"}
          description={
            props.description ||
            calculationValue.description ||
            "Calculation value"
          }
        />
      }
      error={changed && error ? true : false}
      helperText={changed && error}
      {...props.textfieldProps}
    >
      {items.map((item) => (
        <MenuItem value={item}>{item}</MenuItem>
      ))}
    </TextField>
  );
};
