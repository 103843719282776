import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifyConfirmSignIn,
  AmplifyForgotPassword,
} from "@aws-amplify/ui-react";
import { useObserver } from "mobx-react";
import * as React from "react";
import { Route } from "react-router-dom";
import { AuthPresenter } from "./auth-presenter";
import { PageLoading } from "../pages/page-loading";
import { CanView } from "./can-view";
import { ErrorUnauthorized } from "../pages/errors/error-unauthorized";
import { FederatedSignIn } from "./auth-federated-signin";
import { useLocation } from "react-router-dom";

import { makePresenter } from "../../application/helpers/make-presenter";
import { makeStyles } from "@material-ui/core";
import { PentairTheme } from "../../config/theme/types";
import { AuthLegalAgreements } from "./auth-legal-agreements";
import { ErrorLegalUnauthorized } from "../pages/errors/error-unauthorized-legal";

let cachedPresenter: AuthPresenter;
const usePresenter = makePresenter(
  ({ interactor, provider }) =>
    (cachedPresenter =
      cachedPresenter ||
      new AuthPresenter(interactor.user, provider.user, provider.userLegal))
);

const RouteContent: React.FC<any> = (props) => {
  const presenter = usePresenter(props);

  return useObserver(() => {
    if (presenter.loading) {
      return <PageLoading message={presenter.loadingMessage} />;
    }

    return (
      <CanView
        permission={props.route.requiredPermissions}
        UnauthorizedComponent={<ErrorUnauthorized />}
      >
        <Route key={props.route.path} {...props} />
      </CanView>
    );
  });
};

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {
    height: " 100vh",
    width: "100vw",
    background: "url(/images/landingpage.jpg)",
    textAlign: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    "& > *": {
      alignSelf: "center",
    },
  },
}));

export const AuthRoute: React.FC<any> = (props) => {
  const classes = useStyles(props);
  const location = useLocation();
  const useXpertLogin: boolean =
    location.pathname === "/xpert-aws-login" ? true : false;
  const [xpertLogin, setXpertLogin] = React.useState<boolean>(useXpertLogin);
  const presenter = usePresenter(props);

  return useObserver(() => {
    if (presenter.currentUser) {
      if (presenter.loading) {
        return <PageLoading message={presenter.loadingMessage} />;
      } else if (presenter.hasAcceptedUserAgreements) {
        return <RouteContent {...props} />;
      } else {
        return (
          <CanView
            permission={"iam.userlegal.get"}
            UnauthorizedComponent={<ErrorLegalUnauthorized />}
          >
            <div className={classes.root}>
              <AuthLegalAgreements />
            </div>
          </CanView>
        );
      }
    }

    if (xpertLogin) {
      return (
        <AmplifyAuthenticator className={classes.root}>
          <div slot="sign-in">
            <AmplifySignIn hideSignUp federated={undefined} />
          </div>

          <div slot="confirm-sign-in" className={classes.root}>
            <AmplifyConfirmSignIn headerText="Confirm sign in" />
          </div>

          <div slot="forgot-password" className={classes.root}>
            <AmplifyForgotPassword headerText="Forgot password" />
          </div>

          {/* <div slot="require-new-password" style={containerStyle}>
        <AmplifyRequireNewPassword headerText="Change your password" />
      </div> */}

          <div
            slot="loading"
            className={classes.root}
            style={{ background: "none" }}
          >
            <PageLoading />
          </div>
        </AmplifyAuthenticator>
      );
    }

    return (
      <AmplifyAuthenticator>
        <div slot="sign-in">
          <FederatedSignIn
            onXpertLogin={() => {
              setXpertLogin(true);
            }}
          />
        </div>

        <div slot="confirm-sign-in" className={classes.root}>
          <AmplifyConfirmSignIn headerText="Confirm sign in" />
        </div>

        <div slot="forgot-password" className={classes.root}>
          <AmplifyForgotPassword headerText="Forgot password" />
        </div>

        {/* <div slot="require-new-password" style={containerStyle}>
          <AmplifyRequireNewPassword headerText="Change your password" />
        </div> */}

        <div
          slot="loading"
          className={classes.root}
          style={{ background: "none" }}
        >
          <PageLoading />
        </div>
      </AmplifyAuthenticator>
    );
  });
};
