import * as React from "react";
import moment from "moment";
import { makeStyles, Typography } from "@material-ui/core";
import { UserRecord } from "../../../../application/resources/user/user";
import { PentairTheme } from "../../../../config/theme/pentair-theme";

interface OwnProps {
  user: Partial<UserRecord>;
  timestamp: number;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  user: {
    color: theme.palette.helperTextGray.main,
  },
}));

export const UpdatedByColumn: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const userAttributes = props.user.attributes || {};
  const username = [
    (userAttributes.given_name || "").charAt(0),
    userAttributes.family_name,
  ].join(". ");

  return (
    <Typography>
      {moment.unix(props.timestamp / 1000).format("DD-MMM-YYYY, HH:mm")}
      <br />
      <i className={classes.user}>{username}</i>
    </Typography>
  );
};
