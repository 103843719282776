import * as React from "react";
import { IconName } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, ListItem, makeStyles, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react";
import { PentairTheme } from "../../../config/theme/types";
import classNames from "classnames";
import { typographyVariants } from "../../../config/theme/typography";

interface OwnProps {
  title?: string;
  active?: boolean;
  icon?: IconName;
  onClick?: () => void;
  disabled?: boolean;
  classes?: Record<
    | "root"
    | "icon"
    | "iconHover"
    | "iconWithTitle"
    | "label"
    | "disabled"
    | "listItem"
    | "listItemSelected",
    string
  >;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    color: theme.palette.silverGray.main,
    textDecoration: "none",
    "&:hover:not($disabled), &.active:not($disabled) ": {
      color: theme.palette.brightBlue.main,

      "& > $listItemRoot": {
        color: theme.palette.brightBlue.main,
        background: "inherit",

        "& > $icon": {
          color: theme.palette.brightBlue.main,
        },
      },
    },
    padding: theme.spacing(1, 2),
    width: "auto",
  },
  listItemRoot: {
    padding: 0,
    color: theme.palette.silverGray.main,
  },
  icon: {
    fontSize: "1.125rem",
    color: theme.palette.silverGray.main,
  },
  iconHover: {
    display: "none",
    color: theme.palette.white.main,
  },
  iconWithTitle: {
    marginRight: theme.spacing(1.5),
  },
  label: {},
  disabled: {
    opacity: 0.5,
  },
  title: { color: theme.palette.silverGray.main },

  listItemSelected: {
    color: theme.palette.brightBlue.main,
    "& > $icon": {
      color: theme.palette.brightBlue.main,
    },
  },
  listItemPrimary: {
    fontWeight: 500,
  },
  button: {
    color: theme.palette.grey[500],
  },
}));

export const Tab: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const { title, icon, active, onClick } = props;

  return useObserver(() => {
    return (
      <div
        className={classNames(classes.root, active && classes.listItemSelected)}
      >
        <ListItem
          onClick={onClick}
          button
          disableGutters
          component={Link}
          className={classNames(
            classes.listItemRoot,
            active && classes.listItemSelected
          )}
        >
          {icon && (
            <FontAwesomeIcon
              className={classNames(
                classes.icon,
                title && classes.iconWithTitle
              )}
              icon={["fal", icon]}
            />
          )}

          {icon && (
            <FontAwesomeIcon
              className={classNames(
                classes.icon,
                classes.iconHover,
                title && classes.iconWithTitle
              )}
              icon={icon}
            />
          )}

          {title && (
            <Typography
              className={classes.label}
              variant={typographyVariants.h6}
              color={"inherit"}
            >
              {title}
            </Typography>
          )}
        </ListItem>
      </div>
    );
  });
};
