import gql from "graphql-tag";
import {
  runQuery,
  RunQueryOptions,
} from "../../../../application/network/run-query";
import { UserFragment } from "../../../../application/resources/user/graphql/fragments";
import { UserRecord } from "../../../../application/resources/user/user";
import { InputUser } from "./create";

interface MutationUpdateUserArgs {
  input: InputUser;
}

const buildQuery = (fragment: string = UserFragment) => gql`
  mutation UpdateUser($input: inputUser!) {
    updateUser(input: $input) {
      ${fragment}
    }
  }
`;

export const updateUser = async (
  options: RunQueryOptions<MutationUpdateUserArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<MutationUpdateUserArgs, { updateUser: UserRecord }>({
    query,
    variables: options.variables,
  });
};
