import { TableCell, TableRow, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react";
import * as React from "react";
import { ProjectionListBySolution } from "../../../../application/resources/projection/projection";
import { typographyVariants } from "../../../../config/theme/typography";
import { useEventHandler } from "../../../../hooks/use-eventmapper";
import { IconButton } from "../../../components/mui-extended/icon-button";
import { TablePresenter } from "../../../components/table/table-presenter";

interface OwnProps {
  item: ProjectionListBySolution;
  presenter: TablePresenter<ProjectionListBySolution>;
}

export const TableRowProjections: React.FC<OwnProps> = (props) => {
  const presenter = props.presenter;
  const [eventHandler] = useEventHandler();
  const item = props.item;
  const [open, setOpen] = React.useState(item.items.length > 1);

  return useObserver(() => {
    return (
      <React.Fragment>
        <TableRow style={{ borderBottom: open ? "none" : "1px solid #eee" }}>
          <TableCell>
            <div style={{ display: "flex", alignItems: "center" }}>
              {item.items.length > 1 && (
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                  icon={open ? "angle-up" : "angle-down"}
                />
              )}

              <Typography
                variant={typographyVariants.h4}
                style={{ paddingLeft: item.items.length > 1 ? "0" : "36px" }}
              >
                {item.name}
              </Typography>
            </div>
          </TableCell>
          <TableCell style={{ borderBottom: "1px solid #eee" }}>
            {item.items[0].name}
          </TableCell>
          <TableCell style={{ borderBottom: "1px solid #eee" }}>
            {item.items[0].version}
          </TableCell>
          <TableCell style={{ borderBottom: "1px solid #eee" }}>
            {item.items[0].createdBy &&
              item.items[0].createdBy.attributes &&
              item.items[0].createdBy.attributes.given_name &&
              item.items[0].createdBy.attributes.family_name &&
              item.items[0].createdBy.attributes.given_name
                .substr(0, 1)
                .toUpperCase() +
                ". " +
                item.items[0].createdBy.attributes.family_name}
          </TableCell>
          <TableCell style={{ borderBottom: "1px solid #eee" }}>
            {item.items[0].approvedBy &&
              item.items[0].approvedBy.attributes &&
              item.items[0].approvedBy.attributes.given_name &&
              item.items[0].approvedBy.attributes.family_name &&
              item.items[0].approvedBy.attributes.given_name
                .substr(0, 1)
                .toUpperCase() +
                ". " +
                item.items[0].approvedBy.attributes.family_name}
          </TableCell>
          <TableCell
            style={{ borderBottom: "1px solid #eee" }}
            padding={"checkbox"}
            children={
              <IconButton
                onClick={eventHandler(
                  presenter.onClone,
                  item.items[0].id || ""
                )}
                icon="clone"
              />
            }
          />
          <TableCell
            style={{ borderBottom: "1px solid #eee" }}
            padding={"checkbox"}
            children={
              <IconButton
                onClick={eventHandler(presenter.onEdit, item.items[0].id || "")}
                icon="arrow-right"
              />
            }
          />
        </TableRow>

        {item.items.map((projectionItem, i) => {
          if (projectionItem.id === item.items[0].id) {
            return;
          }

          return (
            <TableRow
              style={{
                display: open ? "table-row" : "none",
                borderBottom:
                  open && item.items.length - 1 !== i
                    ? "none"
                    : "1px solid #eee",
              }}
            >
              <TableCell></TableCell>
              <TableCell style={{ borderBottom: "1px solid #eee" }}>
                {projectionItem.name}
              </TableCell>
              <TableCell style={{ borderBottom: "1px solid #eee" }}>
                {projectionItem.version}
              </TableCell>
              <TableCell style={{ borderBottom: "1px solid #eee" }}>
                {projectionItem.createdBy &&
                  projectionItem.createdBy.attributes &&
                  projectionItem.createdBy.attributes.given_name &&
                  projectionItem.createdBy.attributes.family_name &&
                  projectionItem.createdBy.attributes.given_name
                    .substr(0, 1)
                    .toUpperCase() +
                    ". " +
                    projectionItem.createdBy.attributes.family_name}
              </TableCell>
              <TableCell style={{ borderBottom: "1px solid #eee" }}>
                {projectionItem.approvedBy &&
                  projectionItem.approvedBy.attributes &&
                  projectionItem.approvedBy.attributes.given_name &&
                  projectionItem.approvedBy.attributes.family_name &&
                  projectionItem.approvedBy.attributes.given_name
                    .substr(0, 1)
                    .toUpperCase() +
                    ". " +
                    projectionItem.approvedBy.attributes.family_name}
              </TableCell>
              <TableCell
                style={{ borderBottom: "1px solid #eee" }}
                padding={"checkbox"}
                children={
                  <IconButton
                    onClick={eventHandler(
                      presenter.onClone,
                      projectionItem.id || ""
                    )}
                    icon="clone"
                  />
                }
              />
              <TableCell
                style={{ borderBottom: "1px solid #eee" }}
                padding={"checkbox"}
                children={
                  <IconButton
                    onClick={eventHandler(
                      presenter.onEdit,
                      projectionItem.id || ""
                    )}
                    icon="arrow-right"
                  />
                }
              />
            </TableRow>
          );
        })}
      </React.Fragment>
    );
  });
};
