import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton as MuiIconButton, makeStyles } from "@material-ui/core";
import { IconButtonProps } from "@material-ui/core/IconButton";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { PentairTheme } from "../../../config/theme/types";

interface OwnProps extends Partial<IconButtonProps> {
  icon: IconName;
  active?: boolean;
  variant?: "default" | "primary" | "secondary";
  onClick?(e: any): void;
  to?: string;
  loading?: boolean;
}

const useStyles = (props: OwnProps) => {
  return makeStyles((theme: PentairTheme) => ({
    root: {
      borderRadius: 0,
      "&:hover": {
        "& $icon": {
          "& >:first-child": {
            opacity: 0,
          },
          "& >:last-child": {
            opacity: 1,
          },
        },
      },
    },
    loading: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    icon: {
      fontSize: 18,
      color: theme.palette.silverGray.main,
      position: "relative",
      "& >:first-child": {
        opacity: 1,
        position: "absolute",
        transform: "translate(-50%, -50%)",
      },
      "& >:last-child": {
        opacity: 0,
        position: "absolute",
        transform: "translate(-50%, -50%)",
      },
      "&$primary": {
        color: "#fff",
      },
    },
    default: {
      color: theme.palette.silverGray.main,
      backgroundColor: "inherit",

      "& $icon": {
        "& >:last-child": {
          color: theme.palette.secondary.main,
        },
      },

      "&:hover": {
        "& $icon": {
          backgroundColor: theme.palette.secondary.main,
        },
      },
    },
    primary: {
      color: "#fff",
      backgroundColor: theme.palette.primary.main,
      "&:hover": {
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
      },
    },
    secondary: {
      color: "#fff",
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        color: "#fff",
        backgroundColor: theme.palette.secondary.main,
      },
    },
  }));
};
export function IconButton(props: OwnProps) {
  const classes = useStyles(props)();
  const variant = props.variant || "default";

  return props.to && !props.loading ? (
    <Link to={props.to || ""} onClick={props.onClick}>
      <MuiIconButton
        {...props}
        className={classNames(
          classes.root,
          // props.active && classes.active,
          classes[variant],
          props.className
        )}
      >
        <div className={classNames(classes.icon, classes[variant])}>
          <FontAwesomeIcon icon={["fal", props.icon]} />
          <FontAwesomeIcon icon={["fas", props.icon]} />
        </div>
      </MuiIconButton>
    </Link>
  ) : (
    <MuiIconButton
      {...props}
      className={classNames(
        classes.root,
        // props.active && classes.active,
        classes[variant],
        props.className
      )}
      classes={{ label: (props.loading && classes.loading) || undefined }}
      onClick={!props.loading ? props.onClick : undefined}
    >
      <div className={classNames(classes.icon, classes[variant])}>
        <FontAwesomeIcon
          icon={["fal", props.loading ? "spinner" : props.icon]}
          spin={props.loading}
        />
        <FontAwesomeIcon
          icon={["fas", props.loading ? "spinner" : props.icon]}
          spin={props.loading}
        />
      </div>
    </MuiIconButton>
  );
}
