import {
  GraphQLModel,
  BaseGraphQLModel,
} from "../../base/graphql/graphql-model";
import { UserProfile, UserProfileRecord } from "../user-profile/user-profile";
import { v4 as uuid } from "uuid";
import { removeNullValues } from "../../helpers/serialize";

export interface UserAttributes {
  email?: string;
  phone_number?: string;
  address?: string;
  birthdate?: string;
  name?: string;
  middle_name?: string;
  family_name?: string;
  gender?: string;
  given_name?: string;
  locale?: string;
  groups?: string[];
  nickname?: string;
  picture?: string;
  preferred_username?: string;
  profile?: string;
  timezone?: string;
  updated_at?: string;
  website?: string;
  email_verified?: boolean;
  country?: string;
  accountName?: string;
  accountOwner?: string;
}

export type UserRecord = {
  id: string;
  username: string;
  created_at: string;
  updated_at: string;
  status: string;
  attributes: UserAttributes;
  login_history?: string;
  profile?: UserProfileRecord;
};

export interface User extends GraphQLModel<UserRecord> {
  profile: UserProfile | undefined;

  can(permissions: string | string[]): boolean;
  changeAttribute(
    key: keyof UserAttributes,
    value: string | boolean | number
  ): void;
}

export class DefaultUser extends BaseGraphQLModel<UserRecord> implements User {
  public profile: UserProfile | undefined = undefined;
  public defaultValues: UserRecord = {
    id: uuid(),
    attributes: {},
    username: "",
    status: "Unknown",
    created_at: "Unknown",
    updated_at: "Unknown",
  };

  public can = (permissions: string | string[]): boolean => {
    if (typeof permissions === "string") {
      permissions = [permissions];
    }
    return !permissions.length ||
      (this.profile && this.profile.checkPermissions(permissions))
      ? true
      : false;
  };

  public changeAttribute = (
    key: keyof UserAttributes,
    value: string | number | boolean
  ) => {
    this.changes.attributes =
      this.record.attributes || this.changes.attributes || {};
    this.changes.attributes[key as string] = value;
  };

  public serialize = () => {
    const record: Partial<UserRecord> = this.record;
    delete record.status;
    delete record.created_at;
    delete record.updated_at;
    delete record.login_history;
    delete record.profile;

    record.attributes = record.attributes || {};

    if (!this.isNew) {
      delete record.attributes.email;
    } else {
      record.username = record.attributes.email || "";
    }

    return removeNullValues(record);
  };
}
