import { Presenter } from "../../../../hooks/use-presenter";
import * as React from "react";
import { observable } from "mobx";

interface ComponentProps {
  img: Array<string>;
  children: (loaded: boolean) => React.ReactNode;
}

export class ProjectionInteractiveImagePresenter implements Presenter {
  public images: Array<string>;
  public children: (loaded: boolean) => React.ReactNode;
  @observable public loaded: boolean = false;

  constructor(protected props: ComponentProps) {
    this.images = props.img;
    this.children = props.children;
  }

  public mount = () => {};

  public unmount = () => {};

  public imageLoaded = (e: any) => {
    this.loaded = true;
  };
}
