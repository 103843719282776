import { createMuiTheme, Theme, ThemeOptions } from "@material-ui/core";
import { pentairThemeOptions } from "./pentair-theme/options";

import {
  Palette,
  PaletteOptions,
  PaletteColor,
  PaletteColorOptions,
} from "@material-ui/core/styles/createPalette";
import {
  Typography,
  TypographyOptions,
  TypographyStyleOptions,
} from "@material-ui/core/styles/createTypography";
import { ComponentsProps } from "@material-ui/core/styles/props";

export interface ConsensedTypography {
  h1_condensed: TypographyStyleOptions;
  h2_condensed: TypographyStyleOptions;
  h3_condensed: TypographyStyleOptions;
  h4_condensed: TypographyStyleOptions;
  h5_condensed: TypographyStyleOptions;
  h6_condensed: TypographyStyleOptions;
}

interface PentairPalette {
  white: PaletteColor;
  darkGray: PaletteColor;
  silverGray: PaletteColor;
  softGray: PaletteColor;
  darkGold: PaletteColor;
  impactGreen: PaletteColor;
  seafoamGreen: PaletteColor;
  green: PaletteColor;
  brightBlue: PaletteColor;
  blue: PaletteColor;
  khaki: PaletteColor;
  cta: PaletteColor;
  helperTextGray: PaletteColor;
}

interface PentairPaletteOptions {
  white: PaletteColorOptions;
  darkGray: PaletteColorOptions;
  silverGray: PaletteColorOptions;
  softGray: PaletteColorOptions;
  darkGold: PaletteColorOptions;
  impactGreen: PaletteColorOptions;
  seafoamGreen: PaletteColorOptions;
  green: PaletteColorOptions;
  brightBlue: PaletteColorOptions;
  blue: PaletteColorOptions;
  khaki: PaletteColorOptions;
  cta: PaletteColorOptions;
  helperTextGray: PaletteColorOptions;
}

export interface PentairTheme extends Theme {
  palette: Palette & PentairPalette;
  props?: ComponentsProps;
  typography: Typography & ConsensedTypography;
}

type ExtendedTypographyOptions = TypographyOptions & ConsensedTypography;

export interface PentairThemeOptions extends ThemeOptions {
  palette: PaletteOptions & PentairPaletteOptions;
  typography:
    | ExtendedTypographyOptions
    | ((palette: Palette) => ExtendedTypographyOptions);
  props?: ComponentsProps;
}

export const pentairTheme: Theme = createMuiTheme(pentairThemeOptions);
