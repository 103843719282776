import gql from "graphql-tag";
import {
  runQuery,
  RunQueryOptions,
} from "../../../../application/network/run-query";
import { UserFragment } from "../../../../application/resources/user/graphql/fragments";
import { UserRecord } from "../../../../application/resources/user/user";

export interface QueryUserCollectionArgs {}

const buildQuery = (fragment: string = UserFragment) => gql`
    query UserCollection {
        UserCollection {
            ${fragment}
        }
    }
`;

export const collectUsers = async (
  options: RunQueryOptions<QueryUserCollectionArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<
    QueryUserCollectionArgs,
    { UserCollection: UserRecord[] }
  >({
    query,
    variables: options.variables,
  });
};
