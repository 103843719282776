export const startSession = `
mutation {
  assistantCreateSession{
    sessionId
    message {
      messageText
      egu
      inputFormat
      skipValue
      isRephrasePrompt
    }
    dialogState
    responseCard{
      version
      contentType
      genericAttachments{
        title
        subTitle
        attachmentLinkUrl
        imageUrl
        buttons {
          text
          value
        }
      }
    }
  }
}
`;

export const sendMessage = `
mutation SendMessage($sessionId:String! $text:String!) {
  assistantPostText(
		sessionId:$sessionId
    text: $text
  ){
    sessionAttributes
    message {
      messageText
      egu
      inputFormat
      skipValue
      isRephrasePrompt
    }
    dialogState
    slotToElicit
    responseCard{
      version
      contentType
      genericAttachments{
        title
        subTitle
        attachmentLinkUrl
        imageUrl
        buttons {
          text
          value
        }
      }
    }
    sessionId
    
  }
}
`;
