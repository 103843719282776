import * as React from "react";
import { useObserver } from "mobx-react";
import { ProjectionPresenter } from "../projection-presenter";
import {
  StyleProjectionPages,
  useProjectionPageStyles,
} from "../styles-projection-pages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@material-ui/core";
import { TableRevisions } from "./projection-revision-table";

interface OwnProps extends StyleProjectionPages {
  presenter: ProjectionPresenter;
}

export const ProjectionHistory: React.FC<OwnProps> = (props) => {
  const classes = useProjectionPageStyles(props);

  return useObserver(() => {
    const presenter = props.presenter;
    const { projection, projectionRevisions, setVersion } = presenter;

    if (!projection) {
      return null;
    }

    return (
      <React.Fragment>
        <div>
          <Grid container spacing={4}>
            <Grid item xs={12} className={classes.section}>
              <div className={classes.titleWrap}>
                <FontAwesomeIcon
                  className={classes.icon}
                  icon={["fal", "history"]}
                />
                <Typography variant="h4">Projection revisions</Typography>
              </div>
              <TableRevisions
                items={projectionRevisions || []}
                onEdit={setVersion}
                currentRevision={presenter.revision}
              />
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  });
};
