import { CalculationValue } from "../technology/technology";
import { CalculationValueOptions } from "../technology/calculation-values";
import {
  GraphQLModel,
  BaseGraphQLModel,
} from "../../base/graphql/graphql-model";
import { computed } from "mobx";
import { removeNullValues } from "../../helpers/serialize";
import { ProjectRecord } from "../project/project";
import { v4 as uuid } from "uuid";
import { SolutionRecord } from "../../../v2/feature/solution/types/record";

interface UserSummary {
  id: string;
  attributes: {
    given_name: string;
    family_name: string;
  };
}

export interface ProjectionRecord {
  id: string;
  name: string;
  project: Partial<ProjectRecord>;
  projectionValues: CalculationValue[];
  version: string;
  status: string;
  type: string;
  code: string;
  country: string;
  company: string;
  pdf: string;
  account: string;
  opportunity: string;
  case: string;
  units: string;
  createdAt: string;
  updatedAt: string;
  createdBy?: UserSummary;
  approvedBy?: UserSummary;
  solution: Partial<SolutionRecord>;
  solutionId: string;
  versions: ProjectionVersions[];
  note?: string;
}

export interface ProjectionVersions {
  isLatest: boolean;
  lastModified: string;
  versionId: string | undefined;
}

export interface ProjectionRevision {
  id: string | undefined;
  isLatest: boolean;
  lastModified: string;
  designedBy: string;
  approvedBy: string;
  note: string;
}

export interface ProjectionListBySolution {
  id: string;
  name: string;
  items: Partial<ProjectionRecord>[];
}

export interface Projection extends GraphQLModel<ProjectionRecord> {
  categorizedValues: Record<string, CalculationValue[]>;
  updateValue(id: string, valueIndex: number, value: string): void;
  value(id: CalculationValueOptions): any;
  valueMap: any;
}

export class DefaultProjection
  extends BaseGraphQLModel<ProjectionRecord>
  implements Projection {
  public typename: string = "Projection";

  public defaultValues = {
    id: uuid(),
    name: "Some projection name",
    project: { id: "some-project-id", name: "Some project name" },
    projectionValues: [],
    version: "0.1",
    status: "Concept",
    type: "",
    code: "",
    country: "US",
    company: "",
    pdf: "",
    account: "",
    opportunity: "",
    case: "",
    design_case: "",
    units: "",
    solution: {},
    versions: [],
    note: "",
    solutionId: "",
    createdAt: "",
    updatedAt: "",
  };

  public value = (id: CalculationValueOptions) => {
    return {
      ...this.valueMap[id],
    };
  };

  public get code() {
    return this.id.substr(0, 8).toUpperCase();
  }

  public updateValue = (id: string, valueIndex: number, value: string) => {
    const calcVal = this.record.projectionValues.find((val) => val.id === id);

    if (calcVal) {
      const values = [...(calcVal.values || [])];
      values[valueIndex] = value;

      calcVal.values = values;
      this.valueMap[id].values = values;
    }
  };

  @computed public get categorizedValues() {
    const values = this.record.projectionValues || [];
    return values.reduce(
      (
        result: Record<string, [CalculationValue]>,
        valueData: CalculationValue
      ) => {
        if (valueData.category) {
          result[valueData.category] = result[valueData.category] || [];
          result[valueData.category].push(valueData);
        }
        return result;
      },
      {}
    );
  }

  @computed public get valueMap() {
    const values = this.record.projectionValues || [];
    return values.reduce((result, valueData: CalculationValue) => {
      if (valueData.id) {
        result[valueData.id] = valueData;
      }
      return result;
    }, {} as Record<CalculationValueOptions, CalculationValue>);
  }

  public serialize = () => {
    const serialized = { ...this.record };
    delete serialized.pdf;
    delete serialized.case;
    delete serialized.createdBy;
    delete serialized.approvedBy;
    delete serialized.versions;

    serialized.solutionId = this.record.solution.id || "";

    return removeNullValues(serialized);
  };
}
