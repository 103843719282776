import * as React from "react";
import { PageHome } from "../pages/page-home";

export class FederatedSignIn extends React.Component<any, any> {
  public loading = false;
  constructor(props: any) {
    super(props);
  }

  public render() {
    return <PageHome />;
  }
}
