import { User, DefaultUser, UserRecord } from "./user";
import {
  GraphQLProvider,
  BaseGraphQLProvider,
} from "../../base/graphql/graphql-provider";
import Auth from "@aws-amplify/auth";
import { graphqlOperation } from "@aws-amplify/api";
import * as Query from "./graphql/queries";
import * as Mutation from "./graphql/mutations";
import { UserProfileProvider } from "../user-profile/user-profile-provider";
import { GraphQLAPI } from "../../base/types";

export interface UserProvider extends GraphQLProvider<UserRecord, User> {
  fetchAuthUser(): Promise<User | undefined>;
}

export class DefaultUserProvider extends BaseGraphQLProvider<UserRecord, User>
  implements UserProvider {
  constructor(
    _api: GraphQLAPI,
    protected _userProfileProvider: UserProfileProvider
  ) {
    super(_api);
  }

  public model = DefaultUser;

  public async fetchAuthUser() {
    try {
      const auth = await Auth.currentSession();
      const currentUser = await Auth.currentAuthenticatedUser();
      const idToken = auth.getIdToken();
      const user: User = this.createInstance({
        id: idToken.payload.sub,
        username: idToken.payload["cognito:username"],
        attributes: { ...idToken.payload, ...currentUser.attributes },
      });

      user.profile = this._userProfileProvider.get(idToken.payload.sub);
      await user.profile.fetch();

      return user;
    } catch (err) {
      // throw new Error(err);
    }
    return undefined;
  }

  protected fetchOperation = (user: User) => {
    return graphqlOperation(Query.User, {
      id: user.record.username || user.record.id,
    });
  };

  protected listOperation = () => {
    return graphqlOperation(Query.UserCollection, {});
  };

  protected createOperation = (user: User) => {
    return graphqlOperation(Mutation.CreateUser, { input: user.serialize() });
  };

  protected updateOperation = (user: User) => {
    return graphqlOperation(Mutation.UpdateUser, {
      input: user.serialize(),
    });
  };

  protected deleteOperation = (user: User) => {
    return graphqlOperation(Mutation.DeleteUser, { id: user.record.username });
  };
}
