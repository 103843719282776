import { Collection } from "../../../application/core/collection";
import { UserRecord } from "../../../application/resources/user/user";
import { collectUsers, QueryUserCollectionArgs } from "./functions/collect";

export class UserCollection extends Collection<UserRecord> {
  public errors: object[] = [];

  constructor(
    protected _fragment: string | undefined,
    protected _setState: (state: any) => void
  ) {
    super(_setState);
  }

  public handleResponse = (
    records: Partial<UserRecord>[] | null | undefined,
    errors: object[] = []
  ) => {
    this.records = (records as any[]) || [];
    this.errors = errors;
    this._changed();
  };

  public fetch = async (variables?: QueryUserCollectionArgs) => {
    const res = await collectUsers({
      variables,
      query: { fragment: this._fragment },
    });
    this.handleResponse(res.data && res.data.UserCollection, res.errors);
  };
}
