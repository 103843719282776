import { useObserver } from "mobx-react";
import React from "react";
import { CanViewPresenter } from "./can-view-presenter";
import { usePresenter } from "../../hooks/use-presenter";

interface OwnProps {
  UnauthorizedComponent?: React.ReactElement;
  roles?: string[];
  permission?: string | string[];
}

export const CanView: React.FC<OwnProps> = ({
  children,
  UnauthorizedComponent,
  roles,
  permission,
}) => {
  const presenter = usePresenter(
    ({ interactor }) => new CanViewPresenter(interactor.user, permission, roles)
  );
  return useObserver(() => {
    if (presenter.allowed) {
      return <>{children}</>;
    }

    return UnauthorizedComponent ? UnauthorizedComponent : null;
  });
};
