import { Presenter } from "../../../hooks/use-presenter";
import { RouteNames } from "../../../config/application";
import { RouteProvider } from "../../../application/business/provider/routes";

export interface MenuItemProps {
  primary: string;
  secondary: string;
  route: RouteNames;
  icon?: string;
}

export interface MenuItem extends MenuItemProps {
  primary: string;
  secondary: string;
  route: RouteNames;
  permissions: string | string[];
  href: string;
}

export class MenuPresenter implements Presenter {
  public items: MenuItem[] = [];

  constructor(
    protected _items: MenuItemProps[],
    protected _routeProvider: RouteProvider<RouteNames>
  ) {
    this.items = this._items.map((cfg) => {
      const route = this._routeProvider.getOptions(cfg.route);
      const href = this._routeProvider.generatePath(cfg.route);

      return {
        ...cfg,
        href,
        permissions: (route && route.requiredPermissions) || [],
      };
    });
  }

  public mount = () => {
    //
  };
  public unmount = () => {};
}
