import { MenuItem, TextField, TextFieldProps } from "@material-ui/core";
import InfoLabel from "../info-label";
import * as React from "react";
import { useFormValidation } from "../hooks/use-form-validation";
import { useFormChange } from "../hooks/use-form-change";
import { DefaultFormFieldProps } from "../types";

interface OwnProps extends DefaultFormFieldProps<string> {
  textfieldProps?: TextFieldProps;
  disabled?: boolean;
}

const permissions = [
  { value: "read", label: "Read only" },
  { value: "read.write", label: "Read / Write" },
];

export const FormSelectSharePermissions: React.FC<OwnProps> = (props) => {
  const error = useFormValidation(
    props.value,
    props.validations || "required",
    props.onError
  );

  const { onChange, changed } = useFormChange(
    (e) => e.target.value,
    props.onChange
  );

  return (
    <TextField
      disabled={props.disabled}
      select
      required
      name="permissions"
      id="permissions"
      value={props.value || ""}
      onChange={onChange}
      fullWidth
      label={
        <InfoLabel
          title={props.label || "Access"}
          description={props.description || "Access level"}
        />
      }
      error={changed && error ? true : false}
      helperText={changed && error}
      {...props.textfieldProps}
    >
      {permissions.map((permission) => (
        <MenuItem value={permission.value}>{permission.label}</MenuItem>
      ))}
    </TextField>
  );
};
