import { observable } from "mobx";
import { ProjectProvider } from "../../../../application/resources/project/project-provider";
import { ProjectionProvider } from "../../../../application/resources/projection/projection-provider";
import { Technology } from "../../../../application/resources/technology/technology";
import { TechnologyProvider } from "../../../../application/resources/technology/technology-provider";
import { Presenter } from "../../../../hooks/use-presenter";

export class AssistantResultsPresenter implements Presenter {
  @observable public solutionId: string;
  @observable public loading = false;
  @observable public project?: string;
  @observable protected _solution: Technology;
  @observable public createPopup: boolean = false;
  @observable public creating: boolean = false;
  @observable public newProjection = {
    name: "",
    project: "",
    solution: "",
  };

  constructor(
    protected redirect: any,
    protected _solutionProvider: TechnologyProvider,
    protected _projectionProvider: ProjectionProvider,
    protected _projectProvider: ProjectProvider
  ) {}

  public mount = async () => {
    this.loading = true;

    this._solution = this._solutionProvider.get(this.solutionId);
    await this._solution.fetch();

    this.loading = false;
  };

  public unmount = async () => {};

  public get solution() {
    return this._solution;
  }

  public selectTechnology = async () => {
    this.newProjection.solution = this.solutionId;

    this.togglePopup();
  };

  public createProjectOnChange = (key: any, value: string) => {
    if (key === "project") this.newProjection[key] = value;
  };

  public saveNewProjection = async () => {
    this.creating = true;
    const project = this._projectProvider.get(this.newProjection.project);
    const solution = this._solution;
    await Promise.all([project.fetch(), solution.fetch()]);

    const projection = this._projectionProvider.create({
      name: this.newProjection.name,
      solution: solution.record,
      project: {
        id: project.id,
        name: project.record.name,
      },
      country: project.record.destination.id,
      projectionValues: solution.record.techValues,
    });

    this.creating = false;

    projection.save().then(() => {
      this.redirect("projection.single", {
        projectid: project.id,
        projectionid: projection.id,
      });
    });
  };

  public togglePopup = () => {
    this.createPopup = this.createPopup ? false : true;
  };
}
