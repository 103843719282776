import gql from "graphql-tag";
import { runQuery, RunQueryOptions } from "../../../../network/run-query";
import { ProjectionFragment } from "../../graphql/fragments";
import { ProjectionRecord } from "../../projection";

interface MutationDeleteProjectionArgs {
  id: string;
}

const buildQuery = (fragment: string = ProjectionFragment) => gql`
  mutation DeleteProjection($id:ID!) {
    deleteProjection(id: $id) {
      ${fragment}
    }
  }
`;

export const deleteProjection = async (
  options: RunQueryOptions<MutationDeleteProjectionArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<
    MutationDeleteProjectionArgs,
    { deleteProjection: ProjectionRecord }
  >({
    query,
    variables: options.variables,
  });
};
