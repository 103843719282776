import { RouteArguments } from "../application/business/provider/routes";
import { RouteNames } from "../config/application";
import { useHistory } from "react-router";
import { application } from "../application/application";

export function useRedirect() {
  const history = useHistory();

  return (routename: RouteNames, args?: RouteArguments) => {
    const provider = application.provider.routes;
    const path = provider.generatePath(routename, args);
    history.push(path);
  };
}
