import * as React from "react";
import { AssistentResponseCard } from "../../../../application/resources/assistant/assistant-response";
import { Button } from "@material-ui/core";

interface OwnProps {
  responseCard: AssistentResponseCard;
  disabled?: boolean;
  onButtonClick?(value: string): void;
}

export const ResponseCard: React.FC<OwnProps> = ({ responseCard, onButtonClick, disabled }) => {
  if (
    !responseCard ||
    !responseCard.genericAttachments ||
    !responseCard.genericAttachments.length
  ) {
    return null;
  }

  return (
    <>
      {responseCard.genericAttachments.map(({ buttons }) => (
        <div>
          {buttons.map((btn) => (
            <Button
              disabled={disabled}
              style={{ margin: 6 }}
              onClick={onButtonClick && (() => onButtonClick(btn.value))}
            >
              {btn.text}
            </Button>
          ))}
        </div>
      ))}
    </>
  );
};
