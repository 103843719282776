import { ProjectionFragment } from "./fragments";

export const Create = `
mutation CreateProjection($input:inputProjection!) {
  createProjection(input:$input) {
    ${ProjectionFragment}
  }
}
`;

export const Update = `
mutation updateProjection($input:inputProjection!) {
  updateProjection(input:$input) {
    ${ProjectionFragment}
  }
}
`;

export const Clone = `
mutation cloneProjection($id:ID!, $name:String) {
  cloneProjection(id: $id, name: $name) {
    ${ProjectionFragment}
  }
}
`;

export const Delete = `
mutation DeleteProjection($id:ID!) {
  deleteProjection(id:$id){
    id
  }
}
`;

export const AddUser = `
mutation ProjectionAddUser($id:ID!, $userID:ID!) {
  projectionAddUser(id:$id, userID: $userID){
    id
  }
}
`;

export const RequestApproval = `
mutation ProjectionRequestApproval($id:ID!) {
  projectionRequestApproval(id:$id){
    id
  }
}
`;
