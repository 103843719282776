import * as React from "react";

import { makeStyles, WithStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { PentairDarkGray } from "../../../config/theme/colors";
import { PentairTheme } from "../../../config/theme/types";

type OwnProps = WithStyles<"root">;

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {
    textAlign: "right",
    color: "#C4BFB6",
    fontSize: "14px",
    padding: theme.spacing(2),
    "& a": {
      color: "#C4BFB6",
      padding: "6px",
      transition: "color 0.2s",
      "&:hover": {
        color: PentairDarkGray[500],
      },
    },
  },
}));

export const PageFooter: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);

  return (
    <footer className={classes.root}>
      ©2019 Pentair plc. All rights reserved.
      <br />
      <Link
        // to={route("app.disclaimer")}
        to={window.location.pathname}
      >
        Disclaimer
      </Link>{" "}
      |{" "}
      <Link
        // to={route("app.terms.service")}
        to={window.location.pathname}
      >
        Terms of service
      </Link>{" "}
      |{" "}
      <a target="_blank" href={"https://www.pentair.com/content/pentair/en/privacy-notice.html"}>
        Privacy policy
      </a>
    </footer>
  );
};
