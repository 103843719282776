import * as React from "react";
import { Profile } from "../../../../application/resources/profile/profile";
import { TableProps } from "../../../components/table/table-helpers";
import { usePresenter } from "../../../../hooks/use-presenter";
import { TablePresenter } from "../../../components/table/table-presenter";
import { useEventHandler } from "../../../../hooks/use-eventmapper";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { IconButton } from "../../../components/mui-extended/icon-button";

interface OwnProps extends TableProps<Profile> {}

export const TableProfiles: React.FC<OwnProps> = (props) => {
  const presenter = usePresenter(
    () =>
      new TablePresenter(
        props.items,
        props.onEdit,
        props.onDelete,
        props.onRowClick,
        undefined,
        props.onSelect
      )
  );

  const [eventHandler] = useEventHandler();

  return (
    <section>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell children={"Name"} />
            <TableCell children={"Description"} />{" "}
            <TableCell padding={"checkbox"} children={""} />
            <TableCell padding={"checkbox"} children={""} />
          </TableRow>
        </TableHead>
        <TableBody>
          {(props.items || []).map((profile) => {
            const record = profile.record;

            return (
              <TableRow key={profile.id}>
                <TableCell children={record.name} />
                <TableCell children={record.description} />
                <TableCell
                  padding={"checkbox"}
                  children={
                    <IconButton
                      onClick={eventHandler(presenter.onEdit, profile.id)}
                      icon="pencil-alt"
                    />
                  }
                />
                <TableCell
                  padding={"checkbox"}
                  children={
                    <IconButton
                      onClick={eventHandler(profile.delete)}
                      icon="trash-alt"
                    />
                  }
                />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </section>
  );
};
