import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import * as React from "react";
import { ProjectRecord } from "../../../../../application/resources/project/project";
import { ProjectionRecord } from "../../../../../application/resources/projection/projection";
import { FormFieldProjectionName } from "../../../../../view/components/form/fields/projection-name";
import { SelectProject } from "../../../../../view/components/form/fields/select-project";
import { SelectSolution } from "../../../../../view/components/form/fields/select-solution";
import { useProjectionPageStyles } from "../../../../../view/pages/projection/styles-projection-pages";

export interface OwnProps {
  onChange: (key: keyof ProjectionRecord) => (value: any) => void;
  data: Partial<FormData>;
  errors?: any;
}

interface FormData {
  project: Partial<ProjectRecord>;
  solution: string;
  name: string;
}

export const FormProjectionDetails: React.FC<OwnProps> = (props) => {
  const classes = useProjectionPageStyles(props);
  const { onChange, data } = props;
  const [validationErrors, setValidationErrors] = React.useState<
    Record<string, string>
  >({});

  const onValidationError = React.useCallback(
    (key: string) => (error: string | false) => {
      if (validationErrors[key] || false !== error) {
        if (error) {
          validationErrors[key] = error;
        } else {
          delete validationErrors[key];
        }

        setValidationErrors({ ...validationErrors });
      }
    },
    [validationErrors, setValidationErrors]
  );

  return (
    <React.Fragment>
      <div>
        <div className={classes.titleWrap}>
          <FontAwesomeIcon
            className={classes.icon}
            icon={["fal", "clipboard-list"]}
          />
          <Typography variant="h4">Case details</Typography>
        </div>
        <form>
          <SelectProject
            value={data.project}
            onChange={onChange("project")}
            onError={onValidationError("project")}
            textfieldProps={{ margin: "normal" }}
          />
          <SelectSolution
            value={data.solution}
            onChange={onChange("solutionId")}
            onError={onValidationError("solutionId")}
            textfieldProps={{ margin: "normal" }}
          />
          <FormFieldProjectionName
            value={data.name}
            onChange={onChange("name")}
            onError={onValidationError("name")}
            textfieldProps={{ margin: "normal" }}
          />
        </form>
      </div>
    </React.Fragment>
  );
};
