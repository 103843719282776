import * as React from "react";
import { useObserver } from "mobx-react";
import { TextField, MenuItem, TextFieldProps } from "@material-ui/core";

export const SelectStatus: React.FC<TextFieldProps> = (props) => {
  return useObserver(() => {
    return (
      <TextField select {...props}>
        <MenuItem value="Concept">Concept</MenuItem>
        <MenuItem value="Approval">Waiting for approval</MenuItem>
        <MenuItem value="Approved">Approved</MenuItem>
      </TextField>
    );
  });
};
