import * as React from "react";
import { Grid } from "@material-ui/core";
import { useUserCollection } from "../../hooks/use-collection";
import { useRedirect } from "../../../../../hooks/use-redirect";
import { useUser } from "../../hooks/use-single";
import { SectionTitle } from "../../../../common/ui/section-title";
import { SearchField } from "../../../project/view/pages/partials/search-field";
import LoadingIcon from "../../../../common/ui/loading-icon";
import { CollectionTable } from "../../../../common/table/table-collection";
import {
  columnCountry,
  columnDate,
  columnEdit,
  columnIcon,
  columnNestedValue,
  columnUserProfile,
  columnUserStatus,
  columnUserWithEmail,
  columnValue,
} from "../../../../common/table/table-columns";

interface OwnProps {}

export const UserIndexTable: React.FC<OwnProps> = (props) => {
  const collection = useUserCollection();
  const redirect = useRedirect();
  const onEdit = React.useCallback((val, item) => {
    redirect("user.edit", { userid: item.id });
  }, []);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const user = useUser();

  const onDelete = React.useCallback((userId: string) => {
    user.delete(userId).then(() => {
      collection.fetch();
    });
  }, []);

  React.useEffect(() => {
    setLoading(true);
    collection.fetch().then(() => {
      setLoading(false);
    });
  }, [collection, searchQuery]);

  return (
    <section>
      <Grid
        container
        spacing={2}
        justify={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={6}>
          <SectionTitle title="User overview" icon="table" />
        </Grid>
        <Grid item xs={2}>
          <SearchField passedValue={searchQuery} onChange={setSearchQuery} />
        </Grid>
        <Grid item xs={12}>
          {(loading && <LoadingIcon />) || (
            <CollectionTable
              sortKey={"updatedAt"}
              sortDirection={"desc"}
              items={collection.records}
              columns={{
                name: columnUserWithEmail("User"),
                accOwner: columnNestedValue(
                  "Account",
                  "attributes.accountName"
                ),
                accManager: columnNestedValue(
                  "Account Manager",
                  "attributes.accountOwner"
                ),
                country: columnCountry("Country"),
                profile: columnUserProfile("Profile"),
                status: columnUserStatus("Status"),
                created_at: columnDate("Created at"),
                enabled: columnValue("Enabled"),
                edit: columnEdit(onEdit),
                id: columnIcon("trash-alt", onDelete),
              }}
            />
          )}
        </Grid>
      </Grid>
    </section>
  );
};
