import * as React from "react";
import { Typography, ListItem, ListItemText, List, makeStyles } from "@material-ui/core";

import { Link } from "react-router-dom";
import { I18n } from "aws-amplify";
import { PentairTheme } from "../../../config/theme/types";
import { CanView } from "../../auth/can-view";
import { MenuPresenter, MenuItemProps } from "./menu-presenter";
import { usePresenter } from "../../../hooks/use-presenter";

interface OwnProps {
  title: string;
  items: MenuItemProps[];
  classes?: Record<"root" | "title" | "listItem" | "listItemSelected" | "listItemPrimary", string>;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {},
  title: { color: theme.palette.primary.main },
  listItem: {
    "&:hover": {
      backgroundColor: "transparent !important",
      color: theme.palette.secondary.main,
    },
  },
  listItemSelected: {
    backgroundColor: "transparent !important",
    color: theme.palette.secondary.main,
  },
  listItemPrimary: {
    fontWeight: 500,
  },
}));

export const Menu: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const presenter = usePresenter(({ provider }) => new MenuPresenter(props.items, provider.routes));

  return (
    <div className={classes.root}>
      <Typography paragraph variant="h4" className={classes.title}>
        {props.title}
      </Typography>
      <List>
        {presenter.items.map((item) => {
          const selected = item.href === window.location.pathname;

          return (
            <CanView key={item.route} permission={item.permissions}>
              <ListItem
                button
                divider
                disableGutters
                component={Link}
                to={item.href}
                selected={selected}
                className={classes.listItem}
                classes={{
                  selected: classes.listItemSelected,
                }}
              >
                <ListItemText
                  classes={{ primary: classes.listItemPrimary }}
                  primary={I18n.get(item.primary)}
                  secondary={I18n.get(item.secondary)}
                ></ListItemText>
              </ListItem>
            </CanView>
          );
        })}
      </List>
    </div>
  );
};
