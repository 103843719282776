import { ProjectionRecord } from "../../../../../application/resources/projection/projection";
import { CalculationValue } from "../../../solution/types/record";
import * as React from "react";
import { useProjectionPageStyles } from "../../../../../view/pages/projection/styles-projection-pages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@material-ui/core";
import { FormFieldProjectionCalculationValue } from "../../../../../view/components/form/fields/projection-calculation-value";
import { FormSelectProjectionCalculationValue } from "../../../../../view/components/form/fields/select-projection-calculation-value";

export interface OwnProps {
  onChange: (key: keyof ProjectionRecord) => (value: any) => void;
  data: CalculationValue[];
  errors?: any;
}

export const FormProjectionInputsCategory: React.FC<OwnProps> = (props) => {
  const classes = useProjectionPageStyles(props);
  const { onChange, data } = props;
  const [validationErrors, setValidationErrors] = React.useState<
    Record<string, string>
  >({});

  const onValidationError = React.useCallback(
    (key: string) => (error: string | false) => {
      if (validationErrors[key] || false !== error) {
        if (error) {
          validationErrors[key] = error;
        } else {
          delete validationErrors[key];
        }

        setValidationErrors({ ...validationErrors });
      }
    },
    [validationErrors, setValidationErrors]
  );

  return (
    <React.Fragment>
      <div>
        <div className={classes.titleWrap}>
          <FontAwesomeIcon
            className={classes.icon}
            icon={["fal", "clipboard-list"]}
          />
          <Typography variant="h4">Flow inputs</Typography>
        </div>
        <form>
          {data.length &&
            data.map((input) => {
              if ((input.valueOptions || []).length > 1) {
                return (
                  <FormSelectProjectionCalculationValue
                    value={input}
                    onChange={onChange("projectionValues")}
                    onError={onValidationError("projectionValues")}
                    textfieldProps={{ margin: "normal" }}
                  />
                );
              } else {
                return (
                  <FormFieldProjectionCalculationValue
                    value={input}
                    onChange={onChange("projectionValues")}
                    onError={onValidationError("projectionValues")}
                    textfieldProps={{ margin: "normal" }}
                  />
                );
              }
            })}
        </form>
      </div>
    </React.Fragment>
  );
};
