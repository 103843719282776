import * as React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import classnames from "classnames";
import { MessageType } from "../../../application/business/interactor/message-interactor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface OwnProps {
  type?: MessageType;
  show?: boolean;
  onClose?(): void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(0.25, 0),
    color: "#fff",
    display: "flex",
    "&:first-child": {
      marginTop: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  text: { padding: theme.spacing(1.125, 1.125, 1.125, 0), flex: 1 },
  strong: { fontWeight: 600 },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.text.primary,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
}));

export const Message: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const type = props.type || MessageType.Info;

  if (!props.show) {
    return null;
  }

  return (
    <div className={classnames(classes.root, classes[type])}>
      {type === MessageType.Info && (
        <>
          <div className={classes.icon}>
            <FontAwesomeIcon icon={["fas", "info-circle"]} />
          </div>
          <div className={classes.text}>
            <Typography>
              <strong className={classes.strong}>Info: </strong>
              {props.children}
            </Typography>
          </div>
        </>
      )}

      {type === MessageType.Warning && (
        <>
          <div className={classes.icon}>
            <FontAwesomeIcon icon={["fas", "exclamation-circle"]} />
          </div>
          <div className={classes.text}>
            <Typography>
              <strong className={classes.strong}>Warning: </strong>
              {props.children}
            </Typography>
          </div>
        </>
      )}

      {type === MessageType.Error && (
        <>
          <div className={classes.icon}>
            <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />
          </div>
          <div className={classes.text}>
            <Typography>
              <strong className={classes.strong}>Error: </strong>
              {props.children}
            </Typography>
          </div>
        </>
      )}

      <div className={classes.icon} onClick={props.onClose}>
        <FontAwesomeIcon icon={["fal", "times"]} />
      </div>
    </div>
  );
};
