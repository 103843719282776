import { Presenter } from "../../../hooks/use-presenter";
import { observable, computed } from "mobx";
import { Profile } from "../../../application/resources/profile/profile";
import { ProfileProvider } from "../../../application/resources/profile/profile-provider";
import { UserInteractor } from "../../../application/resources/user/user-interactor";
import { Collection } from "../../../application/base/graphql/graphql-collection";

let profiles: Collection<Profile>;

export class PageProfileIndexPresenter implements Presenter {
  @observable public createActive: boolean = false;

  constructor(
    protected redirect: any,
    protected _userInteractor: UserInteractor,
    protected _profileProvider: ProfileProvider
  ) {
    profiles = profiles || this._profileProvider.collect();
  }

  public mount = async () => {
    if (!profiles.loaded) {
      await profiles.fetch();
    }
  };

  public unmount = () => {
    //
  };

  @computed public get profiles() {
    return profiles.items;
  }

  @computed public get collectionLoading() {
    return profiles.loading;
  }

  createProfile = () => {
    this.redirect("profile.create");
  };

  editProfile = (profile: any) => {
    this.redirect("profile.edit", { profile });
  };

  public deleteProfile = (id: string) => {
    const profile = this._profileProvider.get(id);
    profile.delete();
  };

  public toggleCreate = () => {};
}
