import { TextField, TextFieldProps } from "@material-ui/core";
import InfoLabel from "../info-label";
import * as React from "react";
import { useFormValidation } from "../hooks/use-form-validation";
import { useFormChange } from "../hooks/use-form-change";
import { DefaultFormFieldProps } from "../types";

interface OwnProps extends DefaultFormFieldProps<string> {
  textfieldProps?: TextFieldProps;
}

export const FormFieldSalesforceUsername: React.FC<OwnProps> = (props) => {
  const error = useFormValidation(
    props.value,
    props.validations || "required",
    props.onError
  );

  const { onChange, changed } = useFormChange(
    (e) => e.target.value,
    props.onChange
  );

  return (
    <TextField
      required
      name="salesforce-username"
      id="salesforce-username"
      inputProps={{ maxLength: 255 }}
      value={props.value || ""}
      placeholder="Unchanged"
      onChange={onChange}
      fullWidth
      label={
        <InfoLabel
          title={props.label || "Salesforce username"}
          description={
            props.description || "Salesforce username for the API connection"
          }
        />
      }
      error={changed && error ? true : false}
      helperText={changed && error}
      {...props.textfieldProps}
    />
  );
};
