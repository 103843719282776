import * as React from "react";
import { useObserver } from "mobx-react";
import { StyleTabs, useTabsStyles } from "../../styles-tabs";
import { Tab } from "../../../interface/partials/single-tab";

interface OwnProps extends StyleTabs {
  setActiveTab: (tab: string) => void;
  activeTab?: string;
}

export const TabsProjectionSingle: React.FC<OwnProps> = (props) => {
  const classes = useTabsStyles(props);
  const { activeTab, setActiveTab } = props;

  return useObserver(() => {
    return (
      <div className={classes.root}>
        <Tab
          title="Inputs"
          icon="sliders-h"
          active={activeTab === "inputs" || activeTab === undefined}
          onClick={() => setActiveTab("inputs")}
        />
        <Tab
          title="Results"
          icon="clipboard-check"
          active={activeTab === "results"}
          onClick={() => setActiveTab("results")}
        />
        <Tab
          title="History"
          icon="history"
          active={activeTab === "history"}
          onClick={() => setActiveTab("history")}
        />
      </div>
    );
  });
};
