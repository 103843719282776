import { Collection } from "../../../core/collection";
import { ProjectionRecord } from "../projection";
import { collectProjections } from "./functions/collect";
import { QueryProjectionCollectionArgs } from "./functions/collect";

export class ProjectionCollection extends Collection<ProjectionRecord> {
  public errors: object[] = [];

  constructor(
    protected _fragment: string | undefined,
    protected _setState: (state: any) => void
  ) {
    super(_setState);
  }

  public handleResponse = (
    records: Partial<ProjectionRecord>[] | null | undefined,
    errors: object[] = []
  ) => {
    this.records = (records as any[]) || [];
    this.errors = errors;
    this._changed();
  };

  public fetch = async (variables?: QueryProjectionCollectionArgs) => {
    console.log(variables);
    const res = await collectProjections({
      variables,
      query: { fragment: this._fragment },
    });
    this.handleResponse(
      res.data && res.data.ProjectionCollection.projections,
      res.errors
    );
  };
}
