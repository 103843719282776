import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { useObserver } from "mobx-react";
import * as React from "react";
import { typographyVariants } from "../../../../config/theme/typography";
import { usePresenter } from "../../../../hooks/use-presenter";
import {
  FormProps,
  FormPresenter,
} from "../../../components/form/form-presenter";
import InfoLabel from "../../../components/form/info-label";
import { PageTitles } from "../../../components/page/page-titles";
import { useEventHandler } from "../../../../hooks/use-eventmapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useProjectionSingleStyles } from "../styles-projection-single";

type FormData = {
  note?: string;
};

interface OwnProps extends FormProps<FormData> {
  active: boolean;
  saving: boolean;
  abort: () => void;
  classes?: Record<"root", string>;
}

export const ProjectionPaperSave: React.FC<OwnProps> = (props) => {
  const classes = useProjectionSingleStyles(props);
  const [eventHandler, valueGetter] = useEventHandler();
  const presenter = usePresenter(
    ({ utils }) => new FormPresenter<FormData>(props, utils.validator)
  );

  let characterCount = 0;

  return useObserver(() => {
    const { active, abort, saving } = props;
    const { data, change, errors, submit } = presenter;

    if (data.note) {
      characterCount = data.note.length;
    }

    if (!active) {
      return null;
    }

    return (
      <div className={classes.paperpopupWrap}>
        <div className={classes.clickArea} onClick={() => abort()} />
        <Paper className={classes.paperpopup} square>
          <Grid container spacing={1} justify="flex-end">
            <Grid item xs={12}>
              <PageTitles title="projection" subtitle="revision notes" />
              <Typography variant={typographyVariants.body2}>
                Add revision notes for the future reference
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                margin="normal"
                placeholder="E.g. Optimized the main operational settings"
                onChange={eventHandler(change, "note", valueGetter)}
                value={data.note}
                id="note"
                inputProps={{ maxLength: 255 }}
                label={
                  <InfoLabel
                    title="notes"
                    description="Please inform us of changes that you made."
                  />
                }
                multiline
                rows={6}
                rowsMax={6}
                error={errors.note ? true : false}
              />
              {data.note && data.note.length && (
                <Typography>
                  Characters remaining: {255 - characterCount}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right", paddingTop: "0" }}>
              <Button
                style={{ width: "100%" }}
                color="primary"
                children="Confirm save"
                disabled={saving || (characterCount <= 24 ? true : false)}
                startIcon={
                  saving ? (
                    <FontAwesomeIcon icon={["fal", "spinner"]} spin />
                  ) : (
                    <FontAwesomeIcon icon={["fal", "check"]} />
                  )
                }
                onClick={() => submit()}
              />
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right", paddingTop: "0" }}>
              <Button
                style={{ width: "100%" }}
                color="default"
                children="cancel"
                disabled={saving}
                startIcon={<FontAwesomeIcon icon={["fal", "times"]} />}
                onClick={() => abort()}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  });
};
