import { useCallback, useState } from "react";

export const useSorting = (
  list: any[],
  defaultSortKey?: string,
  defaultSortDirection: "asc" | "desc" = "asc"
) => {
  const [sort, setSort] = useState({
    sortKey: defaultSortKey,
    sortDirection: defaultSortDirection,
  });
  const { sortKey, sortDirection } = sort;

  const sortFunc = useCallback(
    (a: any, b: any) => {
      a = a.parsed || a;
      b = b.parsed || b;
      if (!sortKey) {
        return -1;
      }

      if (sortDirection === "asc") {
        if (typeof a[sortKey] === "string" || typeof a[sortKey] === "boolean") {
          return a[sortKey].toString().localeCompare(b[sortKey.toString()]);
        }

        return a[sortKey] - b[sortKey];
      }

      if (sortDirection === "desc") {
        if (typeof b[sortKey] === "string" || typeof b[sortKey] === "boolean") {
          return b[sortKey].toString().localeCompare(a[sortKey].toString());
        }

        return b[sortKey] - a[sortKey];
      }
    },
    [sortKey, sortDirection]
  );

  list.sort(sortFunc);

  return {
    sort,
    setSort,
  };
};
