import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

interface OwnProps {
  children: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}));

export default function WidgetContent({ children }: OwnProps) {
  const classes = useStyles();
  return <section className={classes.root}>{children}</section>;
}
