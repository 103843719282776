import * as React from "react";
import { Link, Button, Typography } from "@material-ui/core";
import { TableColumnProps } from "./table-column";
import { UserRecord } from "../../../application/resources/user/user";
import { UpdatedByColumn } from "./partials/updated-by";
import { IconButton } from "../mui-extended/icon-button";
import { IconName } from "@fortawesome/pro-light-svg-icons";
import { UserEmailColumn } from "./partials/user-email";
import { UserStatusColumn } from "./partials/user-status";
import moment from "moment";
import { getName } from "country-list";
import { UserProfileColumn } from "./partials/user-profile";
import { get } from "lodash";

export const columnValue = (label: React.ReactNode): TableColumnProps => {
  return {
    label,
    sortable: true,
  };
};

export const columnNestedValue = (
  label: React.ReactNode,
  valueKey: string
): TableColumnProps => {
  return {
    label,
    sortable: false,
    value: (value, item) => {
      return get(item, valueKey, "");
    },
    render: (value, item) => {
      return <Typography>{get(item, valueKey, "")}</Typography>;
    },
  };
};

export const columnLink = (
  label: React.ReactNode,
  onClick?: (val: any, item: any) => void
): TableColumnProps => {
  return {
    label,
    sortable: true,
    render: (value, item) => {
      return (
        <Link
          style={{ cursor: "pointer" }}
          onClick={onClick && (() => onClick(value, item))}
        >
          {value}
        </Link>
      );
    },
  };
};

export const columnButton = (
  label: React.ReactNode,
  value: string,
  onClick?: (val: any, item: any) => void
): TableColumnProps => {
  return {
    label,
    render: (val, item) => {
      return (
        <Button onClick={onClick && (() => onClick(value, item))}>
          {value || val}
        </Button>
      );
    },
  };
};

export const columnIcon = (
  icon: IconName,
  onClick?: (val: any, item: any) => void
): TableColumnProps => {
  return {
    tableCellProps: { padding: "checkbox" },
    label: <IconButton icon={icon} disabled />,
    render: (value, item) => (
      <IconButton
        icon={icon}
        onClick={onClick && (() => onClick(value, item))}
      />
    ),
  };
};

export const columnUser = (label: string): TableColumnProps => {
  return {
    label,
    sortable: true,
    value: (user: UserRecord) =>
      (user &&
        user.attributes &&
        [user.attributes.given_name, user.attributes.family_name].join(" ")) ||
      "",
  };
};

export const columnDate = (label: string): TableColumnProps => {
  return {
    label,
    sortable: true,
    render: (value: number) =>
      moment.unix(value / 1000).format("DD-MMM-YYYY, HH:mm"),
  };
};

export const columnDelete = (
  onDelete: (val: any, item: any) => void
): TableColumnProps => {
  return {
    ...columnIcon("trash"),
    render: (value, item) => (
      <IconButton icon="trash" onClick={() => onDelete(value, item)} />
    ),
  };
};

export const columnEdit = (
  onClick: (val: any, item: any) => void
): TableColumnProps => {
  return {
    ...columnIcon("chevron-right"),
    label: undefined,
    render: (value, item) => (
      <IconButton onClick={() => onClick(value, item)} icon="chevron-right" />
    ),
  };
};

export const columnProjectCountry = (label: string): TableColumnProps => {
  return {
    label,
    sortable: true,
    value: (value: any) => value.name,
    render: (value: any) => value.name,
  };
};

export const columnCountry = (label: string): TableColumnProps => {
  return {
    label,
    sortable: true,
    render: (value: any, item: any) =>
      getName(item.attributes.country || "") || "",
  };
};

export const columnUpdatedBy = (label: string): TableColumnProps => {
  return {
    label,
    sortable: true,
    value: (value: any, item: any) => item.updatedAt,
    render: (value: UserRecord, item: any) => {
      return (
        <UpdatedByColumn user={item.updatedBy} timestamp={item.updatedAt} />
      );
    },
  };
};

export const columnUserWithEmail = (label: string): TableColumnProps => {
  return {
    label,
    sortable: true,
    value: (value: any, item: any) =>
      [item.attributes.given_name, item.attributes.last_name].join(" "),
    render: (value: UserRecord, item: any) => {
      return <UserEmailColumn user={item} />;
    },
  };
};

export const columnUserStatus = (label: string): TableColumnProps => {
  return {
    label,
    sortable: true,
    value: (value: any, item: any) =>
      [item.status, item[item.length - 1]].join(" "),
    render: (value: UserRecord, item: any) => {
      return <UserStatusColumn user={item} />;
    },
  };
};

export const columnUserProfile = (label: string): TableColumnProps => {
  return {
    label,
    sortable: false,
    value: (value: any, item: any) => item.id,
    render: (value: UserRecord, item: any) => {
      return <UserProfileColumn user={item} />;
    },
  };
};
