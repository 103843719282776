import gql from "graphql-tag";
import { runQuery, RunQueryOptions } from "../../../network/run-query";
import { SalesForceConfigFragment } from "../graphql/fragment";
import { SalesForceConfigRecord } from "../single";

interface MutationDeleteSalesForceConfigArgs {
  id: string;
}

const buildQuery = (fragment: string = SalesForceConfigFragment) => gql`
  mutation DeleteSalesForceConfig() {
    deleteSalesForceConfig() {
      ${fragment}
    }
  }
`;

export const deleteSalesForceConfig = async (
  options: RunQueryOptions<MutationDeleteSalesForceConfigArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<
    MutationDeleteSalesForceConfigArgs,
    { deleteSalesForceConfig: SalesForceConfigRecord }
  >({
    query,
    variables: options.variables,
  });
};
