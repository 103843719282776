import { Presenter } from "../../../hooks/use-presenter";
import { Permission } from "../../../application/resources/permission/permission";
import { observable, computed } from "mobx";
import { Profile, ProfileRecord } from "../../../application/resources/profile/profile";
import { PermissionProvider } from "../../../application/resources/permission/permission-provider";
import { UserInteractor } from "../../../application/resources/user/user-interactor";
import { ProfileProvider } from "../../../application/resources/profile/profile-provider";
import { Collection } from "../../../application/base/graphql/graphql-collection";

interface ComponentProps {
  routeParams: { profile: string };
}

export interface EditablePermission {
  id: string;
  checked: boolean;
  permission: Permission;
}

export interface PermissionSetProps {
  id: string;
  checked: { all: boolean; partial: boolean };
  name: string;
  permissions: EditablePermission[];
}

let permissions: Collection<Permission>;

export class PageProfileSinglePresenter implements Presenter {
  private _profileId: string;
  @observable public profile: Profile;
  @observable public loading: boolean = false;
  @observable public loadingMessage: string;
  @observable private _permissionSets: Record<string, PermissionSetProps> = {};

  constructor(
    protected props: ComponentProps,
    protected back: any,
    protected _profileProvider: ProfileProvider,
    protected _permissionProvider: PermissionProvider,
    protected _userInteractor: UserInteractor
  ) {
    this._profileId = props.routeParams.profile;
    if (this._profileId) {
      this.profile = this._profileProvider.get(this.props.routeParams.profile || "");
    } else {
      this.profile = this._profileProvider.create();
    }
    permissions = permissions || this._permissionProvider.collect();
    this.generatePermissionSets();
  }

  @computed public get permissionSets(): PermissionSetProps[] {
    return Object.keys(this._permissionSets).map((key) => this._permissionSets[key]);
  }

  public mount = async () => {
    this.loading = true;

    if (!this.profile.fetched && !this.profile.isNew) {
      this.loadingMessage = "fetching profile";
      await this.profile.fetch();
    }

    if (!permissions.loaded) {
      this.loadingMessage = "fetching permissions";
      await permissions.fetch();
    }

    this.generatePermissionSets();
    this.loading = false;
  };

  @computed public get permissions() {
    return permissions.items;
  }

  public unmount = async () => {};

  public generatePermissionSets(): void {
    this._permissionSets = this.permissions.reduce((sets, permission) => {
      const key = (permission.id || "").split(".")[0];
      sets[key] = sets[key] || {
        id: key,
        get checked() {
          const result = this.permissions.reduce(
            (checked: { all: boolean; partial: boolean }, permission: any) => {
              checked.partial = checked.partial || permission.checked;
              checked.all = checked.all && permission.checked;
              return checked;
            },
            { all: true, partial: false }
          );
          return result;
        },
        name: "Xpert " + permission.record.resolver,
        permissions: [],
      };

      sets[key].permissions.push({
        id: permission.id,
        checked: this.isSelected(permission),
        permission,
      });

      return sets;
    }, {});
  }

  public toggleAll = (set: PermissionSetProps) => {
    const checked = set.checked.partial;
    set.permissions.forEach((permission) => {
      permission.checked = !checked;
    });
  };

  public togglePermission = (editable: EditablePermission) => {
    editable.checked = !editable.checked;
  };

  public updateProfile = async () => {
    const allowedPermissions = this.permissionSets.reduce((permissions: string[], set) => {
      if (set.checked.all) {
        permissions.push(set.id + ".*");
      } else if (set.checked.partial) {
        permissions.push(...set.permissions.filter((p) => p.checked).map((p) => p.id));
      }
      return permissions;
    }, []);
    this.profile.changePermission("allow", allowedPermissions);

    await this.profile.save();
    this.back();
  };

  public isSelected = (permission: Permission) => {
    const currentPermissions = this.profile.record.permissions.allow;

    return currentPermissions.reduce((checked, perm: string) => {
      return checked || perm === "*" || permission.id.startsWith(perm.replace(".*", ""));
    }, false);
  };

  public changeProperty = (key: keyof ProfileRecord, value: string | number | boolean) => {
    this.profile.changes = this.profile.record || this.profile.changes || {};
    this.profile.changes[key as string] = value;
  };
}
