// import { Design } from "../design/design";
import { observable } from "mobx";
import { User } from "./user";
import Auth from "@aws-amplify/auth";

export interface UserInteractor {
  currentUser: User;
  legalAgreements: boolean;
  logout: () => void;
}

export default class DefaultUserInteractor implements UserInteractor {
  @observable public _currentUser: User | null = null;
  @observable public _legalAgreements: boolean = false;

  public get currentUser(): User {
    if (!this._currentUser) {
      throw new Error("User not set");
    }
    return this._currentUser;
  }

  public get legalAgreements(): boolean {
    return this._legalAgreements;
  }

  public set currentUser(user: User) {
    this._currentUser = user;
  }

  public set legalAgreements(boolean: boolean) {
    this._legalAgreements = boolean;
  }

  public logout = async () => {
    await Auth.signOut();
    window.location.reload();
  };
}
