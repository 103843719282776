import { makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { UserRecord } from "../../../../application/resources/user/user";
import { PentairTheme } from "../../../../config/theme/pentair-theme";

interface OwnProps {
  user: Partial<UserRecord>;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  email: {
    color: theme.palette.helperTextGray.main,
  },
}));

export const UserEmailColumn: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const userAttributes = props.user.attributes || {};

  return (
    <Typography>
      {[userAttributes.given_name, userAttributes.family_name].join(" ")}
      <br />
      <i className={classes.email}>{userAttributes.email}</i>
    </Typography>
  );
};
