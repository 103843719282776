import gql from "graphql-tag";
import {
  runQuery,
  RunQueryOptions,
} from "../../../../application/network/run-query";
import { ProjectRecord } from "../../../../application/resources/project/project";
import { ProjectIndexFragment } from "../graphql/fragments";

export interface QueryProjectCollectionArgs {
  filters?: { operator: string; property: string; value: string }[];
  pagination?: { start: string; limit: string }[];
  searchterm?: string;
}

/**
 * Returns a Query
 *
 * @param fragment - Query fragment containing the data you wish to retrieve
 *
 * @returns DocumentNode containing the query string
 */
const buildQuery = (fragment: string = ProjectIndexFragment) => gql`
  query ProjectCollection($filters: [inputFilterOptions], $pagination: inputPaginationOptions, $searchterm: String) {
    ProjectCollection(filters: $filters, pagination: $pagination, searchterm: $searchterm) {
      projects {
        ${fragment}
      }
    }
  }
`;

/**
 * Returns a GraphQL result expecting a ProjectCollection
 *
 * @param options - Options for creating the GraphQL Query.
 *
 * @returns GraphQL response
 */
export const collectProjects = async (
  options: RunQueryOptions<QueryProjectCollectionArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<
    QueryProjectCollectionArgs,
    {
      ProjectCollection: {
        projects: ProjectRecord[];
        pagination: {
          start: string;
          end: string;
        };
      };
    }
  >({
    query,
    variables: options.variables,
  });
};
