import * as React from "react";
import { Location } from "history";
import { Prompt } from "react-router";
import { WarningDialog } from "../../interface/dialogs/warning-dialog";
import { IconName } from "@fortawesome/pro-light-svg-icons";

export interface ButtonConfig {
  buttonText: string;
  buttonType: "inherit" | "primary" | "secondary" | "default" | undefined;
  buttonIcon: IconName;
  buttonAction: () => void;
  buttonDisable: boolean;
}

interface OwnProps {
  when?: boolean | undefined;
  titleText: string;
  subTitleText: string;
  contentText: React.ReactFragment;
  cancelButtonText?: string;
  confirmButtonText?: string;
  customButtons?: ButtonConfig[];
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
}

const RouteLeavingGuard = ({
  when,
  titleText,
  subTitleText,
  contentText,
  cancelButtonText,
  confirmButtonText,
  customButtons,
  navigate,
  shouldBlockNavigation,
}: OwnProps) => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [lastLocation, setLastLocation] = React.useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmClickNavigation = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  React.useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      {/* Use default Prompt to render Warning Dialog custom component. */}
      <Prompt when={when} message={handleBlockedNavigation} />
      <WarningDialog
        open={modalVisible}
        titleText={titleText}
        subTitleText={subTitleText}
        contentText={contentText}
        cancelButtonText={cancelButtonText}
        confirmButtonText={confirmButtonText}
        customButtons={customButtons}
        onCancel={closeModal}
        onConfirm={handleConfirmClickNavigation}
      />
    </>
  );
};

export default RouteLeavingGuard;
