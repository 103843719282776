import { observable } from "mobx";
import {
  UserLegal,
  UserLegalRecord,
} from "../../application/resources/user-legal/user-legal";
import { UserLegalProvider } from "../../application/resources/user-legal/user-legal-provider";
import { UserInteractor } from "../../application/resources/user/user-interactor";
import { Presenter } from "../../hooks/use-presenter";

export class AuthLegalAgreementsPresenter implements Presenter {
  protected _legalAgreements: UserLegal;
  @observable public legalData: Partial<UserLegalRecord> = {
    agreements: [
      {
        id: "terms-of-use",
        text: "I have read and agreed to the",
        linkText: "Terms of service",
        link: "/agreements/Xpert_TermsOfService_FINAL_V2040.pdf",
        date: "",
        accepted: false,
        required: true,
      },
      {
        id: "privacy-policy",
        text: "I have read and agreed to the",
        linkText: "Privacy policy",
        link: "https://www.pentair.com/en-us/legal/privacy-notice.html",
        date: "",
        accepted: false,
        required: true,
      },
      {
        id: "communications",
        text:
          "I would like to stay informed about Pentair communications regarding helpful tips and tricks, product and service updates, and surveys.",
        date: "",
        accepted: false,
        required: false,
      },
    ],
  };

  constructor(
    protected _userLegalProvider: UserLegalProvider,
    protected _userInteractor: UserInteractor
  ) {}

  public mount = async () => {
    this._legalAgreements = this._userLegalProvider.get(
      this._userInteractor.currentUser.id
    );

    await this._legalAgreements.fetch();
  };

  public unmount = async () => {};

  public checkChecked = () => {
    let acceptedTerms: boolean[] | undefined;
    const requiredAgreements = this.legalData?.agreements?.filter(
      (legalAgreement) => legalAgreement.required === true
    );

    acceptedTerms = requiredAgreements?.map((agreement) => agreement.accepted);

    if (acceptedTerms && acceptedTerms.every(Boolean)) {
      return true;
    }

    return false;
  };

  public onCheck = (key: any) => {
    const agreement = this.legalData.agreements?.find(
      (legalAgreement) => legalAgreement.id === key
    );

    if (agreement) {
      agreement.accepted = agreement.accepted ? false : true;
    }
  };

  public submit = async () => {
    this._legalAgreements.record.agreements = [];
    this.legalData.agreements?.map((agreement) => {
      agreement.date = Date.now().toString();

      this._legalAgreements.record.agreements.push(agreement);
    });

    await this._legalAgreements
      .save()
      .then(() => (this._userInteractor.legalAgreements = true));
  };

  public logout = async () => {
    this._userInteractor.logout();
  };
}
