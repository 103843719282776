import { take } from "../../../application/resources/helper/take";
import { ProjectRecord } from "../../../application/resources/project/project";
import { createProject } from "./functions/create";
import { deleteProject } from "./functions/delete";
import { fetchProject } from "./functions/fetch";
import { updateProject } from "./functions/update";

export class ProjectClass {
  private _record: Partial<ProjectRecord> = {};
  private _changes: Partial<ProjectRecord> = {};

  public isFetching: boolean = false;
  public isDeleting: boolean = false;
  public isUpdating: boolean = false;
  public isCreating: boolean = false;

  public get record(): Partial<ProjectRecord> {
    const record = { ...this._record, ...this._changes };

    return record;
  }

  public get changes(): Partial<ProjectRecord> {
    return this._changes;
  }

  constructor(
    public id?: string,
    public fragment?: string,
    private _setState?: (state: ProjectClass) => void
  ) {
    this.record.id = id;
  }

  public setState = <T extends Record<string, any>>(
    obj?: T,
    k?: keyof T,
    value?: any
  ) => {
    if (obj && k) {
      obj[k] = value;
    }

    if (this._setState) {
      this._setState({ ...this });
    }
  };

  public handleResponse = (
    record: Partial<ProjectRecord> | null | undefined,
    errors: object[] = []
  ) => {
    this._record = { ...this._record, ...(record || {}) };
    // this.errors = errors;
    this.setState();
  };

  public get input(): Partial<ProjectRecord> {
    return take(this.record, [
      "id",
      "name",
      "opportunity",
      "destination",
      "pentairAccountName",
      "users",
    ]);
  }

  public change = (key: keyof ProjectRecord, value: any) => {
    this._changes = { ...this._changes, [key]: value };
    this.setState();
  };

  public fetch = async () => {
    if (!this.id) {
      throw new Error("ID is not set");
    }

    const res = await fetchProject({
      variables: { id: this.id },
      query: { fragment: this.fragment },
    });
    this.handleResponse(res.data && res.data.Project, res.errors);
    return res;
  };

  public create = async () => {
    const res = await createProject({
      variables: { input: this.input },
      query: { fragment: this.fragment },
    });
    this.handleResponse(res.data && res.data.createProject, res.errors);
    return res;
  };

  public update = async () => {
    if (this.id) {
      const res = await updateProject({
        variables: { input: this.input },
        query: { fragment: this.fragment },
      });
      this.handleResponse(res.data && res.data.updateProject, res.errors);
      return res;
    }

    return;
  };

  public delete = async () => {
    if (!this.id) {
      throw new Error("ID is not set");
    }
    const res = await deleteProject({
      variables: { id: this.id },
      query: { fragment: this.fragment },
    });
    this.handleResponse(res.data && res.data.deleteProject, res.errors);
    return res;
  };
}
