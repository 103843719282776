import * as React from "react";
import { MenuItem, TextField, TextFieldProps } from "@material-ui/core";
import LoadingIcon from "../../../../v2/common/ui/loading-icon";
import { DefaultFormFieldProps } from "../types";
import { useFormChange } from "../hooks/use-form-change";
import { useFormValidation } from "../hooks/use-form-validation";
import { useProjectCollection } from "../../../../v2/feature/project/hooks/use-collection";
import InfoLabel from "../info-label";
import { ProjectRecord } from "../../../../application/resources/project/project";

interface OwnProps extends DefaultFormFieldProps<Partial<ProjectRecord>> {
  textfieldProps?: TextFieldProps;
  disabled?: boolean;
}

export const SelectProject: React.FC<OwnProps> = (props) => {
  const { onChange, changed } = useFormChange((e) => {
    const selectedProject = collection.records.find(
      (project) => project.id === e.target.value
    );
    return {
      id: selectedProject?.id || "",
      name: selectedProject?.name || "",
    };
  }, props.onChange);

  const collection = useProjectCollection();
  const [loading, setLoading] = React.useState(false);

  const error = useFormValidation(
    props.value,
    props.validations || "required|max:120",
    props.onError
  );

  React.useEffect(() => {
    setLoading(true);
    collection.fetch().then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <TextField
      select
      required
      name="Project"
      id="Project"
      value={props.value?.id || ""}
      fullWidth
      onChange={onChange}
      label={
        <InfoLabel
          title={props.label || "Project"}
          description={props.description || "Project for the project"}
        />
      }
      {...props.textfieldProps}
      disabled={loading || props.disabled}
      error={changed && error ? true : false}
      helperText={changed && error}
      {...props.textfieldProps}
    >
      {loading ? (
        <>
          <MenuItem disabled value={"_loading"}>
            <LoadingIcon scale={0.5} />
          </MenuItem>
        </>
      ) : (
        collection.records.map((project) => (
          <MenuItem value={project.id}>{project.name}</MenuItem>
        ))
      )}
    </TextField>
  );
};
