import * as React from "react";
import { useObserver } from "mobx-react";
import {
  StyleTabs,
  useTabsStyles,
} from "../../../../../../view/pages/styles-tabs";
import { Tab } from "../../../../../common/ui/tab";
import { CanView } from "../../../../../../view/auth/can-view";

interface OwnProps extends StyleTabs {
  setActiveTab: (tab: string) => void;
  activeTab?: string;
}

export const TabsProjectIndex: React.FC<OwnProps> = (props) => {
  const classes = useTabsStyles(props);
  const { activeTab, setActiveTab } = props;

  return useObserver(() => {
    return (
      <div className={classes.root}>
        <Tab
          title="Created by me"
          active={activeTab === "created_by_me" || activeTab === undefined}
          onClick={() => setActiveTab("created_by_me")}
        />
        <CanView permission={"sharing.project.by.email"}>
          <Tab
            title="Shared with me"
            active={activeTab === "shared_with_me"}
            onClick={() => setActiveTab("shared_with_me")}
          />
        </CanView>

        {/* 
        <Tab
          disabled
          title="Shared by my accounts"
          active={activeTab === "shared_by_my_accounts"}
          onClick={() => setActiveTab("shared_by_my_accounts")}
        /> */}
      </div>
    );
  });
};
