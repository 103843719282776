import { Presenter } from "../../../../../hooks/use-presenter";
import {
  Project,
  ProjectRecord,
} from "../../../../..//application/resources/project/project";
import { UserInteractor } from "../../../../../application/resources/user/user-interactor";
import { ProjectProvider } from "../../../../../application/resources/project/project-provider";
import { computed, observable } from "mobx";

export class PageProjectIndexPresenter implements Presenter {
  @observable public activeTab: string | undefined = "created_by_me";
  @observable public newProject: Partial<ProjectRecord> = {};
  @observable public createPopup: boolean = false;
  @observable public loading: boolean = false;
  @observable public creating: boolean = false;
  @observable public searchString = "";
  @observable public allProjects: Project[];
  @observable public _subtitle: string;

  constructor(
    protected _userInteractor: UserInteractor,
    protected _projectProvider: ProjectProvider,
    protected redirect: any
  ) {
    this._subtitle = "Created by me";
  }

  public mount = async () => {};

  public unmount = () => {
    //
  };

  @computed public get isPentairUser() {
    return this._userInteractor.currentUser.can(["ui.admin.project.create"]);
  }

  public get activeUser() {
    return this._userInteractor.currentUser;
  }

  public setActiveTab = (tab: string) => {
    if (tab === "shared_with_me") {
      this._subtitle = "Shared with me";
    } else if (tab === "created_by_me") {
      this._subtitle = "Created by me";
    }

    this.activeTab = tab;
  };

  @computed public get subtitle() {
    return this._subtitle;
  }
}
