import { useObserver } from "mobx-react";
import { User } from "../../../../application/resources/user/user";
import * as React from "react";
import { Button, Grid } from "@material-ui/core";
import { PageTitles } from "../../../components/page/page-titles";
import {
  StyleSingleHeader,
  useSingleHeaderStyles,
} from "../../styles-single-header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserPresenter } from "../user-presenter";
import { HeaderPropertyValue } from "../../../components/page/header-prop-value";

interface OwnProps extends StyleSingleHeader {
  user: User;
  onSave: () => void;
  activeTab: string;
  presenter: UserPresenter;
}

export const UserHeader: React.FC<OwnProps> = (props) => {
  const classes = useSingleHeaderStyles(props);
  const { user, onSave, activeTab, presenter } = props;
  const record = user.record;
  const name = record.attributes.given_name || record.attributes.name;

  return useObserver(() => (
    <React.Fragment>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={8}>
          <PageTitles
            title={name + " " + record.attributes.family_name}
            subtitle={"User account " + activeTab}
          />
        </Grid>
        <Grid item xs={4} style={{ textAlign: "right" }} alignItems="center">
          {presenter.userId && (
            <Button
              color="primary"
              children={"save user"}
              disabled={user.loading}
              startIcon={
                user.loading ? (
                  <FontAwesomeIcon icon={["fal", "spinner"]} spin />
                ) : (
                  <FontAwesomeIcon icon={["fal", "save"]} />
                )
              }
              onClick={() => onSave()}
            />
          )}
        </Grid>
      </Grid>
      <div className={classes.propertyBar}>
        <HeaderPropertyValue
          property="Login type"
          value={
            user.record.status === "EXTERNAL_PROVIDER"
              ? "PARTNER PORTAL"
              : "AWS"
          }
        />
      </div>
    </React.Fragment>
  ));
};
