import * as React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableSortLabel,
} from "@material-ui/core";
import { TableCellValue, TableColumnProps } from "./table-column";
import { resolveTableValues } from "./helpers";
import { useSorting } from "./hooks/use-sorting";

export interface CollectionProps<T extends Record<string, any>> {
  columns: Partial<Record<keyof T | string, TableColumnProps<T>>>;
}

interface OwnProps<T> extends CollectionProps<T> {
  items: T[];
  sortKey?: string;
  sortDirection?: "asc" | "desc";
}

export const CollectionTable = <
  T extends Record<string, any> = Record<string, any>
>({
  columns,
  items,
  sortKey,
  sortDirection,
}: OwnProps<T>) => {
  const parsedItems = resolveTableValues<T>(items, columns);

  const { sort, setSort } = useSorting(parsedItems, sortKey, sortDirection);

  return (
    <Table>
      <TableHead>
        <TableRow>
          {Object.keys(columns).map((key) => {
            const col = columns[key];
            return (
              col && (
                <TableCell key={"head-" + key} {...col.tableCellProps}>
                  {(col.sortable && (
                    <TableSortLabel
                      active={key === sort.sortKey}
                      direction={sort.sortDirection}
                      onClick={() =>
                        setSort({
                          sortKey: key,
                          sortDirection:
                            sort.sortDirection === "asc" ? "desc" : "asc",
                        })
                      }
                    >
                      {col.label}
                    </TableSortLabel>
                  )) ||
                    col.label}
                </TableCell>
              )
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {parsedItems.map(({ item }) => (
          <TableRow key={"row-" + item.id}>
            {Object.keys(columns).map((key, index) => {
              const col = columns[key];
              return (
                col && (
                  <TableCell
                    key={"row-" + item.id + "-" + key}
                    {...col.tableCellProps}
                  >
                    <TableCellValue<T>
                      sourceKey={key}
                      source={item as T}
                      config={col}
                    />
                  </TableCell>
                )
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
