import { take } from "../../helper/take";
import { ProjectionRecord } from "../projection";
import { createProjection, InputProjection } from "./functions/create";
import { deleteProjection } from "./functions/delete";
import { fetchProjection } from "./functions/fetch";
import { updateProjection } from "./functions/update";
import { CalculationValue } from "../../technology/technology";
import { computed } from "mobx";
import { CalculationValueOptions } from "../../technology/calculation-values";

export class ProjectionClass {
  private _record: Partial<ProjectionRecord> = {};
  private _changes: Partial<ProjectionRecord> = {};

  public isFetching: boolean = false;
  public isDeleting: boolean = false;
  public isUpdating: boolean = false;
  public isCreating: boolean = false;

  public get record(): Partial<ProjectionRecord> {
    const record = { ...this._record, ...this._changes };

    return record;
  }

  public get changes(): Partial<ProjectionRecord> {
    return this._changes;
  }

  constructor(
    public id?: string,
    public fragment?: string,
    private _setState?: (state: ProjectionClass) => void
  ) {
    this.record.id = id;
  }

  public setState = <T extends Record<string, any>>(
    obj?: T,
    k?: keyof T,
    value?: any
  ) => {
    if (obj && k) {
      obj[k] = value;
    }

    if (this._setState) {
      this._setState({ ...this });
    }
  };

  public handleResponse = (
    record: Partial<ProjectionRecord> | null | undefined,
    errors: Error[] = []
  ) => {
    this._record = { ...this._record, ...(record || {}) };
    // this.errors = errors;
    this.setState();
  };

  public get input(): Partial<InputProjection> {
    return take(this.record, [
      "name",
      "project",
      "projectionValues",
      "version",
      "status",
      "type",
      "code",
      "country",
      "company",
      "pdf",
      "account",
      "opportunity",
      "case",
      "units",
      "createdBy",
      "approvedBy",
      "solution",
    ]);
  }

  public change = (key: keyof ProjectionRecord, value: any) => {
    this._changes = { ...this._changes, [key]: value };
    this.setState();
  };

  public fetch = async () => {
    if (!this.id) {
      throw new Error("ID is not set");
    }

    const res = await fetchProjection({
      variables: { id: this.id },
      query: { fragment: this.fragment },
    });
    this.handleResponse(res.data && res.data.Projection, res.errors);
    return res;
  };

  public create = async () => {
    const input: any = this.input;
    delete input.solution;
    input.solution = this.input.solution?.id || "";

    const res = await createProjection({
      variables: { input: { ...input } },
      query: { fragment: this.fragment },
    });
    this.handleResponse(res.data && res.data.createProjection, res.errors);
    return res;
  };

  public update = async () => {
    const res = await updateProjection({
      variables: { input: this.input },
      query: { fragment: this.fragment },
    });
    this.handleResponse(res.data && res.data.updateProjection, res.errors);
    return res;
  };

  public delete = async () => {
    if (!this.id) {
      throw new Error("ID is not set");
    }
    const res = await deleteProjection({
      variables: { id: this.id },
      query: { fragment: this.fragment },
    });
    this.handleResponse(res.data && res.data.deleteProjection, res.errors);
    return res;
  };

  public value = (id: CalculationValueOptions) => {
    return {
      ...this.valueMap[id],
    };
  };

  public get code() {
    if (this.id) {
      return this.id.substr(0, 8).toUpperCase();
    }

    return null;
  }

  public updateValue = (id: string, valueIndex: number, value: string) => {
    const calcVal = (this.record.projectionValues || []).find(
      (val) => val.id === id
    );

    if (calcVal) {
      const values = [...(calcVal.values || [])];
      values[valueIndex] = value;

      calcVal.values = values;
      this.valueMap[id].values = values;
    }
  };

  @computed public get categorizedValues() {
    const values = this.record.projectionValues || [];
    return values.reduce(
      (
        result: Record<string, [CalculationValue]>,
        valueData: CalculationValue
      ) => {
        if (valueData.category) {
          result[valueData.category] = result[valueData.category] || [];
          result[valueData.category].push(valueData);
        }
        return result;
      },
      {}
    );
  }

  @computed public get valueMap() {
    const values = this.record.projectionValues || [];
    return values.reduce((result, valueData: CalculationValue) => {
      if (valueData.id) {
        result[valueData.id] = valueData;
      }
      return result;
    }, {} as Record<CalculationValueOptions, CalculationValue>);
  }
}
