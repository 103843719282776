import { observable } from "mobx";
import { BaseModule } from "../../../module";

export interface TechnologyInteractor {
  comparisonSelection: string[] | null;
}

export default class DefaultTechnologyInteractor extends BaseModule<any, any, any>
  implements TechnologyInteractor {
  @observable public comparisonSelection: string[] | null = null;
}
