export const removeNullValues = (data: any, recursive: boolean = true) => {
  if (data.filter) {
    data = data.filter(Boolean);
  }

  for (const key in data) {
    if (data[key] !== null && data[key] !== undefined && data[key] !== Infinity) {
      if (typeof data[key] === "object" && recursive) {
        data[key] = removeNullValues(data[key]);
      }
    } else {
      delete data[key];
    }
  }

  return data;
};
