import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { RouteProvider } from "../../../application/business/provider/routes";
import { typographyVariants } from "../../../config/theme/pentair-theme/typography";
import { PentairTheme } from "../../../config/theme/types";
import { useProvider } from "../../../hooks/use-provider";

interface OwnProps {
  classes?: Record<"root", string>;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {},
  crumbWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  crumbSpacerIcon: {
    color: theme.palette.softGray.main,
    height: theme.spacing(2),
    width: theme.spacing(1.5) + "px !important",
    opacity: 0.3,
    marginRight: theme.spacing(1),
  },
  crumb: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
  },
  crumbLink: {
    textDecoration: "none",
    color: theme.palette.silverGray.main,
  },
}));

export const Breadcrumbs: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const provider = useProvider<RouteProvider<any>>("routes");
  const match = useRouteMatch();

  if (!match) {
    return null;
  }
  const currentRoute = provider.getRouteFromPath(match.path);

  if (!currentRoute) {
    return null;
  }

  const resolvedBreadcrumb = currentRoute.breadcrumbs.map((cfg) => {
    const route = provider.getOptions(cfg.route);
    let label: string = "";
    if (typeof cfg.label === "function") {
      label = cfg.label({ match });
    }
    return {
      href: provider.generatePath(cfg.route, match.params),
      label: label || cfg.label || route.title,
    };
  });

  return (
    <div className={classes.crumbWrap}>
      {resolvedBreadcrumb.map((breadcrumb) => (
        <div className={classes.crumb}>
          <FontAwesomeIcon
            className={classes.crumbSpacerIcon}
            icon={["fal", "chevron-right"]}
          />

          <Link to={breadcrumb.href} className={classes.crumbLink}>
            <Typography variant={typographyVariants.h5}>
              {breadcrumb.label}
            </Typography>
          </Link>
        </div>
      ))}
    </div>
  );
};
