import { Collection } from "../../../application/core/collection";
import {
  collectSolutions,
  QuerySolutionCollectionArgs,
} from "./functions/collect";
import { SolutionRecord } from "./types/record";

export class SolutionCollection extends Collection<SolutionRecord> {
  public errors: object[] = [];

  constructor(
    protected _fragment: string | undefined,
    protected _setState: (state: any) => void
  ) {
    super(_setState);
  }

  public handleResponse = (
    records: Partial<SolutionRecord>[] | null | undefined,
    errors: object[] = []
  ) => {
    this.records = (records as any) || {};
    this.errors = errors;
    this._changed();
  };

  public fetch = async (variables?: QuerySolutionCollectionArgs) => {
    const res = await collectSolutions({
      variables,
      query: { fragment: this._fragment },
    });
    this.handleResponse(
      res.data && res.data.SolutionCollection.solutions,
      res.errors
    );
  };
}
