import { createRoute } from "../../application/helpers/route";
import { PageSalesForceConfig } from "../../view/pages/salesforce/page-salesforce-config";

export const routesSalesForce = {
  "salesforce.config": createRoute({
    path: "/manage/salesforce/config",
    title: "SalesForce config",
    component: PageSalesForceConfig,
    breadcrumbs: [
      {
        route: "app.administration",
        label: "Manage",
      },
      {
        route: "salesforce.config",
        label: "SalesForce Config",
      },
    ],
    public: false,
    requiredPermissions: ["projections.salesforce.updateconfig"],
  }),
};
