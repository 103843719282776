import { makeStyles } from "@material-ui/core";
import { PentairTheme } from "../../../config/theme/types";

export type StyleDefaultPages = {
  classes?: Record<"root" | "bigText" | "icon", string>;
};

export const useErrorPageStyles = makeStyles<PentairTheme>((theme) => ({
  root: {
    color: "#ccc",
    textAlign: "center",
  },
  bigText: {
    ...theme.typography.h1_condensed,
    fontSize: "calc(1rem + 2vw)",
  },
  icon: {
    width: `calc(${theme.spacing(4)}px + 2vw)`,
    height: `calc(${theme.spacing(4)}px + 2vw)`,
    margin: theme.spacing(2),
  },
}));
