import gql from "graphql-tag";
import { runQuery, RunQueryOptions } from "../../../../network/run-query";
import { ProjectionFragment } from "../../graphql/fragments";
import { ProjectionRecord } from "../../projection";

interface QueryProjectionArgs {
  id: string;
}

const buildQuery = (fragment: string = ProjectionFragment) => gql`
  query Projection($id: ID!) {
    Projection(id: $id) {
      ${fragment}
    }
  }
`;

export const fetchProjection = async (
  options: RunQueryOptions<QueryProjectionArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<QueryProjectionArgs, { Projection: ProjectionRecord }>({
    query,
    variables: options.variables,
  });
};
