import { observable } from "mobx";
import { TableSelection } from "./table-helpers";
import { RecordData } from "../../../application/base/general/store";

export class TablePresenter<TItem extends RecordData> {
  @observable public selection: TableSelection = {
    item: {},
    get selected() {
      const result = Object.keys(this.item).reduce(
        (selection: { all: boolean; partial: boolean }, id: any) => {
          const selected = this.item[id];
          selection.partial = selection.partial || selected;
          selection.all = selection.all && selected;
          return selection;
        },
        { all: true, partial: false }
      );
      return result;
    },
  };

  constructor(
    protected _collection: TItem[] = [],
    protected _onEdit?: (id: string) => void,
    protected _onDelete?: (id: string) => void,
    protected _onClone?: (id: string) => void,
    protected _onRowClick?: (id: string) => void,
    protected _onSelect?: (selection: TableSelection, id?: string) => void
  ) {
    try {
      this.selection.item = this._collection.reduce((selection: any, item) => {
        selection[item.id] = false;
        return selection;
      }, {});
    } catch {
      this.selection.item = {};
    }
  }

  public mount = () => {};

  public unmount = () => {
    this.clearSelection();
  };

  public toggleSelect = (id: string) => {
    this.selection.item[id] = !this.selection.item[id];
    this.onSelect(id);
  };

  public toggleSelectAll = () => {
    const selected = this.selection.selected.partial;

    this.selection.item = this._collection.reduce((selection: any, item) => {
      selection[item.id] = !selected;
      return selection;
    }, {});

    this.onSelect();
  };

  public clearSelection = () => {
    this.selection.item = {};
  };

  public onEdit = (id: string) => {
    if (this._onEdit) {
      this._onEdit(id);
    }
  };

  public onDelete = (id: string) => {
    if (this._onDelete) {
      this._onDelete(id);
    }
  };

  public onClone = (id: string) => {
    if (this._onClone) {
      this._onClone(id);
    }
  };

  public onRowClick = (id: string) => {
    if (this._onRowClick) {
      this._onRowClick(id);
    }
  };

  public onSelect = (id?: string) => {
    if (this._onSelect) {
      this._onSelect(this.selection, id);
    }
  };
}
