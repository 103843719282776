import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { PentairTheme } from "../../../../../config/theme/pentair-theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { typographyVariants } from "../../../../../config/theme/typography";
import * as React from "react";

interface OwnProps {
  data: { key: string; value: any }[];
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {
    margin: theme.spacing(0, 0, 3, 0),
    border: "none",
  },
  tableRow: {
    border: "none",
  },
  tableCell: {
    fontWeight: 600,
    padding: theme.spacing(0.2, 0, 0.2, 0),
    border: 0,
    [theme.breakpoints.down("md")]: {
      minWidth: 150,
    },
  },
  tableValueCell: { fontWeight: 400 },
  before: {
    marginRight: theme.spacing(2),
  },
  titleWrap: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  name: {},
  value: {},
  suggestedValue: { opacity: 0.5 },
  unit: {},
  listname: { width: theme.spacing(12), color: theme.palette.silverGray.main },
  bold: { fontWeight: 700 },
  icon: {
    width: theme.spacing(2) + "px !important",
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.blue.main,
  },
}));

export const ProjectionCalculationValueList: React.FC<OwnProps> = (props) => {
  const { data } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div className={classes.titleWrap}>
        <FontAwesomeIcon
          className={classes.icon}
          icon={["fal", "clipboard-list-check"]}
        />
        <Typography variant="h4">Summary of inputs</Typography>
      </div>
      <Table style={{ fontSize: "0.825em" }}>
        <TableBody>
          {data.map((row, index) => {
            return (
              <TableRow key={index} className={classes.tableRow}>
                <TableCell
                  className={classnames(classes.tableCell, classes.listname)}
                >
                  <Typography
                    className={classes.bold}
                    variant={typographyVariants.body2}
                    color={"inherit"}
                  >
                    {row.key}
                  </Typography>
                </TableCell>

                <TableCell
                  className={classnames(classes.tableCell, classes.value)}
                >
                  <Typography variant={typographyVariants.body2}>
                    {row.value}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};
