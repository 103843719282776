import * as React from "react";
import { Grid } from "@material-ui/core";
import { useRedirect } from "../../../../../hooks/use-redirect";
import { useProfileCollection } from "../../hooks/use-collection";
import { SectionTitle } from "../../../../common/ui/section-title";
import LoadingIcon from "../../../../common/ui/loading-icon";
import { CollectionTable } from "../../../../common/table/table-collection";
import {
  columnEdit,
  columnValue,
} from "../../../../common/table/table-columns";
import { SearchField } from "../../../project/view/pages/partials/search-field";
interface OwnProps {}

export const ProfileIndexTable: React.FC<OwnProps> = (props) => {
  const collection = useProfileCollection();
  const redirect = useRedirect();
  const onEdit = React.useCallback((val, item) => {
    redirect("profile.edit", { profile: item.id });
  }, []);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true);
    collection.fetch().then(() => {
      setLoading(false);
    });
  }, [collection, searchQuery]);

  console.log("Profile table", collection);

  return (
    <section>
      <Grid
        container
        spacing={2}
        justify={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={6}>
          <SectionTitle title="Profile overview" icon="table" />
        </Grid>
        <Grid item xs={2}>
          <SearchField passedValue={searchQuery} onChange={setSearchQuery} />
        </Grid>
        <Grid item xs={12}>
          {(loading && <LoadingIcon />) || (
            <CollectionTable
              sortKey={"name"}
              sortDirection={"desc"}
              items={collection.records}
              columns={{
                name: columnValue("Name"),
                description: columnValue("Description"),
                edit: columnEdit(onEdit),
              }}
            />
          )}
        </Grid>
      </Grid>
    </section>
  );
};
