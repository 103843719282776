import React from "react";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./view/routes";
import { useObserver } from "mobx-react";
import { pentairTheme } from "./config/theme/pentair-theme";
import "./css/amplify.css";

export const App: React.FC = () => {
  return useObserver(() => {
    return (
      <div className="App">
        <ThemeProvider theme={pentairTheme}>
          <CssBaseline />
          <Router>
            <Routes />
          </Router>
        </ThemeProvider>
      </div>
    );
  });
};
