import * as React from "react";
import { CalculationValue } from "../../../application/resources/technology/technology";
import { IconName } from "@fortawesome/pro-light-svg-icons";
import { PentairTheme } from "../../../config/theme/types";
import {
  makeStyles,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Tooltip,
  TableBody,
  TextField,
} from "@material-ui/core";
import { useObserver } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { typographyVariants } from "../../../config/theme/typography";
import { useEventHandler } from "../../../hooks/use-eventmapper";

interface RowConfig extends CalculationValue {
  space?: number;
}

interface OwnProps {
  title?: string;
  icon?: IconName;
  values: RowConfig[];
  hideSuggestedValue?: boolean;
  onValueChange?(
    CalculationValue: string,
    valueIndex: number,
    value: any
  ): void;
  classes?: Record<
    | "tableCell"
    | "error"
    | "name"
    | "value"
    | "range"
    | "unit"
    | "tableCellValue"
    | "titleWrap",
    string
  >;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  tableCell: {
    "&:last-child": {
      paddingRight: 0,
    },
  },
  tableValueCell: { fontWeight: 400 },

  error: {
    color: theme.palette["error"].main,
    maxWidth: theme.spacing(4),
    textAlign: "center",
    fontSize: 18,
  },
  name: { width: theme.spacing(15) },
  value: { width: theme.spacing(10) },
  range: { width: theme.spacing(21) },
  unit: {},
  titleWrap: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  icon: {
    width: theme.spacing(2) + "px !important",
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.blue.main,
  },
}));

export const ValuesTableForm: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const { title, icon, values, onValueChange } = props;
  const [eventHandler, valueGetter] = useEventHandler();
  const spacing = (amount: number) => {
    return amount * 12;
  };

  return useObserver(() => {
    return (
      <div>
        <div className={classes.titleWrap}>
          {icon && (
            <FontAwesomeIcon className={classes.icon} icon={["fal", icon]} />
          )}
          {title && <Typography variant="h4">{title}</Typography>}
        </div>
        <Table style={{ fontSize: "0.825em" }}>
          <TableHead>
            <TableCell></TableCell>
            <TableCell>Parameter</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>EGU/Unit</TableCell>
          </TableHead>
          <TableBody>
            {(values || []).map((row, index) =>
              row.space ? (
                <div key={index} style={{ height: spacing(row.space) }} />
              ) : (
                <TableRow key={index}>
                  <TableCell padding={"checkbox"} className={classes.error}>
                    {row.error && (
                      <Tooltip title={row.error || ""}>
                        <FontAwesomeIcon
                          icon={["fal", "exclamation-circle"]}
                          size={"1x"}
                        />
                      </Tooltip>
                    )}
                  </TableCell>

                  <TableCell className={classes.name}>
                    <Typography variant={typographyVariants.body2}>
                      {row.name}
                    </Typography>
                  </TableCell>

                  {row.values && (
                    <TableCell
                      className={
                        row.values.length < 2 ? classes.value : classes.range
                      }
                    >
                      {row.values.map((val, index) => (
                        <TextField
                          key={row.id}
                          onChange={eventHandler(
                            onValueChange,
                            row.id || "",
                            index,
                            valueGetter
                          )}
                          value={val || ""}
                          style={{ width: spacing(8), marginRight: spacing(1) }}
                        />
                      ))}
                    </TableCell>
                  )}

                  <TableCell className={classes.unit}>
                    <Typography variant={typographyVariants.body2}>
                      {row.unit}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </div>
    );
  });
};
