import * as React from "react";
import { makePresenter } from "../../../application/helpers/make-presenter";
import { AssistantPresenter } from "./assistant-presenter";
import { useObserver } from "mobx-react";
import { Slide, makeStyles, Typography } from "@material-ui/core";

import { ResponseMessage } from "./partials/response-message";
import { ResponseError } from "./partials/response-error";
import { TechnologySelector } from "../technology/technology-selector";
import { PentairTheme } from "../../../config/theme/types";
import { AssistantResults } from "./partials/results";
import LoadingIcon from "../../../v2/common/ui/loading-icon";

interface OwnProps {
  onChange(technologies: string[]): void;
  onDone?(): void;
  onReset?(): void;
  isDone?: boolean;
  technologies: string[];
  project?: string;
}

const usePresenter = makePresenter(
  ({ provider, utils }) =>
    new AssistantPresenter(provider.assistant, utils.validator),
  (presenter, props) => {
    presenter.onChange = props.onChange;
    presenter.onDone = props.onDone;
  }
);

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {
    height: 0,
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
  },
  assistant: {
    margin: "auto",
    textAlign: "center",
    opacity: 1,
    transition: "opacity 0.5s",
  },
  technologyRoot: {
    maxWidth: "100%",
  },
  technologySelector: {
    justifyContent: "flex-start",
  },
  message: {
    fontFamily: "Barlow Condensed",
    fontWeight: 300,
    fontSize: "calc(1.75em + 1vw)",
    color: theme.palette.silverGray.main,
    opacity: 0,
  },
  done: {
    "& $assistant": {
      opacity: 0,
      margin: 0,
    },
    "& $message": {
      opacity: 1,
    },
    "& $result": {
      flex: 1,
      textAlign: "left",
    },
  },
  answerSheet: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(6),
  },
  result: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "flex 0.5s",
  },
  resultTechnology: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "flex 0.5s",
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(6),
  },
  resultPanelTitle: {
    color: theme.palette.blue.main,
    textAlign: "left",
  },
}));

const getCompleteMessage = (technologies: any[]) => {
  if (technologies.length == 1) return "I recommend the following solution:";
  return "I recommend one of the following solutions:";
};

export const Assistant: React.FC<OwnProps> = (props) => {
  const presenter = usePresenter(props);
  const classes = useStyles(props);
  const isDone = props.isDone;
  const technologies = props.technologies;
  const project = props.project;

  return useObserver(() => {
    const {
      messages,
      submitMessage,
      loading,
      isSubmitting,
      isError,
      reset,
      validationErrors,
    } = presenter;

    if (loading) {
      return (
        <div className={classes.assistant}>
          <LoadingIcon />
        </div>
      );
    }

    return (
      <React.Fragment>
        {!isDone && (
          <React.Fragment>
            <div className={classes.assistant}>
              <div className={classes.root}>
                <div style={{ marginBottom: 75 }}>
                  {messages.map(
                    (msg, index: number) =>
                      (index < messages.length - 1 || !isError) && (
                        <ResponseMessage
                          key={msg.id}
                          record={msg.record}
                          answer={msg.givenAnswer}
                          onSubmitAnswer={submitMessage}
                          isSubmitting={isSubmitting}
                          errors={validationErrors}
                          active={index === messages.length - 1}
                        />
                      )
                  )}
                  {isError && (
                    <ResponseError
                      errorMessage={presenter.errorMessage}
                      onReset={reset}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={classes.result}>
              <Slide in appear direction="up" timeout={700}>
                <div style={{ maxWidth: "100%", minWidth: "75%" }}>
                  <Typography
                    className={classes.message}
                    variant="h2"
                    gutterBottom
                    align={"center"}
                  >
                    {getCompleteMessage(technologies)}
                  </Typography>
                  <TechnologySelector
                    include={technologies}
                    project={project}
                  />
                </div>
              </Slide>
            </div>
          </React.Fragment>
        )}
        {isDone && (
          <AssistantResults
            presenterReset={() => presenter.reset()}
            onReset={() => props.onReset && props.onReset()}
            messages={messages}
            technology={technologies[0].replace(/ /g, "-")}
            technologies={technologies}
          />
        )}
      </React.Fragment>
    );
  });
};
