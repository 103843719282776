import { createRoute } from "../../application/helpers/route";
import { PageProfileIndex } from "../../view/pages/profile/page-profile-index";
import { PageProfileSingle } from "../../view/pages/profile/page-profile-single";

export const routesProfile = {
  "profile.index": createRoute({
    path: "/manage/profile/index",
    title: "User profile management",
    component: PageProfileIndex,
    public: false,
    requiredPermissions: ["iam.profile.list"],
    breadcrumbs: [
      {
        route: "app.administration",
        label: "Manage",
      },
      {
        route: "profile.index",
        label: "Profiles",
      },
    ],
  }),
  "profile.create": createRoute({
    path: "/manage/profile/create",
    title: "Create User profile",
    component: PageProfileSingle,
    public: false,
    requiredPermissions: ["iam.profile.create"],
    breadcrumbs: [
      {
        route: "app.administration",
        label: "Manage",
      },
      {
        route: "profile.index",
        label: "Profiles",
      },
      {
        route: "profile.create",
        label: "Create",
      },
    ],
  }),
  "profile.edit": createRoute({
    path: "/manage/profile/:profile",
    title: "Edit User profile",
    component: PageProfileSingle,
    public: false,
    requiredPermissions: ["iam.profile.edit"],
    breadcrumbs: [
      {
        route: "app.administration",
        label: "Manage",
      },
      {
        route: "profile.index",
        label: "Profiles",
      },
      {
        route: "profile.edit",
        label: "Edit",
      },
    ],
  }),
};
