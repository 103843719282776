import { RouteOptions } from "../business/provider/routes";

export function createRoute(
  config: Partial<RouteOptions> & { path: string }
): RouteOptions {
  return {
    title: "Untitled",
    component: undefined,
    public: false,
    mainmenu: false,
    requiredPermissions: [],
    breadcrumbs: [],
    ...config,
  };
}
