import { TableCellProps } from "@material-ui/core";
import * as React from "react";

export interface TableColumnProps<T = Record<string, any>> {
  label: React.ReactNode;
  sortable?: boolean;
  defaultValue?: React.ReactNode;
  render?: (value: any, record: T) => React.ReactNode;
  tableCellProps?: TableCellProps;
  value?:
    | ((value: any, record: T) => string | number | boolean)
    | string
    | number
    | boolean;
}

interface OwnProps<T = Record<string, any>> {
  sourceKey: string;
  config?: TableColumnProps<any>;
  source: T;
  api?: any;
}

export const TableCellValue = <T extends Record<string, any>>({
  sourceKey,
  config,
  source,
  api,
}: OwnProps<T>) => {
  config = config || {
    label: sourceKey,
    defaultValue: null,
  };

  let value: any = "";

  if (source && sourceKey in source) {
    value = source[sourceKey];
  }

  if (config.render) {
    value = config.render(value, source);
  }

  return <>{value || config.defaultValue}</>;
};
