import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid } from "@material-ui/core";
import { useObserver } from "mobx-react";
import * as React from "react";
import { makePresenter } from "../../../../../application/helpers/make-presenter";
import { theme } from "../../../../../config/theme/pentair";
import { useRedirect } from "../../../../../hooks/use-redirect";
import { CanView } from "../../../../../view/auth/can-view";
import { StyleDefaultPages } from "../../../../../view/pages/errors/styles-error-pages";
import { SharedProjectIndexTable } from "../../../../../view/pages/project/index/partials/shared-table";
import { ProjectCreateDialog } from "./partials/project-create-dialog";
import { useProjectionSingleStyles } from "../../../../../view/pages/projection/styles-projection-single";
import { PageContent } from "../../../../common/layout/page-content";
import { PageTitles } from "../../../../common/layout/page-titles";
import { Page } from "../../../../common/layout/page-wrapper";
import { ProjectIndexTable } from "../table/table";
import { PageProjectIndexPresenter } from "./index-presenter";
import { TabsProjectIndex } from "./partials/tabs";

interface OwnProps extends StyleDefaultPages {}

const usePresenter = makePresenter(
  ({ interactor, provider }) =>
    new PageProjectIndexPresenter(
      interactor.user,
      provider.project,
      useRedirect()
    )
);

export const PageProjectIndex: React.FC<OwnProps> = (props) => {
  const classes = useProjectionSingleStyles(props);
  const presenter = usePresenter(props);
  const redirect = useRedirect();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  return useObserver(() => {
    const {
      subtitle,
      activeTab,
      setActiveTab,
      isPentairUser,
      activeUser,
    } = presenter;

    return (
      <Page variant="fullscreen">
        <PageContent>
          <div
            className={classes.header}
            style={{ paddingTop: theme.spacing(4) }}
          >
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <PageTitles title={"Projects"} subtitle={subtitle} />
              </Grid>
              <Grid
                item
                xs={4}
                style={{ textAlign: "right" }}
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setDialogOpen(true)}
                  startIcon={<FontAwesomeIcon icon={["fal", "plus"]} />}
                >
                  Create Project
                </Button>
              </Grid>
            </Grid>
          </div>
          <div className={classes.sectionBar}>
            <TabsProjectIndex
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
          <div className={classes.sectionWrap}>
            {activeTab === "created_by_me" && (
              <ProjectIndexTable activeUser={activeUser.id} />
            )}
            {activeTab === "shared_with_me" && (
              <CanView permission={"sharing.project.by.email"}>
                <SharedProjectIndexTable activeUser={activeUser.id} />
              </CanView>
            )}
          </div>
          <ProjectCreateDialog
            adminForm={isPentairUser}
            open={dialogOpen}
            onClose={(project) => {
              setDialogOpen(false);
              if (project) {
                redirect("project.single", { projectid: project.id || "" });
              }
            }}
          />
        </PageContent>
      </Page>
    );
  });
};
