import * as React from "react";
import { useObserver } from "mobx-react";
import { usePresenter } from "../../../../hooks/use-presenter";
import { ProjectionInteractiveImagePresenter } from "./projection-interactive-image-presenter";

interface OwnProps {
  img: Array<string>;
  children: (loaded: boolean) => React.ReactNode;
}

export const InteractiveImage: React.FC<OwnProps> = (props) => {
  const presenter = usePresenter(
    () => new ProjectionInteractiveImagePresenter(props)
  );

  return useObserver(() => {
    const { imageLoaded, images, loaded, children } = presenter;
    return (
      <div style={{ position: "relative" }}>
        {images.map((image: string, index) =>
          index == 0 ? (
            <img width="100%" key={index} src={image} onLoad={imageLoaded} />
          ) : (
            <img
              width="100%"
              key={index}
              src={image}
              style={{ position: "absolute", top: 0, left: 0 }}
              onLoad={imageLoaded}
            />
          )
        )}
        {loaded && children(loaded)}
      </div>
    );
  });
};
