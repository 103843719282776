import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { ProjectRecord } from "../../../../../application/resources/project/project";
import { typographyVariants } from "../../../../../config/theme/typography";
import { PageTitles } from "../../../../components/page/page-titles";
import { UserShareSummary } from "../../../../../application/resources/project/project";
import { FormSelectSharePermissions } from "../../../../components/form/fields/share-permissions";
import { FormSelectShareUser } from "../../../../components/form/fields/share-user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProjectUsersTable } from "./project-users-table";
import { UserRecord } from "../../../../../application/resources/user/user";
import { ProjectClass } from "../../../../../v2/feature/project/single";

interface OwnProps {
  open: boolean;
  project: ProjectClass;
  onClose: (project?: Partial<ProjectRecord>) => void;
  currentUser: UserRecord;
}

export const ProjectShareDialog: React.FC<OwnProps> = (props) => {
  const [saving, setSaving] = React.useState(false);
  const { project, onClose, open } = props;
  // const [errors, setErrors] = React.useState<Error[]>([]);
  const [userId, setUserId] = React.useState<string>();
  const [permissions, setPermissions] = React.useState<string[]>([]);
  const [user, setUser] = React.useState<any>({});

  React.useEffect(() => {
    if (user.id) {
      user.fetch();
    }
  }, [userId]);

  const onDelete = React.useCallback(
    async (userId) => {
      setSaving(true);

      if (project.record.users) {
        const newUsers = project.record.users.filter(
          (user) => user.id !== userId
        );

        project.change("users", newUsers);
      }
      try {
        await project.update();
      } catch (err) {
        console.log(err);
      }

      setSaving(false);
    },
    [project.record]
  );

  const onUpdate = React.useCallback(
    async (e) => {
      e.preventDefault();
      setSaving(true);

      const userToAdd: UserShareSummary = {
        id: user.id || "",
        attributes: {
          given_name: user.given_name || "",
          family_name: user.family_name || "",
          email: user.email,
        },
        permissions: permissions,
      };

      if (project.record.users) {
        project.record.users = project.record.users.filter(function (obj) {
          return obj.id !== userToAdd.id;
        });

        project.change("users", [userToAdd, ...project.record.users]);
      } else {
        project.change("users", [userToAdd]);
      }

      try {
        await project.update();
        setUserId(undefined);
      } catch (err) {
        console.log(err);
      }
      setSaving(false);
    },
    [project.record]
  );

  const onChange = React.useCallback(
    (key: string) => (value: any) => {
      if (key === "user") {
        setUser(value);
      } else if (key === "permissions") {
        const newPermissions = value.split(".");
        setPermissions(newPermissions);
      }
    },
    []
  );

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth={"md"} fullWidth>
      <DialogContent>
        <PageTitles title="Project" subtitle="Collaboration" />
        <Typography variant={typographyVariants.body2} paragraph>
          Share this project with other Xpert Users
        </Typography>
        <div style={{ position: "relative", marginBottom: 10 }}>
          <form onSubmit={onUpdate}>
            <Grid container spacing={2} alignItems={"flex-end"}>
              <Grid item xs={12} md={4}>
                <FormSelectShareUser
                  disabled={
                    project.record.owner &&
                    props.currentUser.id !== project.record.owner.id
                  }
                  value={user.id || ""}
                  owner={project.record.owner && project.record.owner.id}
                  currentUsers={project.record.users}
                  onChange={onChange("user")}
                  textfieldProps={{ margin: "normal" }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormSelectSharePermissions
                  disabled={
                    project.record.owner &&
                    props.currentUser.id !== project.record.owner.id
                  }
                  value={permissions.join(".") || ""}
                  onChange={onChange("permissions")}
                  textfieldProps={{ margin: "normal" }}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  color="secondary"
                  disabled={
                    saving ||
                    (project.record.owner &&
                      props.currentUser.id !== project.record.owner.id)
                  }
                  children={
                    (saving && (
                      <FontAwesomeIcon icon={["fal", "spinner"]} spin />
                    )) || <FontAwesomeIcon icon={["fal", "plus"]} />
                  }
                  onClick={onUpdate}
                />
              </Grid>
            </Grid>
          </form>
          <ProjectUsersTable
            users={project.record.users || []}
            onDelete={onDelete}
            disabledDelete={
              (project.record.owner &&
                props.currentUser.id !== project.record.owner.id) ||
              false
            }
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          children={(saving && "Saving users..") || "Done"}
          disabled={
            saving ||
            (project.record.owner &&
              props.currentUser.id !== project.record.owner.id)
          }
          startIcon={
            (saving && <FontAwesomeIcon icon={["fal", "spinner"]} spin />) || (
              <FontAwesomeIcon icon={["fal", "check"]} />
            )
          }
          onClick={() => onClose(project.record)}
        />
      </DialogActions>
    </Dialog>
  );
};
