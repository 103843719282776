import { makeStyles } from "@material-ui/core";
import { PentairTheme } from "../../config/theme/types";

export type StyleSingleHeader = {
  classes?: Record<"root" | "propertyBar", string>;
};

export const useSingleHeaderStyles = makeStyles<PentairTheme>((theme) => ({
  root: {},
  propertyBar: {
    paddingTop: theme.spacing(2),
    display: "flex",
    "& > *": {
      marginRight: theme.spacing(4),
      width: "auto",
    },
    alignItems: "center",
  },
}));
