import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Checkbox,
  Grid,
  Link,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { useObserver } from "mobx-react";
import * as React from "react";
import { makePresenter } from "../../application/helpers/make-presenter";
import { theme } from "../../config/theme/pentair";
import { PentairTheme } from "../../config/theme/types";
import { useEventHandler } from "../../hooks/use-eventmapper";
import { PageTitles } from "../components/page/page-titles";
import { AuthLegalAgreementsPresenter } from "./auth-legal-agreements-presenter";

type OwnProps = {};

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {
    height: " 100vh",
    width: "100vw",
    background: "url(/images/landingpage.jpg)",
    textAlign: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    "& > *": {
      textAlign: "left",
      alignSelf: "center",
    },
  },
  paper: {
    padding: theme.spacing(2),
    maxWidth: "500px",
    width: "75vw",
  },
}));

const usePresenter = makePresenter(
  ({ provider, interactor }) =>
    new AuthLegalAgreementsPresenter(provider.userLegal, interactor.user)
);

export const AuthLegalAgreements: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const [eventHandler] = useEventHandler();
  const presenter = usePresenter(props);

  return useObserver(() => {
    return (
      <div className={classes.root}>
        <Paper square className={classes.paper}>
          <PageTitles title={"Xpert"} subtitle={"First time login"} />
          <Grid container spacing={0} justify={"flex-end"}>
            {presenter.legalData &&
              (presenter.legalData?.agreements || []).map((agreement) => {
                return (
                  <Grid item xs={12}>
                    <Checkbox
                      style={{ margin: "-8px 0 -8px -14px" }}
                      checked={agreement.accepted}
                      onClick={eventHandler(presenter.onCheck, agreement.id)}
                      icon={
                        <FontAwesomeIcon
                          icon={["fal", "square"]}
                          size="xs"
                          style={{ fontSize: 20 }}
                          color={theme.palette.softGray.main}
                        />
                      }
                      required={agreement.required}
                      checkedIcon={
                        <FontAwesomeIcon
                          icon={["fas", "check-square"]}
                          size="xs"
                          style={{ fontSize: 20 }}
                          color={theme.palette.secondary.main}
                        />
                      }
                    />
                    {agreement.text + " "}
                    {agreement.link && (
                      <Link
                        target="_blank"
                        style={{
                          textTransform: "none",
                          color: theme.palette.brightBlue.main,
                        }}
                        href={agreement.link}
                      >
                        <strong>{agreement.linkText}</strong>
                      </Link>
                    )}
                  </Grid>
                );
              })}
            <Grid
              item
              xs={"auto"}
              style={{
                textAlign: "right",
                paddingTop: theme.spacing(2),
                marginRight: theme.spacing(1),
              }}
            >
              <Button
                style={{ width: "100%" }}
                color="primary"
                children="Continue"
                disabled={presenter.checkChecked() ? false : true}
                startIcon={<FontAwesomeIcon icon={["fal", "check"]} />}
                onClick={() => presenter.submit()}
              />
            </Grid>
            <Grid
              item
              xs={"auto"}
              style={{
                textAlign: "right",
                paddingTop: theme.spacing(2),
              }}
            >
              <Button
                style={{ width: "100%" }}
                color="default"
                children="abort"
                startIcon={<FontAwesomeIcon icon={["fal", "times"]} />}
                onClick={() => presenter.logout()}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  });
};
