import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Checkbox,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { useObserver } from "mobx-react";
import * as React from "react";
import { theme } from "../../../../config/theme/pentair";
import { PentairTheme } from "../../../../config/theme/types";
import { useEventHandler } from "../../../../hooks/use-eventmapper";
import { usePresenter } from "../../../../hooks/use-presenter";
import { SectionTitle } from "../../../../v2/common/ui/section-title";
import {
  FormPresenter,
  FormProps,
} from "../../../components/form/form-presenter";
import InfoLabel from "../../../components/form/info-label";

type FormData = {
  engineering_unit?: string;
  language?: string;
};

interface OwnProps extends FormProps<FormData> {
  classes?: Record<"titleWrap" | "root" | "icon", string>;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {
    margin: theme.spacing(0, 0, 3, 0),
    border: "none",
  },
  titleWrap: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  icon: {
    width: theme.spacing(2) + "px !important",
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.blue.main,
  },
}));

export const UserFormPreferences: React.FC<OwnProps> = (props) => {
  const [eventHandler, valueGetter] = useEventHandler();
  const classes = useStyles(props);
  const presenter = usePresenter(
    ({ utils }) => new FormPresenter<FormData>(props, utils.validator)
  );

  return useObserver(() => {
    const { data, change, errors } = presenter;

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              select
              required
              fullWidth
              margin="normal"
              placeholder="Metric"
              id="engineering_unit"
              label={
                <InfoLabel
                  title="Engineering unit"
                  description="Centimeters, inches"
                />
              }
              onChange={eventHandler(change, "engineering_unit", valueGetter)}
              value={data.engineering_unit}
              error={errors.engineering_unit ? true : false}
              helperText={errors.engineering_unit}
            >
              <MenuItem value="metric">Metric</MenuItem>
              <MenuItem value="imperial">Imperial</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              required
              fullWidth
              margin="normal"
              placeholder="English"
              id="language"
              label={
                <InfoLabel
                  title="Language"
                  description="Pentair Xpert application language"
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <SectionTitle title="Notifications" icon="bells" />
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={0}>
              <Grid item md={6}></Grid>
              <Grid item md={2}>
                <strong>Xpert notification</strong>
              </Grid>
              <Grid item md={2}>
                <strong>Email notification</strong>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: theme.spacing(1) }}>
                <strong>Projects</strong>
              </Grid>
              <Grid item md={6}>
                Notify me when a project is shared with me
              </Grid>
              <Grid item md={2}>
                <Checkbox
                  style={{ margin: "-8px 0 -8px -14px" }}
                  checked={false}
                  onClick={() => {}}
                  icon={
                    <FontAwesomeIcon
                      icon={["fal", "square"]}
                      size="xs"
                      style={{ fontSize: 20 }}
                      color={theme.palette.softGray.main}
                    />
                  }
                  checkedIcon={
                    <FontAwesomeIcon
                      icon={["fas", "check-square"]}
                      size="xs"
                      style={{ fontSize: 20 }}
                      color={theme.palette.secondary.main}
                    />
                  }
                />
              </Grid>
              <Grid item md={2}>
                <Checkbox
                  style={{ margin: "-8px 0 -8px -14px" }}
                  checked={false}
                  onClick={() => {}}
                  icon={
                    <FontAwesomeIcon
                      icon={["fal", "square"]}
                      size="xs"
                      style={{ fontSize: 20 }}
                      color={theme.palette.softGray.main}
                    />
                  }
                  checkedIcon={
                    <FontAwesomeIcon
                      icon={["fas", "check-square"]}
                      size="xs"
                      style={{ fontSize: 20 }}
                      color={theme.palette.secondary.main}
                    />
                  }
                />
              </Grid>

              <Grid item md={6}>
                Notify me when a project is modified
              </Grid>
              <Grid item md={2}>
                <Checkbox
                  style={{ margin: "-8px 0 -8px -14px" }}
                  checked={false}
                  onClick={() => {}}
                  icon={
                    <FontAwesomeIcon
                      icon={["fal", "square"]}
                      size="xs"
                      style={{ fontSize: 20 }}
                      color={theme.palette.softGray.main}
                    />
                  }
                  checkedIcon={
                    <FontAwesomeIcon
                      icon={["fas", "check-square"]}
                      size="xs"
                      style={{ fontSize: 20 }}
                      color={theme.palette.secondary.main}
                    />
                  }
                />
              </Grid>
              <Grid item md={2}>
                <Checkbox
                  style={{ margin: "-8px 0 -8px -14px" }}
                  checked={false}
                  onClick={() => {}}
                  icon={
                    <FontAwesomeIcon
                      icon={["fal", "square"]}
                      size="xs"
                      style={{ fontSize: 20 }}
                      color={theme.palette.softGray.main}
                    />
                  }
                  checkedIcon={
                    <FontAwesomeIcon
                      icon={["fas", "check-square"]}
                      size="xs"
                      style={{ fontSize: 20 }}
                      color={theme.palette.secondary.main}
                    />
                  }
                />
              </Grid>

              <Grid item md={6}>
                Notify me when a project is approved
              </Grid>
              <Grid item md={2}>
                <Checkbox
                  style={{ margin: "-8px 0 -8px -14px" }}
                  checked={false}
                  onClick={() => {}}
                  icon={
                    <FontAwesomeIcon
                      icon={["fal", "square"]}
                      size="xs"
                      style={{ fontSize: 20 }}
                      color={theme.palette.softGray.main}
                    />
                  }
                  checkedIcon={
                    <FontAwesomeIcon
                      icon={["fas", "check-square"]}
                      size="xs"
                      style={{ fontSize: 20 }}
                      color={theme.palette.secondary.main}
                    />
                  }
                />
              </Grid>
              <Grid item md={2}>
                <Checkbox
                  style={{ margin: "-8px 0 -8px -14px" }}
                  checked={false}
                  onClick={() => {}}
                  icon={
                    <FontAwesomeIcon
                      icon={["fal", "square"]}
                      size="xs"
                      style={{ fontSize: 20 }}
                      color={theme.palette.softGray.main}
                    />
                  }
                  checkedIcon={
                    <FontAwesomeIcon
                      icon={["fas", "check-square"]}
                      size="xs"
                      style={{ fontSize: 20 }}
                      color={theme.palette.secondary.main}
                    />
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  paddingBottom: theme.spacing(1),
                  paddingTop: theme.spacing(2),
                }}
              >
                <strong>Pentair communication</strong>
              </Grid>
              <Grid item xs={12}>
                <Checkbox
                  style={{ margin: "-8px 0 -8px -14px" }}
                  checked={false}
                  onClick={() => {}}
                  icon={
                    <FontAwesomeIcon
                      icon={["fal", "square"]}
                      size="xs"
                      style={{ fontSize: 20 }}
                      color={theme.palette.softGray.main}
                    />
                  }
                  checkedIcon={
                    <FontAwesomeIcon
                      icon={["fas", "check-square"]}
                      size="xs"
                      style={{ fontSize: 20 }}
                      color={theme.palette.secondary.main}
                    />
                  }
                />
                I would like to stay informed about Pentair communications
                regarding helpful tips and tricks, product and service updates,
                and surveys.
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  });
};
