import * as React from "react";
import { Typography, makeStyles, Grid, Paper, Button } from "@material-ui/core";
import { StyleRules, StyledComponentProps } from "@material-ui/styles";
import { Page } from "../components/page/page-wrapper";
import { PageContent } from "../components/page/page-content";
import { typographyVariants } from "../../config/theme/typography";
import { PentairTheme } from "../../config/theme/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { theme } from "../../config/theme/pentair";
import { Auth, I18n } from "aws-amplify";

type OwnProps = StyledComponentProps<
  "root" | "headline1" | "headline2" | "headline3" | "logo"
>;

const useStyles = makeStyles(
  (theme: PentairTheme): StyleRules => ({
    root: {
      height: "100vh",
      background: "url('/images/landingpage.jpg')",
      backgroundSize: "cover",
      backgroundPosition: "bottom",
      width: "100vw",
    },
    logo: {
      position: "absolute",
      top: theme.spacing(2),
      left: "50%",
      transform: "translateX(-50%)",
    },
    headline1: {
      color: "#fff",
      lineHeight: 1,
      textShadow: "0 0 1.5rem rgba(0,0,0,0.5)",
      [theme.breakpoints.up("md")]: {
        fontSize: "4vw",
      },
      [theme.breakpoints.down("sm")]: {
        "& br": {
          display: "none",
        },
      },
    },
    headline2: {
      color: "#fff",
      lineHeight: 1,
      textShadow: "0 0 1.5rem rgba(0,0,0,0.5)",
      [theme.breakpoints.up("md")]: {
        fontSize: "2vw",
      },
      [theme.breakpoints.down("sm")]: {
        "& br": {
          display: "none",
        },
      },
    },
    headline3: {
      color: "#fff",
      lineHeight: 1.25,
      marginTop: theme.spacing(2),
      textShadow: "0 0 1.5rem rgba(0,0,0,0.5)",
      [theme.breakpoints.up("md")]: {
        fontSize: "1.2vw",
      },
      [theme.breakpoints.down("sm")]: {
        "& br": {
          display: "none",
        },
      },
    },
    leftContent: {
      paddingRight: theme.spacing(2),
    },
    sectionTitle: {
      margin: theme.spacing(2) + "px 0",
    },
    loginPaper: {
      color: theme.palette.silverGray.main,
      padding: theme.spacing(2),
      marginTop: theme.spacing(6),
    },
    titleWrap: {
      display: "flex",
      alignItems: "center",
      paddingBottom: theme.spacing(2),
    },
  })
);

const initiateFederatedLogin = () => {
  Auth.federatedSignIn({ customProvider: "pentair-partner-portal" });
};

export const PageHome: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);

  return (
    <Page variant="centered" classes={{ root: classes.root }}>
      <PageContent variant="centered" color="transparent">
        <Grid
          container
          spacing={4}
          justify="center"
          alignItems="center"
          style={{ maxWidth: "875px", width: "85vw", color: "#fff" }}
        >
          <Grid item xs={6} className={classes.leftContent}>
            <img
              src="/images/logo-x-flow-white.svg"
              height={"68"}
              className={classes.xflowLogo}
              style={{ marginLeft: "-62px" }}
            />
            <Typography
              variant={typographyVariants.h1}
              style={{
                fontWeight: 300,
                marginTop: "-15px",
                fontSize: "2.5em",
              }}
            >
              XPERT
            </Typography>
            <Typography
              variant={typographyVariants.h3}
              className={classes.sectionTitle}
            >
              Interactive app for membrane filtration solutions
            </Typography>
            <Typography variant={typographyVariants.body2}>
              <p>
                Pentair X-Flow Xpert App features the world's first
                conversational interface in the membrane filtration industry.
                The App guides you through helping to select the ideal Pentair
                X-Flow membrane filtration solution and configuration for water
                and wastewater treatment applications by answering a few
                questions.
              </p>
              <p>
                It enables you to design entire filtration systems, including
                all ancillary equipment and all operational requirements that
                mobilize decades of experience of Pentair X-Flow membrane
                filtration solutions. In addition, it has various unique new
                features.
              </p>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Paper square className={classes.loginPaper}>
              <div className={classes.titleWrap}>
                <FontAwesomeIcon
                  icon={["fal", "lock-alt"]}
                  style={{
                    fontSize: theme.spacing(1.5),
                    marginRight: theme.spacing(1),
                  }}
                />
                <Typography variant={typographyVariants.h3}>Sign in</Typography>
              </div>
              <div>
                <Typography variant={typographyVariants.body2}>
                  The Pentair X-Flow Xpert App is accessible exclusively via the
                  Pentair Partner Program and is available with your Pentair
                  Partner Portal credentials.
                </Typography>
              </div>
              {window.location.hostname !== "pentairxpert.com" && (
                <div>
                  <Button
                    style={{ margin: theme.spacing(2) + "px 0" }}
                    startIcon={<FontAwesomeIcon icon={["fal", "lock-alt"]} />}
                    fullWidth
                    onClick={initiateFederatedLogin}
                  >
                    {I18n.get("Sign in with Pentair Partner Portal")}
                  </Button>
                </div>
              )}
              <div>
                <Typography variant={typographyVariants.body2}>
                  For more details, contact your
                  <a
                    target="_blank"
                    href="https://xflow.pentair.com/en/contact?utm_source=pentairxpert.com&utm_medium=landingpage"
                    style={{
                      textDecoration: "none",
                      color: theme.palette.brightBlue.main,
                    }}
                  >
                    <strong> Pentair Account Manager</strong>
                  </a>
                </Typography>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </PageContent>
    </Page>
  );
};
