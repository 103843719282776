import * as React from "react";
import { IconName } from "@fortawesome/pro-light-svg-icons";
import { makeStyles, Typography } from "@material-ui/core";
import { PentairTheme } from "../../../config/theme/types";
import { typographyVariants } from "../../../config/theme/typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

interface OwnProps {
  icon: IconName;
  title: string | undefined;
  titleVariant: "big" | "small";
  rotation?: number;
  width?: number;
  align?: any;
  children: React.ReactNode;
  location: [number, number];
  animationDelay?: number;
  line?: string;
  info?: string;
  classe?: Record<
    "root" | "header" | "content" | "icon" | "bigText" | "smallText" | "line",
    string
  >;
}

export const InfoMarker: React.FC<OwnProps> = (props) => {
  const useStyles = makeStyles((theme: PentairTheme) => ({
    root: {
      display: "flex",
      position: "absolute",
      left: 0,
      top: 0,
      transformOrigin: "center",
      backfaceVisibility: "hidden",
      width: "max-content",
    },
    header: {
      alignItems: "center",
    },
    content: {
      paddingLeft:
        props.titleVariant == "big" ? theme.spacing(3) : theme.spacing(2),
    },
    icon: {
      width: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.brightBlue.main,
      marginLeft: 10,
      marginRight: 10,
    },
    bigText: { marginBottom: theme.spacing(0.5), textTransform: "none" },
    smallText: { textTransform: "none" },
    line: {
      position: "absolute",
      left: 0,
      top: 0,
    },
  }));

  const classes = useStyles(props);
  const titleSize = props.titleVariant || "small";
  const align = props.align || "left";

  const rootStyle: any = {
    left: props.location[0] + "%",
    top: props.location[1] + "%",
    transform: "rotate(" + (props.rotation ? props.rotation : 0) + "deg)",
  };

  if (props.width) {
    rootStyle.maxWidth = props.width;
  }

  if (props.align) {
    rootStyle.textAlign = props.align;
  }

  return (
    <div className={classes.root}>
      {props.line && (
        <svg
          className={classes.line}
          xmlns="https://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox="0 0 100% 100%"
        >
          <path d={props.line} fill="none" stroke="#707070" stroke-width="1" />
        </svg>
      )}

      {props.info && align === "left" && (
        <Typography
          className={classes[titleSize + "Text"]}
          variant={
            props.titleVariant == "big"
              ? typographyVariants.h3
              : typographyVariants.h5
          }
        >
          <FontAwesomeIcon
            className={classes.icon}
            icon={["fal", props.icon]}
          />
        </Typography>
      )}

      <div className={classnames(classes.header, classes[props.titleVariant])}>
        <Typography
          className={classes[titleSize + "Text"]}
          variant={
            props.titleVariant == "big"
              ? typographyVariants.h3
              : typographyVariants.h6
          }
        >
          {props.title}
        </Typography>
        {props.children}
      </div>

      {props.info && align === "right" && (
        <Typography
          className={classes[titleSize + "Text"]}
          variant={
            props.titleVariant == "big"
              ? typographyVariants.h3
              : typographyVariants.h5
          }
        >
          <FontAwesomeIcon
            className={classes.icon}
            icon={["fal", props.icon]}
          />
        </Typography>
      )}
    </div>
  );
};
