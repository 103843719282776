import gql from "graphql-tag";
import { runQuery, RunQueryOptions } from "../../../../network/run-query";
import { PermissionFragment } from "../../graphql/fragments";
import { PermissionRecord } from "../../permission";

export interface QueryPermissionCollectionArgs {
  filter: { key: "search" | keyof PermissionRecord; value: string }[];
}

const buildQuery = (fragment: string = PermissionFragment) => gql`
  query PermissionCollection {
    PermissionCollection {
      ${fragment}
    } 
  }
`;

export const collectPermissions = async (
  options: RunQueryOptions<QueryPermissionCollectionArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<
    QueryPermissionCollectionArgs,
    { PermissionCollection: PermissionRecord[] }
  >({
    query,
    variables: options.variables,
  });
};
