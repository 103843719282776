import { MenuItem, TextField, TextFieldProps } from "@material-ui/core";
import InfoLabel from "../info-label";
import * as React from "react";
import { useFormValidation } from "../hooks/use-form-validation";
import { useFormChange } from "../hooks/use-form-change";
import { countrycodes } from "../../../../application/helpers/countries";
import { getName } from "country-list";
import { DefaultFormFieldProps } from "../types";

interface OwnProps extends DefaultFormFieldProps<{ id: string; name: string }> {
  textfieldProps?: TextFieldProps;
}

const OFAC = ["IR", "KP", "SD", "SY", "CU"];

const countries = countrycodes.filter((country) => {
  return !OFAC.includes(country);
});

export const FormSelectCountry: React.FC<OwnProps> = (props) => {
  const error = useFormValidation(
    props.value,
    props.validations || "required",
    props.onError
  );

  const { onChange, changed } = useFormChange(
    (e) => ({
      id: e.target.value,
      name: getName(e.target.value) || "",
    }),
    props.onChange
  );

  return (
    <TextField
      select
      required
      name="country"
      id="country"
      value={(props.value && props.value.id) || ""}
      onChange={onChange}
      fullWidth
      label={
        <InfoLabel
          title={props.label || "Country"}
          description={props.description || "Country"}
        />
      }
      error={changed && error ? true : false}
      helperText={changed && error}
      {...props.textfieldProps}
    >
      {countries.map((country) => (
        <MenuItem value={country}>{getName(country)}</MenuItem>
      ))}
    </TextField>
  );
};
