import { StyleDefaultPages, useErrorPageStyles } from "./styles-error-pages";
import * as React from "react";
import { PageContent } from "../../components/page/page-content";
import { ErrorOutline } from "@material-ui/icons";
import { Button, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DefaultUserInteractor from "../../../application/resources/user/user-interactor";

interface OwnProps extends StyleDefaultPages {}

export const ErrorLegalUnauthorized: React.FC<OwnProps> = (props) => {
  const classes = useErrorPageStyles(props);
  const userInteractor = new DefaultUserInteractor();

  return (
    <PageContent variant="centered" classes={{ root: classes.root }}>
      <div>
        <ErrorOutline className={classes.icon} />
        <Typography className={classes.bigText} variant={"h1"} gutterBottom>
          Your account does not have sufficient permissions <br /> to start the
          Xpert onboarding process.
        </Typography>
        <Button
          color="default"
          children="Log out"
          startIcon={<FontAwesomeIcon icon={["fal", "times"]} />}
          onClick={() => userInteractor.logout()}
        />
      </div>
    </PageContent>
  );
};
