import gql from "graphql-tag";
import { runQuery, RunQueryOptions } from "../../../network/run-query";
import { SalesForceConfigFragment } from "../graphql/fragment";
import { SalesForceConfigRecord } from "../single";

interface QuerySalesForceConfigArgs {}

const buildQuery = (fragment: string = SalesForceConfigFragment) => gql`
  query SalesForceConfig {
    SalesForceConfig {
      ${fragment}
    }
  }
`;

export const fetchSalesForceConfig = async (
  options: RunQueryOptions<QuerySalesForceConfigArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<
    QuerySalesForceConfigArgs,
    { SalesForceConfig: SalesForceConfigRecord }
  >({
    query,
    variables: options.variables,
  });
};
