import * as React from "react";

import { Widget } from "../../../components/widget/widget";
import { TextField, Grid } from "@material-ui/core";
import { useEventHandler } from "../../../../hooks/use-eventmapper";
import { useObserver } from "mobx-react";
import { usePresenter } from "../../../../hooks/use-presenter";
import {
  FormProps,
  FormPresenter,
} from "../../../components/form/form-presenter";
import { SelectProfile } from "../../../components/form/fields/select-profile";
import { CanView } from "../../../auth/can-view";
import { User } from "../../../../application/resources/user/user";

type FormData = {
  email?: string;
  given_name?: string;
  family_name?: string;
  profile?: any;
};

interface OwnProps extends FormProps<FormData> {
  //
  currentUser: User;
}

export const UserFormDetails: React.FC<OwnProps> = (props) => {
  const [eventHandler, valueGetter] = useEventHandler();
  const { currentUser } = props;
  const presenter = usePresenter(
    ({ utils }) => new FormPresenter<FormData>(props, utils.validator)
  );

  return useObserver(() => {
    const { data, change, errors } = presenter;

    const disabled: boolean =
      (data.profile === "xpert-admins" &&
        currentUser?.profile?.record.profile.id !== "xpert-admins") ||
      data.email === currentUser.record.attributes.email;

    return (
      <Widget title={"User details"}>
        <Grid container spacing={2}>
          <Grid item sm={12} md>
            <TextField
              required
              fullWidth
              margin="normal"
              placeholder="E-mail"
              id="username"
              label="Username"
              onChange={eventHandler(change, "email", valueGetter)}
              value={data.email}
              error={errors.email ? true : false}
              helperText={errors.email}
            />
          </Grid>
          <Grid item sm={12} md>
            <TextField
              required
              fullWidth
              margin="normal"
              placeholder=""
              id="given_name"
              label="Name"
              error={errors.given_name ? true : false}
              helperText={errors.given_name}
              onChange={eventHandler(change, "given_name", valueGetter)}
              value={data.given_name}
            />
          </Grid>
          <Grid item sm={12} md>
            <TextField
              required
              fullWidth
              margin="normal"
              placeholder=""
              id="family-name"
              label="Last name"
              error={errors.family_name ? true : false}
              helperText={errors.family_name}
              onChange={eventHandler(change, "family_name", valueGetter)}
              value={data.family_name}
            />
          </Grid>
          <CanView permission={"iam.profile.list"}>
            <Grid item sm={12} md>
              <SelectProfile
                disabled={disabled}
                currentUser={currentUser}
                value={data.profile}
                required
                fullWidth
                margin="normal"
                placeholder=""
                id={"profile"}
                label="Profile"
                error={errors.profile ? true : false}
                helperText={errors.profile}
                onChange={eventHandler(change, "profile", valueGetter)}
              />
            </Grid>
          </CanView>
        </Grid>
      </Widget>
    );
  });
};
