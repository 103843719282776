import * as React from "react";
import { AssistantResponseRecord } from "../../../../application/resources/assistant/assistant-response";
import { Typography, TextField, makeStyles, InputAdornment, Button, Zoom } from "@material-ui/core";
import { IconButton } from "../../mui-extended/icon-button";
import { ResponseCard } from "./response-card";
import { useObserver } from "mobx-react";
import { PentairTheme } from "../../../../config/theme/types";
import { ValidationErrors } from "../../../../application/business/utils/validator";
import classNames from "classnames";

interface OwnProps {
  record: AssistantResponseRecord;
  onSubmitAnswer?(answer: string): void;
  isSubmitting?: boolean;
  errors?: ValidationErrors | false;
  active?: boolean;
  answer?: string;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {
    maxWidth: 1050,
    transition: "transform 0.5s",
    transform: "translateY(calc(50%)) scale(0.5)",
    opacity: 0.5,
    padding: theme.spacing(2, 2, 0, 2),
    margin: theme.spacing(-4, 0),

    [theme.breakpoints.up("md")]: {
      fontSize: "1.75vw",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.33vw",
    },
  },
  message: {
    fontFamily: "Barlow Condensed",
    fontWeight: 300,
    fontSize: "1em",
    color: theme.palette.silverGray.main,
  },
  active: {
    transform: "translateY(calc(50% + 75px)) scale(1)",
    opacity: 1,
    marginTop: -75,
    margin: 0,
    "& $message": {
      color: theme.palette.secondary.main,
    },
  },
  adornment: {
    "& > p": { fontSize: 16 },
  },
  input: {
    fontSize: 16,
    width: 250,
  },
  buttonSubmit: {
    marginLeft: theme.spacing(1),
  },
}));

export const ResponseMessage: React.FC<OwnProps> = (props) => {
  const { record, onSubmitAnswer, isSubmitting } = props;
  const [answer, setAnswer] = React.useState<string>("");
  const classes = useStyles(props);

  return useObserver(() => (
    <div className={classNames(classes.root, props.active && classes.active)}>
      <Zoom in timeout={500} key={record.id}>
        <div>
          <Typography
            className={classes.message}
            variant="h2"
            gutterBottom
            color={"primary"}
            style={{
              fontSize: `${Math.max(
                2,
                Math.min(2, 2 / (record.message.messageText.length / 150))
              )}em`,
            }}
          >
            {record.message.messageText}
          </Typography>
          {!props.active && props.answer && (
            <Typography style={{ fontSize: 28 }}>{props.answer}</Typography>
          )}
          {props.active &&
            (record.responseCard ? (
              <ResponseCard
                disabled={isSubmitting}
                responseCard={record.responseCard}
                onButtonClick={onSubmitAnswer}
              />
            ) : (
              <>
                <TextField
                  InputProps={{
                    className: classes.input,
                    endAdornment: record.message.egu && (
                      <InputAdornment position="end" classes={{ root: classes.adornment }}>
                        {record.message.egu}
                      </InputAdornment>
                    ),
                  }}
                  value={answer}
                  error={props.errors && props.errors["answer"] ? true : false}
                  helperText={props.errors && props.errors["answer"]}
                  placeholder={"Answer"}
                  autoFocus={true}
                  onChange={(e) => setAnswer(e.target.value)}
                  onKeyPress={
                    onSubmitAnswer &&
                    ((e) => {
                      if (e.key === "Enter" && !isSubmitting) {
                        onSubmitAnswer(answer);
                      }
                    })
                  }
                />

                <IconButton
                  icon={"arrow-right"}
                  className={classes.buttonSubmit}
                  loading={isSubmitting}
                  variant={"secondary"}
                  size={"small"}
                  onClick={onSubmitAnswer && (() => onSubmitAnswer(answer))}
                />
                <br />
                {record.message.skipValue && (
                  <Button
                    variant="text"
                    onClick={
                      onSubmitAnswer &&
                      (() => {
                        setAnswer(record.message.skipValue);
                        onSubmitAnswer(record.message.skipValue);
                      })
                    }
                  >
                    Skip
                  </Button>
                )}
              </>
            ))}
        </div>
      </Zoom>
    </div>
  ));
};
