import * as React from "react";
import { PentairTheme } from "../../../config/theme/types";
import { makeStyles, useTheme } from "@material-ui/core";
import { usePresenter } from "../../../hooks/use-presenter";
import { PageContainerPresenter } from "./page-container-presenter";
import { useObserver } from "mobx-react";
import classnames from "classnames";
import { Message } from "../../../v2/common/ui/message";

interface OwnProps {
  classes?: Record<"root" | "content" | "footer" | "interfaceActive", string>;
}

const useStyles = makeStyles((theme: PentairTheme) => ({
  root: {
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  content: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  interfaceActive: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
}));

export const PageContainer: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();

  const presenter = usePresenter(
    ({ interactor }) =>
      new PageContainerPresenter(interactor.interface, interactor.message)
  );

  return useObserver(() => {
    const { toolWidth, interfaceActive } = presenter;
    let rootStyle: React.CSSProperties = {};

    if (interfaceActive) {
      rootStyle.paddingLeft = theme.spacing(4) + toolWidth;
    }

    return (
      <>
        <div
          className={classnames(
            classes.root,
            interfaceActive && classes.interfaceActive
          )}
          style={rootStyle}
        >
          {presenter.messages.map((msg) => (
            <Message
              key={msg.id}
              type={msg.type}
              show={true}
              onClose={() => msg.acknowledge(msg.id)}
            >
              {msg.contents.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Message>
          ))}
          <main className={classes.content}>{props.children}</main>
        </div>
      </>
    );
  });
};
