import * as React from "react";
import { useObserver } from "mobx-react";
import { Menu } from "./menu";

interface OwnProps {}

export const AdministrationMenuAccess: React.FC<OwnProps> = () => {
  return useObserver(() => (
    <Menu
      title="Access"
      items={[
        {
          primary: "Users",
          secondary: "Manage users",
          route: "user.index",
        },
        // {
        //   primary: "User groups",
        //   secondary: "Manage usergroups",
        //   route: "user.index",
        // },
        // {
        //   primary: "Roles",
        //   secondary: "Manage roles",
        //   route: "user.index",
        // },
        {
          primary: "Profiles",
          secondary: "Manage profiles",
          route: "profile.index",
        },
        {
          primary: "Permissions",
          secondary: "Manage permissions",
          route: "permission.index",
        },
      ]}
    />
  ));
};
