import { useEffect, useState } from "react";
import { DefaultValidator } from "../../../../application/business/utils/validator";

const validator = new DefaultValidator();

export const useFormValidation = <TValue extends any>(
  value: TValue,
  validations: string,
  onError?: (error: string | false) => void
) => {
  const [error, setError] = useState<string>();

  useEffect(() => {
    const errors: any = validator.validate({ value }, { value: validations });
    let error = errors && errors.value;

    setError(error);
    if (onError) {
      onError(error);
    }
  }, [value, validations]);

  return error;
};
