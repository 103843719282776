import * as React from "react";
import { TextFieldProps, MenuItem, TextField } from "@material-ui/core";

import { useObserver } from "mobx-react";
import { getName } from "country-list";
import { countrycodes } from "../../../../application/helpers/countries";

export const SelectCountry: React.FC<TextFieldProps> = (props) => {
  const OFAC = ["IR", "KP", "SD", "SY", "CU"];

  const countries = countrycodes.filter((country) => {
    return !OFAC.includes(country);
  });

  return useObserver(() => {
    return (
      <TextField select {...props}>
        {countries.map((country) => (
          <MenuItem value={country}>{getName(country)}</MenuItem>
        ))}
      </TextField>
    );
  });
};
