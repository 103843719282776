import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  StyleRules,
} from "@material-ui/core";
import * as React from "react";
import { ProjectionRecord } from "../../../../../application/resources/projection/projection";
import { useProjection } from "../../../../../application/resources/projection/v2/hooks/use-single";
import { PentairTheme } from "../../../../../config/theme/types";
import { useRedirect } from "../../../../../hooks/use-redirect";
// import { useRedirect } from "../../../../../hooks/use-redirect";
import { PageTitles } from "../../../../../view/components/page/page-titles";
import { SteppedProgressBar } from "../../../../common/ui/stepped-progress-bar";
import { useProject } from "../../../project/hooks/use-single";
import { useSolution } from "../../../solution/hooks/use-single";
import { FormProjectionDetails } from "../form/form-projection-details";
import { FormProjectionFlowInputs } from "../form/form-projection-inputs";
import { FormProjectionInputsCategory } from "../form/form-projection-inputs-category";
import { ProjectionCalculationValueList } from "../partials/projection-calculation-value-list";

interface OwnProps {
  open: boolean;
  onClose: () => void;
  classes?: Record<"root", string>;
  solutionid: string;
  projectid?: string;
}

const useStyles = makeStyles(
  (theme: PentairTheme): StyleRules => ({
    root: {},
    dialogActions: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);
export const ProjectionCreateDialog: React.FC<OwnProps> = (props) => {
  const { open, onClose, solutionid, projectid } = props;
  const [activeStep, setActiveStep] = React.useState("details");
  const steps = ["details", "inputs", "feed", "products", "summary"];
  const projection = useProjection();
  const formSolution = useSolution(solutionid);
  const formProject = useProject(projectid);
  const redirect = useRedirect();

  const onChange = React.useCallback(
    (key: keyof ProjectionRecord) => (value: any) => {
      // If projectionValue is being updated run different logic
      if (key === "projectionValues") {
        // Set current projectionValues
        const projectionValues = projection.record.projectionValues || [];

        // Find index of the projectionValue we want to change.
        const calcValIndex = projectionValues.findIndex(
          (projectionValue) => projectionValue.id === value.id
        );

        // Update value in our projectionValues array
        projectionValues[calcValIndex] = value;

        // Update projection with our new values;
        projection.change(key, projectionValues);
      } else {
        projection.change(key, value);
      }
    },
    []
  );

  const onSave = React.useCallback(() => {
    projection.create().then(() => {
      const projectId =
        (projection.record.project && projection.record.project.id) || "";
      console.log(projectId);
      const projectionId = projection.record.id || "";

      redirect("projection.single", {
        projectionid: projectionId,
        projectid: projectId,
      });
    });
  }, []);

  const getProjectionValue = (id: string) => {
    const projectionValues = projection.record.projectionValues || [];
    return projectionValues.find(
      (projectionValue) => projectionValue.id === id
    );
  };

  const projectionValueToString = (id: string) => {
    const calculationValue = getProjectionValue(id);
    const unit =
      (calculationValue &&
        calculationValue.unit &&
        " " + calculationValue.unit) ||
      "";

    return ((calculationValue && calculationValue.values[0]) || "") + unit;
  };

  const resultsData = [
    {
      key: "Projection name",
      value: projection.record.name,
    },
    {
      key: "Project",
      value: projection.record.project?.name,
    },
    {
      key: "Solution",
      value: projection.record.solution?.name,
    },
    {
      key: "Water source",
      value: projectionValueToString("water_source"),
    },
    {
      key: "Capacity",
      value: projectionValueToString("capacity"),
    },
    {
      key: "Capacity based on",
      value: projectionValueToString("capacity_base"),
    },
    {
      key: "Design turbidity",
      value: projectionValueToString("design_turbidity"),
    },
    {
      key: "Temperature",
      value: projectionValueToString("temperature"),
    },
  ];

  const getInputsByCategory = (category: string) => {
    const values = projection.record.projectionValues || [];
    return values.filter(
      (projectionValue) => projectionValue.category === category
    );
  };

  const nextStep = React.useCallback(() => {
    setActiveStep(steps[steps.indexOf(activeStep) + 1]);
  }, [activeStep]);

  const previousStep = React.useCallback(() => {
    setActiveStep(steps[steps.indexOf(activeStep) - 1]);
  }, [activeStep]);

  React.useEffect(() => {
    if (
      (projection.record.solutionId && projection.record.solutionId.length) ||
      solutionid
    ) {
      formSolution
        .fetch(projection.record.solutionId || solutionid)
        .then(() => {
          projection.change("solution", formSolution.record);
          projection.change("projectionValues", formSolution.record.techValues);
        });
    }
  }, [projection.record.solutionId, solutionid]);

  React.useEffect(() => {
    if (projectid) {
      formProject.fetch().then(() => {
        projection.change("project", {
          id: formProject.record.id,
          name: formProject.record.name,
        });
      });
    }
  }, []);

  const classes = useStyles(props);

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth>
      <DialogContent>
        <PageTitles title="create" subtitle="new projection" />
        <SteppedProgressBar
          steps={steps}
          activeStep={activeStep || "details"}
          onStepClick={setActiveStep}
        />
        {activeStep === "details" && (
          <FormProjectionDetails
            onChange={onChange}
            data={{
              solution: projection.record.solutionId || solutionid,
              project: projection.record.project || formProject.record,
              name: projection.record.name || "",
            }}
          />
        )}
        {activeStep === "inputs" && (
          <FormProjectionFlowInputs
            onChange={onChange}
            data={{
              capacity: getProjectionValue("capacity"),
              capacity_base: getProjectionValue("capacity_base"),
              water_source: getProjectionValue("water_source"),
            }}
          />
        )}
        {activeStep === "feed" && (
          <FormProjectionInputsCategory
            onChange={onChange}
            data={getInputsByCategory("input.water.main")}
          />
        )}
        {activeStep === "products" && (
          <FormProjectionInputsCategory
            onChange={onChange}
            data={getInputsByCategory("plant.configuration.new")}
          />
        )}
        {activeStep === "summary" && (
          <ProjectionCalculationValueList data={resultsData} />
        )}
      </DialogContent>
      <DialogActions
        classes={
          activeStep !== "details" ? { root: classes.dialogActions } : {}
        }
      >
        {activeStep !== "details" && (
          <Button
            variant={"text"}
            children={"Previous"}
            startIcon={<FontAwesomeIcon icon={["fal", "arrow-left"]} />}
            onClick={() => previousStep()}
          />
        )}

        <Button
          color="primary"
          children={activeStep !== steps[steps.length - 1] ? "Next" : "Create"}
          startIcon={
            activeStep === steps[steps.length - 1] && (
              <FontAwesomeIcon icon={["fal", "check"]} />
            )
          }
          endIcon={
            activeStep !== steps[steps.length - 1] && (
              <FontAwesomeIcon icon={["fal", "arrow-right"]} />
            )
          }
          onClick={() =>
            activeStep !== steps[steps.length - 1] ? nextStep() : onSave()
          }
        />
      </DialogActions>
    </Dialog>
  );
};
